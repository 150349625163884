import React from 'react';
import { Form, Button, Select, List } from 'antd';
import EmptyComponent from '../../../../../../../Common/Empty/Empty';
import TemplateApiConsumer from '../../../../../../../../core/templates/api';
import AuthStore from '../../../../../../../../stores/Auth';

const { Option } = Select;

export default class LayoutTemplateSelectComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elementType: props.elementType,
      templateId: props.templateId,
      templatesList: [],
    };
  }

  componentDidMount() {
    this.loadTemplateList();
  }

  componentWillMount() {}

  loadTemplateList() {
    const { scope } = AuthStore.getScope();

    TemplateApiConsumer.getTemplates(scope).then(templates => {
      const templatesList = templates.filter(
        template => template.elementType === this.state.elementType,
      );

      this.setState({ templatesList });
    });
  }

  getActions(template) {
    return [
      <Button type="primary" onClick={() => this.handleSelect(template._id)}>
        <span>Selecionar</span>
      </Button>,
    ];
  }

  handleSelect = templateId => {
    this.props.onChange(templateId);

    this.setState({ templateId });
  };

  render() {
    //  const { getFieldDecorator } = this.props.form;

    if (this.state.templatesList && !this.state.templatesList.length) {
      return (
        <EmptyComponent description="Não existem templates disponíveis para este tipo de elemento." />
      );
    }

    return (
      <>
        {' '}
        {this.state.templateId ? (
          <Form.Item
            label="Template"
            // {...this.state.props}
            name="template._id"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            initialValue={this.state.templateId}
          >
            <Select onChange={this.handleSelect}>
              {this.state.templatesList.map(template => (
                <Option key={template._id} value={template._id}>
                  {template.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <List
            dataSource={this.state.templatesList}
            renderItem={template => (
              <List.Item actions={this.getActions(template)}>
                <List.Item.Meta
                  title={template.label}
                  description={template.description}
                />
              </List.Item>
            )}
          />
        )}
      </>
    );
  }
}
