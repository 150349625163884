import React from 'react';
import { Form, InputNumber } from 'antd';

const InputNumberItem = ({ props }) => {
  const { defaultValue, hasFeedback, help, label, name, rules } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
    >
      <InputNumber defaultValue={defaultValue} />
    </Form.Item>
  );
};

export default InputNumberItem;
