import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';
import CustomCssSettings from './CustomCssSettings';

const { Title } = Typography;

const EmptyResultsSettings = ({ emptyResults, form, tenantConfig }) => {
  if (!emptyResults?.length) return <></>;

  return (
    <>
      <Title level={4}>Pesquisa sem resultados</Title>
      {emptyResults.map(([key, value]) => {
        if (value.frontendType === 'code') {
          return (
            <CustomCssSettings
              key={`empty_settings_${key}`}
              form={form}
              keyName={key}
              value={value}
              tenantConfig={tenantConfig}
            />
          );
        }

        return (
          <FormFieldComponent
            key={`empty_settings_${key}`}
            form={form}
            name={key}
            {...value}
            defaultValue={value.default}
            dataType={value.type}
            hasFeedback={false}
            help={value.description}
            settedValue={tenantConfig[key]}
            className="search-form__grid search-form__grid__long-inputs"
          />
        );
      })}
    </>
  );
};

export default EmptyResultsSettings;
