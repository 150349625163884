import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title, Paragraph } = Typography;

const AvailabilitySettings = ({ itemsAvailability, form, tenantConfig }) => {
  if (!itemsAvailability?.length) return <></>;

  return (
    <>
      <Title level={4}>Disponibilidade de itens</Title>
      <Paragraph>
        Você pode definir se os produtos esgotados farão parte dos resultados de
        pesquisa ou não e, se sim, ainda pode agrupar todos os indisponíveis no
        fim da lista de resultados.
      </Paragraph>

      {itemsAvailability.length > 0 &&
        itemsAvailability.map(([key, value]) => {
          return (
            <FormFieldComponent
              form={form}
              name={key}
              key={key}
              {...value}
              defaultValue={value.default}
              dataType={value.type}
              hasFeedback={false}
              settedValue={tenantConfig[key]}
              className="search-form__grid"
            />
          );
        })}
    </>
  );
};

export default AvailabilitySettings;
