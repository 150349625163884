import React from 'react';
import { Empty, Button } from 'antd';

export default function EmptyComponent(props) {
    let {
        description = 'Sentindo falta de alguma coisa?',
        action = null,
        onClick = null,
        buttonProps = {},
    } = props;

    if (onClick) buttonProps.onClick = onClick;

    buttonProps = { type: 'primary', shape: 'round', ...buttonProps };

    return (
        <Empty description={description}>
            {action !== null && <Button {...buttonProps}>{action}</Button>}
        </Empty>
    );
}
