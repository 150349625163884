export default [
    {
        name: 'between',
        label: 'está entre',
        group: 'comparisons',
        subgroup: 'between',
        multi: false,
        options: false,
        type: 'list',
    },
    {
        name: 'empty',
        label: 'é vazio',
        group: 'comparisons',
        subgroup: 'empty',
        multi: false,
        options: false,
        type: 'boolean',
    },
    {
        name: 'equals',
        label: 'é igual',
        group: 'comparisons',
        subgroup: 'equals',
        multi: false,
        options: true,
        type: 'any',
    },
    {
        name: 'falsy',
        label: 'é falso',
        group: 'afirmations',
        subgroup: 'afirmations',
        multi: false,
        options: false,
        type: 'boolean',
    },
    {
        name: 'greater_than_equals_to',
        label: 'maior ou igual a',
        group: 'comparisons',
        subgroup: 'greater_or_less',
        multi: false,
        options: false,
        type: 'number',
    },
    {
        name: 'less_than_equals_to',
        label: 'menor ou igual a',
        group: 'comparisons',
        subgroup: 'greater_or_less',
        multi: false,
        options: false,
        type: 'number',
    },
    {
        name: 'not_empty',
        label: 'não é vazio',
        group: 'comparisons',
        subgroup: 'empty',
        multi: false,
        options: false,
        type: 'boolean',
    },
    {
        name: 'not_equals',
        label: 'é diferente',
        group: 'comparisons',
        subgroup: 'equals',
        multi: false,
        options: true,
        type: 'any',
    },
    {
        name: 'regex',
        label: 'expressão regular',
        group: 'comparisons',
        subgroup: 'regex',
        multi: false,
        options: false,
        type: 'text',
    },
    {
        name: 'truthy',
        label: 'é verdadeiro',
        group: 'afirmations',
        subgroup: 'afirmations',
        multi: false,
        options: false,
        type: 'boolean',
    },
    {
        name: 'is_in',
        label: 'é um dos',
        group: 'comparisons',
        subgroup: 'in',
        multi: true,
        options: true,
        type: 'any',
    },
    {
        name: 'not_in',
        label: 'não é um dos',
        group: 'comparisons',
        subgroup: 'in',
        multi: true,
        options: true,
        type: 'any',
    },
];
