import uuid from 'uuid';

import ContentApiConsumer from '../../core/content/api';

const ContentPageApiFactory = function ({ persistOnSave = false }) {
  this.instance = uuid.v4();
  this.page = undefined;
  this.dirty = false;

  this.options = {
    persistOnSave,
  };

  return this;
};

ContentPageApiFactory.prototype.setPage = function (page) {
  this.page = page;

  // emit region save event
  if (this.emitter) this.emitter.dispatch(this.emitter.PAGE_CHANGED, page);
};

ContentPageApiFactory.prototype.getPage = function () {
  return this.page;
};

ContentPageApiFactory.prototype.savePage = function (page) {
  return new Promise(async resolve => {
    // use moment to check date triggers and set status to scheduled
    page.props.status = page.props.isHidden ? `suspended` : `published`;

    this.setPage(page);
    this.dirty = true;

    // if saveOnSet
    if (this.options.persistOnSave)
      await this.persistPage(page.client_id, page);

    resolve(page);
  });
};

ContentPageApiFactory.prototype.persistPage = function (clientId, page) {
  return new Promise(async (resolve, reject) => {
    // console.log(clientId, page);
    if (this.emitter)
      this.emitter.dispatch(this.emitter.PAGE_PERSIST_STATUS, `START`, page);

    try {
      // put
      const savedPage = await ContentApiConsumer.savePage(page);

      this.setPage(savedPage);
      this.dirty = false;

      resolve(savedPage);

      if (this.emitter)
        this.emitter.dispatch(
          this.emitter.PAGE_PERSIST_STATUS,
          `DONE`,
          savedPage,
        );
    } catch (error) {
      if (this.emitter)
        this.emitter.dispatch(
          this.emitter.PAGE_PERSIST_STATUS,
          `FAIL`,
          page,
          error,
        );

      resolve(new Error(error.message));
    }
  });
};

ContentPageApiFactory.prototype.deletePage = function (clientId, pageId) {
  return new Promise(async (resolve, reject) => {
    try {
      // put
      const deletedPage = await ContentApiConsumer.deletePage(clientId, pageId);

      this.setPage(deletedPage);
      this.dirty = false;

      resolve(deletedPage);
    } catch (error) {
      resolve(new Error(error.message));
    }
  });
};

ContentPageApiFactory.prototype.initPage = function (clientId, pageId) {
  return new Promise(async resolve => {
    if (!pageId) {
      return resolve(
        new Error(`Não é possível inicializar uma página inexistente.`),
      );
    }

    const page = this.getPage();

    // do not init a page that is already inited
    if (page && page._id === pageId) {
      return resolve(page);
    }

    const loadedPage = await this.loadPage(clientId, pageId);

    if (!loadedPage) {
      return resolve(new Error(`Página não encontrada.`));
    }

    this.setPage(loadedPage);

    // emit region save event
    if (this.emitter)
      this.emitter.dispatch(this.emitter.PAGE_INITED, loadedPage);

    resolve(loadedPage);
  });
};

ContentPageApiFactory.prototype.loadPage = function (clientId, pageId) {
  return new Promise(async (resolve, reject) => {
    try {
      const page = await ContentApiConsumer.getPage(clientId, pageId);
      resolve(page);
    } catch (error) {
      console.log(error);
      resolve(new Error(error.message));
    }
  });
};

// REGIONS MANAGEMENT

ContentPageApiFactory.prototype.setRegions = async function (regions) {
  const page = this.getPage();
  page.regions = regions;

  await this.savePage(page);
};

ContentPageApiFactory.prototype.getRegions = function () {
  const page = this.getPage();
  return page ? page.regions : null;
};

ContentPageApiFactory.prototype.getRegion = function (_id) {
  const regions = this.getRegions();

  if (!regions) return undefined;

  return regions.filter(region => region._id === _id);
};

ContentPageApiFactory.prototype.saveRegion = function (updatedRegion) {
  return new Promise(async (resolve, reject) => {
    let regions = this.getRegions() || [];

    let regionToUpdate = regions.find(
      region => updatedRegion._id === region._id,
    );

    // region already exists, update.
    if (regionToUpdate) {
      regionToUpdate = Object.assign(regionToUpdate, updatedRegion);
    } else {
      regions.push(updatedRegion);
    }

    this.setRegions(regions);

    // emit region save event
    if (this.emitter)
      this.emitter.dispatch(this.emitter.REGION_SAVED, updatedRegion);

    resolve(updatedRegion);
  });
};

ContentPageApiFactory.prototype.deleteRegion = function (_id) {
  return new Promise(async resolve => {
    const region = this.getRegion(_id);

    if (!region) {
      return resolve(new Error(`Cannot delete a region that does not exists.`));
    }

    const regions = (this.getRegions() || []).filter(
      region => region._id !== _id,
    );

    this.setRegions(regions);

    // emit element delete event
    if (this.emitter)
      this.emitter.dispatch(this.emitter.REGION_DELETED, region);

    resolve(region);
  });
};

ContentPageApiFactory.prototype.getRegionElements = function ({ _id }) {
  return this.getElementsByRegion(_id);
};

// ELEMENTS MANAGEMENT

ContentPageApiFactory.prototype.setElements = async function (elements) {
  const page = this.getPage();
  page.elements = elements;

  await this.savePage(page);
};

ContentPageApiFactory.prototype.getElements = function () {
  const page = this.getPage();
  return page ? page.elements : null;
};

ContentPageApiFactory.prototype.getElement = function (_id) {
  const elements = this.getElements();

  if (!elements) return undefined;

  return elements.find(element => element._id === _id);
};

ContentPageApiFactory.prototype.getElementsByRegion = function (regionId) {
  const elements = this.getElements() || [];
  return elements.filter(element => element.region_id === regionId);
};

ContentPageApiFactory.prototype.saveElement = function (updatedElement) {
  return new Promise(async resolve => {
    let elements = this.getElements() || [];

    let elementToUpdate = elements.find(
      element => updatedElement._id === element._id,
    );

    // element already exists, update.
    if (elementToUpdate) {
      elementToUpdate = Object.assign(elementToUpdate, updatedElement);
    } else {
      elements.push(updatedElement);
    }

    this.setElements(elements);

    // emit element save event
    if (this.emitter)
      this.emitter.dispatch(this.emitter.ELEMENT_SAVED, updatedElement);

    resolve(updatedElement);
  });
};

ContentPageApiFactory.prototype.deleteElement = function (_id) {
  return new Promise(async resolve => {
    const element = this.getElement(_id);

    if (!element) {
      return resolve(
        new Error(`Cannot delete an element that does not exists.`),
      );
    }

    const elements = (this.getElements() || []).filter(
      element => element._id !== _id,
    );

    this.setElements(elements);

    // emit element delete event
    if (this.emitter)
      this.emitter.dispatch(this.emitter.ELEMENT_DELETED, element);

    resolve(element);
  });
};

ContentPageApiFactory.prototype.cloneElement = function (_id) {
  return new Promise(async resolve => {
    const original = this.getElement(_id);
    const now = new Date().toISOString();

    if (!original) {
      return resolve(new Error(`Source element not found, cannot clone.`));
    }

    const clone = JSON.parse(JSON.stringify(original));

    clone._id = uuid.v4();
    clone.props.label = `Cópia de ${clone.props.label}`;
    clone.props.isHidden = true;
    clone.created_at = now;
    clone.updated_at = now;
    clone.hidden_at = now;
    clone.visible_at = null;

    await this.saveElement(clone);

    resolve(clone);
  });
};

export default ContentPageApiFactory;
