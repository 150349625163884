import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const ElementActionClone = props => {
  const handle = props.handle;

  return (
    <Tooltip title="Duplicar">
      <Popconfirm
        title="Continuar com a cópia deste elemento?"
        okText="Sim"
        cancelText="Não"
        onConfirm={handle}
      >
        <Button type="default" shape="circle" icon={<CopyOutlined />} />
      </Popconfirm>
    </Tooltip>
  );
};

export default ElementActionClone;
