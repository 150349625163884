// import uuid from 'uuid';
import staticRecommendations from './static/recommendations';
import staticGroups from './static/groups';

const delay = 0;

export const getRecommendations = () =>
    new Promise(resolve => {
        setTimeout(() => resolve(staticRecommendations), delay);
    });

export const getRecommendation = recommendationId =>
    new Promise(async resolve => {
        const list = await getRecommendations();
        const recommendation = list.find(
            recommendation => recommendation._id === recommendationId
        );

        setTimeout(() => resolve(recommendation), delay);
    });

export const getGroups = () =>
    new Promise(resolve => {
        setTimeout(() => resolve(staticGroups), delay);
    });

export const getGroup = name =>
    new Promise(async resolve => {
        const list = await getGroups();
        const group = list.find(group => group.name === name);

        setTimeout(() => resolve(group), delay);
    });
