import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Popconfirm } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import AvailabilitySettings from '../../../../../Common/Search/SearchConfig/AvailabilitySettings';
import FrontendGeneralSettings from '../../../../../Common/Search/SearchConfig/FrontendGeneralSettings';

const AVAILABILITY = 'availability';
const BACKEND = 'backend';
const FRONTEND = 'frontend';
const GENERAL = 'general';

const GeneralTab = ({ formItems, handleSubmit, tenantConfig, onCancel }) => {
  const [form] = Form.useForm();
  const [generalConfigs, setGeneralConfigs] = useState([]);
  const [itemsAvailability, setItemsAvailability] = useState([]);

  const { properties } = formItems;

  useEffect(() => {
    const generalItems = [
      ...filterByKeywords(properties, [FRONTEND, GENERAL]),
      ...filterByKeywords(properties, [BACKEND, GENERAL]),
    ];
    const availability = filterByKeywords(properties, [BACKEND, AVAILABILITY]);

    setGeneralConfigs(generalItems);
    setItemsAvailability(availability);
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <FrontendGeneralSettings
          form={form}
          generalConfigs={generalConfigs}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <AvailabilitySettings
          form={form}
          itemsAvailability={itemsAvailability}
          tenantConfig={tenantConfig}
        />
      </div>
    </Form>
  );
};

export default GeneralTab;
