import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const FiltersSettings = ({ filtersSettings, form, tenantConfig }) => {
  if (!filtersSettings?.length) return <></>;

  return (
    <>
      <Title level={4}>Filtros</Title>

      {filtersSettings.length > 0 &&
        filtersSettings.map(([key, value]) => {
          return (
            <FormFieldComponent
              form={form}
              name={key}
              key={key}
              {...value}
              defaultValue={value.default}
              dataType={value.type}
              hasFeedback={false}
              settedValue={tenantConfig[key]}
              className="search-form__grid"
            />
          );
        })}
    </>
  );
};

export default FiltersSettings;
