import React from 'react';
import { Route } from 'react-router-dom';
import { AuthenticateBeforeRender } from './AuthenticateBeforeRender';
import AuthStore from '../../../stores/Auth';

export const AuthenticatedRoute = ({
    component: Component,
    render,
    exact,
    path,
}) => {
    const getRender = props => {
        if (render) {
            return render(props);
        } else {
            return <Component {...props} />;
        }
    };

    return (
        <Route
            exact={exact}
            path={path}
            render={props =>
                AuthStore.isAuthenticated() === true ? (
                    getRender(props)
                ) : (
                    <AuthenticateBeforeRender render={() => getRender(props)} />
                )
            }
        />
    );
};
