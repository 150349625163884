import cookie from 'react-cookies';
import AuthApiConsumer from '../../core/auth/api';

const AuthApiFactory = () => {
  const isAuthenticated = () => {
    try {
      const token = cookie.load('beon_auth_token');
      return token ? true : false;
    } catch (error) {
      return false;
    }
  };

  const isAuthorized = scope => {
    try {
      const { acl } = getAuthenticated();

      if (!acl) {
        return false;
      }

      const hasGlobalAcl = Object.keys(acl).indexOf(`bn:global`);
      const hasOrgAcl = Object.keys(acl).indexOf(`org:${scope}`);

      return hasGlobalAcl > -1 || hasOrgAcl > -1;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const setAuthenticated = ({ token, user, acl, meta }) =>
    new Promise(async resolve => {
      try {
        cookie.save('beon_auth_token', token);

        // user is local stored
        localStorage.setItem('current_user', user);
        localStorage.setItem('current_user_meta', JSON.stringify(meta));
        localStorage.setItem('current_user_acl', JSON.stringify(acl));

        resolve(true);
      } catch (error) {
        resolve(error);
      }
    });

  const destroyAuthenticated = () =>
    new Promise(async resolve => {
      cookie.remove('beon_auth_token');

      // user is local stored
      localStorage.clear();
      resolve();
    });

  const getAuthenticated = () => {
    try {
      const user = localStorage.getItem('current_user');
      const acl = JSON.parse(localStorage.getItem('current_user_acl'));
      const meta = JSON.parse(localStorage.getItem('current_user_meta'));

      const storageScope = localStorage.getItem('current_scope');
      const scope = storageScope ? JSON.parse(storageScope) : undefined;

      return { user, acl, scope, meta };
    } catch (error) {
      console.log(error);
      return { user: undefined, acl: undefined, scope: undefined };
    }
  };

  const authenticate = ({ username, password }) =>
    new Promise(async resolve => {
      AuthApiConsumer.authenticate({ username, password }).then(result => {
        const status = {
          success: undefined,
          user: username,
          meta: undefined,
          acl: undefined,
          token: undefined,
        };

        if (result instanceof Error) {
          status.success = false;
        } else {
          status.success = true;
          status.acl = result.acl;
          status.token = result.token;
          status.meta = result;
        }

        resolve(status);
      });
    });

  const refreshAuth = () =>
    new Promise(async resolve => {
      resolve(isAuthenticated());
    });

  const login = async (username, password) =>
    new Promise(async resolve => {
      if (isAuthenticated()) await destroyAuthenticated();

      const authResponse = await authenticate({ username, password });

      if (authResponse.success) {
        await setAuthenticated(authResponse);
        resolve(true);
      } else {
        resolve(new Error(authResponse.message));
      }
    });

  const logout = () =>
    new Promise(async resolve => {
      await destroyAuthenticated();
      resolve();
    });

  const setScope = scope => {
    localStorage.setItem('current_scope', JSON.stringify(scope));

    return scope;
  };

  const getScope = () => {
    const scope = localStorage.getItem('current_scope');

    if (scope) return JSON.parse(scope);

    // returns empty object to allow destructuring
    return {};
  };

  /**
   * Reports an user action to auth API
   * @param {string} action
   */
  const report = async (action, actionScope) => {
    if (!isAuthenticated())
      throw new Error(`Cannot report an action for non-authenticated user.`);

    const currentUser = getAuthenticated();
    const { user, scope } = currentUser;
    const timestamp = new Date().toString();

    const userCurrentScope = scope ? scope.scope : actionScope;

    if (!userCurrentScope) throw new Error(`Cannot identify current scope`);

    // report request
    const payload = {
      username: user,
      scope: userCurrentScope,
      timestamp,
      action,
    };

    const response = await AuthApiConsumer.report(payload);
  };

  return Object.freeze({
    isAuthenticated,
    isAuthorized,
    getAuthenticated,
    login,
    logout,
    refreshAuth,
    setScope,
    getScope,
    report,
  });
};

export default AuthApiFactory;
