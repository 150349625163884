import React from 'react';
import { DatePicker, Form, TimePicker } from 'antd';
import moment from 'moment';

const DateItem = ({ props }) => {
  const { frontendType, defaultValue, help, label, name } = props;
  const initialValue = moment(defaultValue);

  if (frontendType === 'date') {
    return (
      <Form.Item
        help={help}
        initialValue={defaultValue}
        label={label}
        name={name}
      >
        <DatePicker
          format={'DD/MM/YYYY'}
          defaultValue={initialValue || moment()}
        />
      </Form.Item>
    );
  }

  if (frontendType === 'time') {
    const initialTime = moment(defaultValue, 'HH:mm');
    const { _isValid } = initialTime;
    return (
      <Form.Item
        help={help}
        initialValue={defaultValue}
        label={label}
        name={name}
      >
        <TimePicker
          format={'HH:mm'}
          defaultValue={_isValid ? initialTime : moment('12:00', 'HH:mm')}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
    >
      <DatePicker
        format={'DD/MM/YYYY HH:mm'}
        defaultValue={initialValue || moment()}
        showTime
      />
    </Form.Item>
  );
};

export default DateItem;
