import React from 'react';
import { Col, Row, Tag } from 'antd';

import MerchandisingRuleEdit from './Actions/Edit';
import MerchandisingRuleDelete from './Actions/Delete';

const MerchandisingRuleComponent = ({ deleteRule, editRule, rule }) => {
  const { _id, trees_keys: treesKeys, score, is_active: isActive } = rule;

  return (
    <Row
      className="page-element-row"
      style={{ alignItems: 'center', backgroundColor: 'white' }}
    >
      <Col span={18}>
        {treesKeys?.map(({ label }) => (
          <Tag
            color="blue"
            key={crypto.randomUUID()}
            style={{
              maxWidth: '450px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {label}
          </Tag>
        ))}
      </Col>

      <Col span={2}>{score}</Col>

      <Col span={2}>
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Ativo' : 'Inativo'}
        </Tag>
      </Col>

      <Col span={1}>
        <MerchandisingRuleEdit onEdit={() => editRule(_id)} />
      </Col>

      <Col span={1}>
        <MerchandisingRuleDelete onDelete={() => deleteRule(rule)} />
      </Col>
    </Row>
  );
};

export default MerchandisingRuleComponent;
