import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './loading.css';

export default function LoadingComponent(props) {
    return (
        <div className="loading">
            <LoadingOutlined />
            <span>{props.label || `Loading`}</span>
        </div>
    );
}
