import React from 'react';
import IntegrationStatusGraph from './IntegrationStatusGraph';
import StatsText from '../Text/StatsText';
import SyncText from '../Text/SyncText';

import './styles.css';

const VtexIntegrationStatsHeader = ({
  fullData,
  restartSync,
  syncTextMode,
}) => {
  const data = [
    {
      name: 'Success',
      value: fullData.filter(
        ({ integrationStatus }) => integrationStatus === 'success',
      ).length,
    },
    {
      name: 'Error',
      value: fullData.filter(
        ({ integrationStatus }) => integrationStatus === 'error',
      ).length,
    },
    {
      name: 'Waiting',
      value: fullData.filter(({ integrationStatus }) => !integrationStatus)
        .length,
    },
  ];

  return (
    <div className="stats-container">
      <SyncText syncTextMode={syncTextMode} restartSync={restartSync} />

      <div className="stats-data">
        <IntegrationStatusGraph data={data} />
        <StatsText data={data} />
      </div>
    </div>
  );
};

export default VtexIntegrationStatsHeader;
