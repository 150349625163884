import React from 'react';
import CheckboxItem from './Checkbox';
import ColorPickerItem from './ColorPicker';
import CurrencyItem from './Currency';
import DateItem from './Date';
import InputItem from './Input';
import InputNumberItem from './InputNumber';
import RadioItem from './Radio';
import RichTextItem from './RichText';
import SelectItem from './Select';
import SliderItem from './Slider';
import SwitchItem from './Switch';

const FormItem = props => {
  const { frontendType } = props;

  switch (frontendType) {
    case 'checkbox':
      return <CheckboxItem props={props} />;

    case 'colorpicker':
      return <ColorPickerItem props={props} />;

    case 'currency':
      return <CurrencyItem props={props} />;

    case 'date':
    case 'time':
    case 'dateTime':
    case 'datetime':
      return <DateItem props={props} />;

    case 'number':
      return <InputNumberItem props={props} />;

    case 'radio':
      return <RadioItem props={props} />;

    case 'richtext':
      return <RichTextItem props={props} />;

    case 'select':
      return <SelectItem props={props} />;

    case 'slider':
      return <SliderItem props={props} />;

    case 'switch':
      return <SwitchItem props={props} />;

    default:
      return <InputItem props={props} />;
  }
};

export default FormItem;
