import React from 'react';

const ElementListComponent = props => {
    const { elements, addItem, empty = <></> } = props;

    if (!elements.length) return empty;

    return (
        <>
            {elements
                .sort((a, b) => a.props.order - b.props.order)
                .map(element => props.children(element))}
            {addItem}
        </>
    );
};

export default ElementListComponent;
