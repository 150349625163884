import React from 'react';
import { Form, InputNumber } from 'antd';

const CurrencyItem = ({ props }) => {
  const { defaultValue, hasFeedback, help, label, name, rules } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
    >
      <InputNumber
        style={{ width: '30%' }}
        defaultValue={defaultValue}
        prefix="R$"
      />
    </Form.Item>
  );
};

export default CurrencyItem;
