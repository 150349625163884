import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../../../../Common/Loading/Loading';

const SEARCH_TEST_URL = process.env.REACT_APP_SEARCH_TEST_URL;

const SearchTestFrame = () => {
  const { client_id: tenantId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const iframe = document.querySelector('.search__iframe');

    if (iframe) {
      iframe.addEventListener('load', () => setIsLoading(false));
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '75vh',
      }}
    >
      <div
        style={{
          display: isLoading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <LoadingComponent label={'Carregando ambiente de testes...'} />
      </div>

      <iframe
        className="search__iframe"
        src={`${SEARCH_TEST_URL}/${tenantId}`}
        style={{
          display: isLoading ? 'none' : 'block',
          border: '2px solid rgb(217, 217, 217)',
          borderRadius: '0.5rem',
          padding: '5px',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

export default SearchTestFrame;
