import React from 'react';
import { Button } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const ElementActionMove = ({ direction, handle }) => {
  const icon = direction === 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />;

  return (
    <Button
      className={`actions_move-icon ${direction === 'up' ? 'up' : 'down'}`}
      type="default"
      shape="circle"
      icon={icon}
      onClick={handle}
    />
  );
};

export default ElementActionMove;
