import {
    BarcodeOutlined,
    CodeOutlined,
    ExperimentOutlined,
    FileDoneOutlined,
    FileImageFilled,
    FlagOutlined,
    LikeOutlined,
    NotificationOutlined,
} from '@ant-design/icons';
import React from 'react';

const ElementTypeIcon = (props) => {
    let icon;

    switch (props.type) {
        case 'showcase':
            icon = <BarcodeOutlined {...props} />;
            break;
        case 'notification':
            icon = <NotificationOutlined {...props} />;
            break;
        case 'subscribe':
            icon = <FileDoneOutlined {...props} />;
            break;
        case 'banner':
            icon = <FileImageFilled {...props} />;
            break;
        case 'product_flag':
            icon = <FlagOutlined {...props} />;
            break;
        case 'snippet':
            icon = <CodeOutlined {...props} />;
            break;
        default:
            icon = <ExperimentOutlined {...props} />;
            break;
    }

    return icon;
};

export default ElementTypeIcon;
