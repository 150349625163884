import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, message, Select, Typography, Upload } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

import FormFieldComponent from '../Form/Field';

const { Option } = Select;
const { Title } = Typography;

const ECMS_API_BASE_URL = process.env.REACT_APP_ECMS_API_URL;
const IMG_ERROR_MSG =
  'Esta imagem foi removida do nosso banco de dados pois encontrava-se inativa por mais de 30 dias';

const NotificationTemplateParams = props => {
  const { client_id } = useParams();
  const { element, template } = props;

  const [stage1imageUrl, setStage1imageUrl] = useState(null);
  const [stage1fileList, setStage1fileList] = useState([]);
  const [stage1select, setStage1select] = useState('');

  const [stage2fileList, setStage2fileList] = useState([]);
  const [stage2select, setStage2select] = useState('');
  const [stage2imageUrl, setStage2imageUrl] = useState();

  const [stage1imageErrorMessage, setStage1ImageErrorMessage] = useState(null);
  const [stage2imageErrorMessage, setStage2ImageErrorMessage] = useState(null);

  const checkImageUrl = (url, group) => {
    const stageImageErrorMsg =
      group === 'stage1'
        ? setStage1ImageErrorMessage
        : setStage2ImageErrorMessage;

    const img = new Image();
    img.src = url;

    img.onerror = () => {
      stageImageErrorMsg(IMG_ERROR_MSG);
    };
  };

  useEffect(() => {
    console.log(props.element.template.params);
    const stage1useIconOrImg = props.element?.template?.params?.stage1_icon
      ? 'stage1-useIcon'
      : props.element?.template?.params?.stage1_useIconOrImg;
    const stage2useIconOrImg = props.element?.template?.params?.stage2_icon
      ? 'stage2-useIcon'
      : props.element?.template?.params?.stage2_useIconOrImg;

    setStage1select(stage1useIconOrImg);
    setStage2select(stage2useIconOrImg);

    const stage1imageUrl = props.element?.template?.params?.stage1_image_url;
    const stage2imageUrl = props.element?.template?.params?.stage2_image_url;

    if (stage1imageUrl) {
      checkImageUrl(stage1imageUrl, 'stage1');

      setStage1fileList([
        {
          uid: crypto.randomUUID(),
          name: `banner-${element._id}-image.jpg`,
          status: 'done',
          url: stage1imageUrl,
          thumbUrl: stage1imageUrl,
        },
      ]);
    }

    if (stage2imageUrl) {
      checkImageUrl(stage2imageUrl, 'stage2');

      setStage2fileList([
        {
          uid: crypto.randomUUID(),
          name: `banner-${element._id}-image.jpg`,
          status: 'done',
          url: stage2imageUrl,
          thumbUrl: stage2imageUrl,
        },
      ]);
    }
  }, [props.element, props.template]);

  const isUrlValidImage = url => {
    var imageExtensions = /\.(jpg|jpeg|png)$/i;
    return imageExtensions.test(url);
  };

  const uploadImage = async formData => {
    const response = await fetch(
      `${ECMS_API_BASE_URL}/presets/image/${client_id}/${element._id}`,
      {
        method: 'POST',
        body: formData,
      },
    );
    const { url } = await response.json();
    return url;
  };

  const deleteImage = async imageParams => {
    const payload = JSON.stringify({ imageParams });
    await fetch(`${ECMS_API_BASE_URL}/presets/image/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    });
  };

  const customRequest = async (options, group) => {
    try {
      const { file } = options;
      const formData = new FormData();
      formData.append('image', file);
      const url = await uploadImage(formData);
      const imageFileList = [
        {
          uid: crypto.randomUUID(),
          name: `notification-${element._id}-image.jpg`,
          status: 'done',
          url,
          thumbUrl: url,
        },
      ];
      const stageImageUrl =
        group === 'stage1' ? setStage1imageUrl : setStage2imageUrl;
      const stageFileList =
        group === 'stage1' ? setStage1fileList : setStage2fileList;

      props.form.setFieldsValue({
        [`template.params.${group}_image_url`]: url,
      });
      stageImageUrl(url);
      stageFileList(imageFileList);
    } catch (e) {
      message.error(
        'Ocorreu um erro ao subir a imagem. Por favor, tente novamente',
      );
      console.error('uploadImage failed with', e);
    }
  };

  const deleteImgFromBucket = async ({ url }, group) => {
    try {
      const isImage = isUrlValidImage(url);
      if (isImage) {
        const parts = url.split('/').filter(part => part !== '');
        const imageParams = parts.slice(-4).join('/');
        await deleteImage(imageParams);
      }

      const setStageFileList =
        group === 'stage1' ? setStage1fileList : setStage2fileList;
      const setStageImageUrl =
        group === 'stage1' ? setStage1imageUrl : setStage2imageUrl;

      setStageFileList([]);
      props.form.setFieldsValue({ [`template.params.${group}_image_url`]: '' });
      setStageImageUrl('');
    } catch (e) {
      console.error('deleteImgFromBucket failed with', e);
    }
  };

  const handleUrlChange = ({ target: { id, value } }) => {
    const isImage = isUrlValidImage(value);
    const imageFileList = [
      {
        uid: crypto.randomUUID(),
        name: `notification-${element._id}-image.jpg`,
        status: 'done',
        url: value,
        thumbUrl: value,
      },
    ];

    if (id === 'template.params.stage1_image_url') {
      setStage1imageUrl(value);
      setStage1fileList(isImage ? imageFileList : []);
    } else {
      setStage2imageUrl(value);
      setStage2fileList(isImage ? imageFileList : []);
    }
  };

  const changeElementUsage = v => {
    if (v.includes('stage1')) {
      setStage1select(v);
    } else {
      setStage2select(v);
    }
  };

  if (!template.paramsGroups || !template.params) return null;

  const groups = Object.values(template.paramsGroups);

  return (
    <>
      {groups.map(group => (
        <div className="params_group" key={group.name}>
          <Col offset="6">
            <Title level={4}>{group.label}</Title>
          </Col>

          {(group.name === 'stage1' || group.name === 'stage2') && (
            <>
              <Form.Item
                label="Imagem ou Ícone"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                name={`template.params.${group.name}_useIconOrImg`}
                initialValue={
                  props.element.template.params?.[`${group.name}_icon`]
                    ? `${group.name}-useIcon`
                    : props.element.template.params?.[
                        `${group.name}_useIconOrImg`
                      ] || `${group.name}-useNone`
                }
              >
                <Select onChange={v => changeElementUsage(v)}>
                  <Option
                    key={`${group.name}-useImage`}
                    value={`${group.name}-useImage`}
                  >
                    Imagem
                  </Option>

                  <Option
                    key={`${group.name}-useIcon`}
                    value={`${group.name}-useIcon`}
                  >
                    Ícone
                  </Option>

                  <Option
                    key={`${group.name}-useNone`}
                    value={`${group.name}-useNone`}
                  >
                    Nenhum
                  </Option>
                </Select>
              </Form.Item>

              {((group.name === 'stage1' &&
                stage1select === 'stage1-useImage') ||
                (group.name === 'stage2' &&
                  stage2select === 'stage2-useImage')) && (
                <>
                  <Form.Item
                    label="URL da imagem"
                    name={`template.params.${group.name}_image_url`}
                    initialValue={
                      element?.template?.params?.[`${group.name}_image_url`] ||
                      ''
                    }
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                    help={'Dimensão padrão: 600x600px'}
                  >
                    <Input
                      onChange={handleUrlChange}
                      value={
                        group.name === 'stage1'
                          ? stage1imageUrl
                          : stage2imageUrl
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Imagem"
                    name={`template.params.${group.name}_uploadedImage`}
                  >
                    <Upload
                      customRequest={options =>
                        customRequest(options, group.name)
                      }
                      listType="picture-card"
                      fileList={
                        group.name === 'stage1'
                          ? stage1fileList
                          : stage2fileList
                      }
                      maxCount={1}
                      accept="image/jpeg, image/png"
                      onRemove={v => deleteImgFromBucket(v, group.name)}
                    >
                      {((group.name === 'stage1' &&
                        stage1fileList.length < 1) ||
                        (group.name === 'stage2' &&
                          stage2fileList.length < 1)) &&
                        '+ Upload'}
                    </Upload>

                    {(group.name === 'stage1'
                      ? stage1imageErrorMessage
                      : stage2imageErrorMessage) && (
                      <>
                        <InfoCircleTwoTone twoToneColor="red" />{' '}
                        <span style={{ color: 'rgba(0,0,0,0.45)' }}>
                          {group.name === 'stage1'
                            ? stage1imageErrorMessage
                            : stage2imageErrorMessage}
                        </span>
                      </>
                    )}
                  </Form.Item>
                </>
              )}
            </>
          )}

          {Object.values(template.params)
            .filter(param => param.groupName === group.name)
            .map(param => {
              if (
                (param.name === 'stage1_icon' &&
                  stage1select !== 'stage1-useIcon') ||
                (param.name === 'stage2_icon' &&
                  stage2select !== 'stage2-useIcon')
              ) {
                return null;
              }

              return (
                <div
                  className="template_params_param"
                  key={`${template._id}:${param.name}`}
                >
                  <FormFieldComponent
                    form={props.form}
                    name={`template.params.${param.name}`}
                    label={param.label}
                    help={param.helper}
                    settedValue={
                      element.template &&
                      element.template.params &&
                      element.template.params[param.name]
                    }
                    defaultValue={param.default}
                    required={param.required}
                    frontendType={param.inputType}
                    dataType={param.type || 'string'}
                    options={param.options}
                    allowMulti={param.allow_multi}
                  />
                </div>
              );
            })}
        </div>
      ))}
    </>
  );
};

export default NotificationTemplateParams;
