import React from 'react';

import ClassificationsTab from './AdvancedConfigs/ClassificationsTab';
import FiltersTab from './BasicConfigs/FiltersTab';
import GeneralTab from './BasicConfigs/GeneralTab';
import ResultsTab from './BasicConfigs/ResultsTab';
import SearchInputTab from './BasicConfigs/SearchInputTab';
import SearchTestFrame from './SearchTest';
import SearchTypesTab from './AdvancedConfigs/SearchTypesTab';
import SynonymsTab from './BasicConfigs/SynonymsTab';
import MerchandisingRules from './BasicConfigs/MerchandisingRules';

const CAMPO_DE_PESQUISA = 'Campo de Pesquisa';
const CLASSIFICACOES = 'Classificações';
const FILTROS = 'Filtros';
const GERAIS = 'Gerais';
const REGRAS_DE_MERCHANDISING = 'Regras de Merchandising';
const RESULTADOS = 'Resultados';
const SINONIMOS = 'Sinônimos';
const TESTAR_BUSCA = 'Testar Busca';
const TIPOS_DE_BUSCA = 'Tipos de Busca';

const Folders = props => {
  switch (props.currentTab) {
    case GERAIS:
      return <GeneralTab {...props} />;

    case CAMPO_DE_PESQUISA:
      return <SearchInputTab {...props} />;

    case RESULTADOS:
      return <ResultsTab {...props} />;

    case FILTROS:
      return <FiltersTab {...props} />;

    case SINONIMOS:
      return <SynonymsTab {...props} />;

    case CLASSIFICACOES:
      return <ClassificationsTab {...props} />;

    case TIPOS_DE_BUSCA:
      return <SearchTypesTab {...props} />;

    case TESTAR_BUSCA:
      return <SearchTestFrame />;

    case REGRAS_DE_MERCHANDISING:
      return <MerchandisingRules {...props} />;

    default:
      return <></>;
  }
};

export default Folders;
