import React from 'react';
import { Tag } from 'antd';

const StatusTag = props => {
    const status = props.status || null;

    const tagProps = {};

    switch (status) {
        case 'published':
            tagProps.label = `Publicada`;
            tagProps.color = `green`;
            break;
        case 'draft':
            tagProps.label = `Rascunho`;
            tagProps.color = ``;
            break;
        case 'suspended':
            tagProps.label = `Suspensa`;
            tagProps.color = `gold`;
            break;
        case 'scheduled':
            tagProps.label = `Agendada`;
            tagProps.color = `geekblue`;
            break;
        default:
            tagProps.label = `Desconhecido`;
            tagProps.color = ``;
            break;
    }

    return <Tag {...tagProps}>{tagProps.label}</Tag>;
};

export default StatusTag;
