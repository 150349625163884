import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const TitleSettings = ({ titleTpls, form, tenantConfig }) => {
  if (!titleTpls?.length) return <></>;

  return (
    <>
      <Title level={4}>Templates de título</Title>
      {titleTpls.map(([key, value]) => (
        <FormFieldComponent
          form={form}
          name={key}
          key={key}
          {...value}
          defaultValue={value.default}
          hasFeedback={false}
          help={value.description}
          dataType={value.type}
          settedValue={tenantConfig[key]}
          className="search-form__grid search-form__grid__long-inputs"
        />
      ))}
    </>
  );
};

export default TitleSettings;
