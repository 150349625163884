import React from 'react';
import { Tag } from 'antd';

const ElementStatus = ({ isHidden, isPreview }) => {
  const tagColor = isHidden ? 'gray' : isPreview ? 'orange' : 'green';
  const tagLabel = isHidden ? 'Oculto' : isPreview ? 'Preview' : 'Ativo';

  return (
    <Tag className="tags-col__tag" color={tagColor}>
      {tagLabel}
    </Tag>
  );
};

export default ElementStatus;
