import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';

import SearchApiConsumer from '../../../../core/search/api';
import IndexationStatusTag from '../../../Common/Search/IndexationStatus/StatusTag';
import SearchIndexRebuildButton from './SearchIndexRebuildButton';

import '../styles/style.css';

const IndexationStatus = () => {
  const { client_id } = useParams();
  const [indexationStatus, setIndexationStatus] = useState([]);

  const getIndexationStatus = async () => {
    try {
      const { indexes } = await SearchApiConsumer.getIndexationStatus(
        client_id,
      );

      if (indexes) {
        setIndexationStatus(indexes);
      }
    } catch (e) {
      console.warn('getIndexationStatus failed with', e);
    }
  };

  useEffect(() => {
    getIndexationStatus();
  }, []);

  return (
    <Form.Item
      name="search-indexation-status"
      style={{
        padding: '7px',
        margin: 0,
        marginLeft: '1.5rem',
        border: '1px solid rgba(27, 31, 35, 0.15)',
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h4>Status de Indexação </h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {!indexationStatus.length ? (
            <IndexationStatusTag noIndex={true} />
          ) : (
            indexationStatus.map(index => (
              <IndexationStatusTag key={index._id} index={index} />
            ))
          )}
        </div>

        <SearchIndexRebuildButton getIndexationStatus={getIndexationStatus} />
      </div>
    </Form.Item>
  );
};

export default IndexationStatus;
