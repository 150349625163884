import uuid from 'uuid';

export default [{
        _id: uuid.v4(),
        username: 'demo',
        password: 'beon@123',
        acl: {
            development: true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'hipervarejo',
    //     password: '04610674',
    //     acl: {
    //         '04610674-3aa1-4e38-95dc-e38da87a8d0d': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'hipervarejo-vtex',
        password: '10139231',
        acl: {
            '10139231-98cc-4dd1-8be2-6393c87add58': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'imaginarium',
        password: 'e3f2340c',
        acl: {
            'e3f2340c-17c0-4c9c-bec0-7a2bfc89b842': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'staging',
        password: 'beon@123',
        acl: {
            'f9006b97-df94-4cc3-a0eb-93a387138f13': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'casamind',
        password: 'a3946eb8',
        acl: {
            'a3946eb8-460a-42a5-9a70-18359764a973': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'ludi',
        password: 'c1a1cdb5',
        acl: {
            'c1a1cdb5-9b4c-45f5-a0d7-01b9257ccd8d': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'barbaderespeito',
        password: '87fc171b',
        acl: {
            '87fc171b-8fd2-456a-a009-2b887fba8f6e': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'oculosshop',
    //     password: '0c0d724a',
    //     acl: {
    //         '0c0d724a-0630-46c7-b70e-7d47629ff406': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: uuid.v4(),
    //     username: 'puket',
    //     password: '409f6b81',
    //     acl: {
    //         '409f6b81-21e3-4cce-830a-8b06c2eef75f': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'schumann',
        password: 'dfaf1c11',
        acl: {
            'dfaf1c11-8d66-438c-b8e8-b4a06b2296a3': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'ropestore',
        password: '4b1eb4f0',
        acl: {
            '4b1eb4f0-9f69-40c0-998c-92f0eea4b852': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'armazemautopecas',
        password: '6880e65a',
        acl: {
            '6880e65a-447b-4b07-a07d-7d1d87a25172': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'cpapfit',
        password: '2d75e181',
        acl: {
            '2d75e181-a3a3-4c0c-b6e4-edcc7595d7c2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'ssshopping',
        password: '0db83257',
        acl: {
            '0db83257-b7d9-492a-a0b6-7d1d4d352089': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'asm',
        password: 'c2ff7d32',
        acl: {
            'c2ff7d32-0d6b-49b0-bdb4-43f2b07bb49e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'shopfisio',
        password: '9056ef93',
        acl: {
            '9056ef93-2da3-49cd-b926-568a22e78eb9': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'roche',
    //     password: '226b7a7f',
    //     acl: {
    //         '226b7a7f-1d50-4960-aa1b-629850af1c0d': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'lojacondi',
        password: '20bcfafd',
        acl: {
            '20bcfafd-7c1d-4c48-83c8-d6a687df346e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'yora',
        password: '4ea975d6',
        acl: {
            '89c6ebec-74a3-405e-b0fe-d6c02f19585f': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'manole',
        password: '0868383e',
        acl: {
            '0868383e-3860-48e3-b433-a6bb85b495a8': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'cerealista',
        password: '0c418771',
        acl: {
            '0c418771-6125-46a2-af24-4a21fbdbf8ca': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'iaza',
        password: '10636c28',
        acl: {
            '10636c28-8608-4e57-8430-67ad1145c826': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'grupogen',
        password: '801e56f78e67',
        acl: {
            'd618426f-b0c5-4769-ac13-801e56f78e67': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'pittol',
        password: 'bf86a1ae',
        acl: {
            'bf86a1ae-2b94-4070-8281-41ec3bbc38fa': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'hospinet',
        password: '3a29ea2d',
        acl: {
            '3a29ea2d-41b6-4d23-807b-738cc85f5d10': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'fibracirurgica',
        password: 'da117994',
        acl: {
            '51e8f821-0b5f-449f-8475-1948786872d5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'altese',
        password: '75d2d69a',
        acl: {
            '75d2d69a-478d-400d-98d2-2a2afe21d582': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'zelao',
        password: '44ea9093',
        acl: {
            '44ea9093-b272-42d4-97b9-b1ad4f2d05bb': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'darkside',
        password: '1276769b',
        acl: {
            '1276769b-683f-4d3e-b560-2d804c8f1304': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'beachcountry',
        password: '584d8571',
        acl: {
            '584d8571-9def-4bc8-9bb1-2434b9622864': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'descabeladas',
        password: 'c97d138d',
        acl: {
            'c97d138d-bd2c-42e4-9cf5-62080a152824': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'catral',
        password: '1e341f81',
        acl: {
            '1e341f81-e792-40af-8ed2-8d2856a2d562': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'officina7',
        password: '1f3a9ebe',
        acl: {
            '1f3a9ebe-8407-4fe9-a6e7-f5148d5fc04a': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'dressus',
        password: '03102618',
        acl: {
            '03102618-f252-4f96-9c2f-6914aa770e2e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'gang',
        password: 'ed28e254',
        acl: {
            'ed28e254-cd98-424f-a797-9584cede31d9': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'wqs',
    //     password: 'd406536f',
    //     acl: {
    //         'd406536f-82a1-4c14-aad9-68e230e91cc3': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //   _id: uuid.v4(),
    //   username: 'certel',
    //   password: 'a99f842d',
    //   acl: {
    //     'a99f842d-6360-47b8-84ef-bae67e682b9f': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'missmisses',
        password: '609a0bf7',
        acl: {
            '609a0bf7-cc07-43f7-aadf-07ea32de0743': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'missmisses-atacado',
        password: 'abbbffd7',
        acl: {
            'abbbffd7-ff06-483e-8174-0af917ac674e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'sipolatti',
        password: '71537796',
        acl: {
            '71537796-2685-41a6-ad55-859517037069': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'autoz',
        password: '47dce34b',
        acl: {
            '47dce34b-4c1d-44d3-bdcf-1c96456b2db4': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'fom',
        password: '52ac40aa',
        acl: {
            '52ac40aa-bb08-4fa4-a4a7-955415ab7742': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: uuid.v4(),
    //   username: 'brandili',
    //   password: 'ec39123f',
    //   acl: {
    //     'ec39123f-17e4-43f0-9b0a-479a0773077c': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'perlatto',
        password: '0e247d00',
        acl: {
            '0e247d00-8546-4a69-8eb6-ce31600fc28e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'incor',
        password: 'f57a6b65',
        acl: {
            'f57a6b65-951e-4326-ace7-5aac1d0b9730': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'espacorubronegro',
    //     password: 'bed3f5ca',
    //     acl: {
    //         'bed3f5ca-be5e-44b1-ba47-bab066bcba73': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'multisom',
        password: 'e15d1c96',
        acl: {
            'e15d1c96-f5a2-482e-a406-fc4a78538a20': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'dullius',
        password: '22de1779',
        acl: {
            '22de1779-d1d8-4b08-a625-016281f6efab': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'goofy',
        password: 'a017e2c6',
        acl: {
            'a017e2c6-3a97-4423-951f-78e851929242': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: uuid.v4(),
    //     username: 'bombayhs',
    //     password: '376f0da1',
    //     acl: {
    //         '376f0da1-d320-4b06-91b1-dfc87f1a8561': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'utilidadesclinicas',
        password: '3e33d446',
        acl: {
            '3e33d446-ab51-4d0a-b19e-2954f3c5f28c': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: uuid.v4(),
    //   username: 'artefeita',
    //   password: '2f4aa01c',
    //   acl: {
    //     '2f4aa01c-abb2-4ab7-8698-ccc434a15659': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'cajubrasil',
        password: '95f5abc8',
        acl: {
            '95f5abc8-6ee9-4bb9-ba66-1ecec9dd1c06': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'bagaggio',
        password: '6dbb2d87',
        acl: {
            '6dbb2d87-0a5c-4c0a-90eb-fd2f96ba794d': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'cecconello',
        password: '5159e620',
        acl: {
            '5159e620-c754-4683-a5ed-7c96d86443d2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'caciquehomecenter',
        password: 'ddbe190b',
        acl: {
            'ddbe190b-e311-421a-b9b0-4e90efdc74e8': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'fisiofernandes',
        password: '8fdcf581',
        acl: {
            '8fdcf581-bfbf-43aa-9b93-326052d70715': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'uatt',
        password: 'd5407ae5',
        acl: {
            'd5407ae5-67d6-44c2-b664-663e5e5b851a': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: uuid.v4(),
    //   username: 'dominik',
    //   password: '289ea4d2',
    //   acl: {
    //     '289ea4d2-53ce-4b3a-a8f6-294cad8b2caa': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //     _id: uuid.v4(),
    //     username: 'petzera',
    //     password: '8d760ee7',
    //     acl: {
    //         '8d760ee7-824e-45d5-ab25-66204547aaf0': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'rrmaquinas',
        password: '009027e9',
        acl: {
            '009027e9-796b-4383-b1ac-01471760172b': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'dpaschoal',
        password: 'ab097f8f',
        acl: {
            'ab097f8f-bb84-4731-8349-36dfb8298658': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'starki',
        password: '6ee5696f',
        acl: {
            '6ee5696f-bc74-434a-8dbd-7c59d1efc7dc': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: uuid.v4(),
    //   username: 'lbm',
    //   password: '4cd81c9f',
    //   acl: {
    //     '4cd81c9f-3188-41a9-97fb-7960d02afcab': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //   _id: uuid.v4(),
    //   username: 'proposto',
    //   password: '78d25f4d',
    //   acl: {
    //     '78d25f4d-5dbc-4a02-b7aa-f8020435f203': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: uuid.v4(),
        username: 'camucamu',
        password: '052a9433',
        acl: {
            '052a9433-52ef-4578-a5dd-095395838db7': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'karamello',
        password: '453eca74',
        acl: {
            '453eca74-80f7-4b3d-b25b-709ea2816f39': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'doremi',
        password: 'b15c6286',
        acl: {
            'b15c6286-a4ea-453a-823b-51e49fb1a14d': true,
        },
        token: uuid.v4(),
    },
    {
        _id: uuid.v4(),
        username: 'sheslaine',
        password: '07cd5a50',
        acl: {
            '07cd5a50-0307-4799-a640-a586286f5b15': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: '39cf9d4a-2f0a-4444-860b-b0f6709c62bb',
    //   username: 'grandesc',
    //   password: '39cf9d4a',
    //   acl: {
    //     '39cf9d4a-2f0a-4444-860b-b0f6709c62bb': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //   _id: 'c012f803-1b50-4077-917f-2f586bb66340',
    //   username: 'western',
    //   password: 'c012f803',
    //   acl: {
    //     'c012f803-1b50-4077-917f-2f586bb66340': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: '05397454-0313-42bc-b452-3b7fd49ededd',
        username: 'salvadorcomercial',
        password: '05397454',
        acl: {
            '05397454-0313-42bc-b452-3b7fd49ededd': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '88326148-ee3c-44d9-8acf-a29219e215a4',
        username: 'siparferramentas',
        password: '88326148',
        acl: {
            '88326148-ee3c-44d9-8acf-a29219e215a4': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '9ab4b7c0-7fe8-407c-97dd-2ff8a49bfec8',
    //     username: 'vestindoamesa',
    //     password: '134d4c68',
    //     acl: {
    //         '9ab4b7c0-7fe8-407c-97dd-2ff8a49bfec8': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: '6c13dd86-7427-4504-90b3-55e9e97076f3',
    //     username: 'estiloeconforto',
    //     password: '6c13dd86',
    //     acl: {
    //         '6c13dd86-7427-4504-90b3-55e9e97076f3': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: 'f37f9e3f-8948-4525-8799-018f52ff8555',
        username: 'medcomex',
        password: 'f37f9e3f',
        acl: {
            'f37f9e3f-8948-4525-8799-018f52ff8555': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '979fc8cb-70a5-43cb-b9d7-54189ad28cd5',
        username: 'benoit',
        password: '979fc8cb',
        acl: {
            '979fc8cb-70a5-43cb-b9d7-54189ad28cd5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'bce9c53e-4e2c-4891-b8dc-4cec5579247c',
        username: 'offpricemob',
        password: 'bce9c53e',
        acl: {
            'bce9c53e-4e2c-4891-b8dc-4cec5579247c': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '485b1688-a174-4c67-8516-352b3437abd2',
    //     username: 'ipermaq',
    //     password: '485b1688',
    //     acl: {
    //         '485b1688-a174-4c67-8516-352b3437abd2': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: '705a91b0-acd8-4a3c-83cf-a605b26d5268',
    //     username: 'feiraodemoveis',
    //     password: '705a91b0',
    //     acl: {
    //         '705a91b0-acd8-4a3c-83cf-a605b26d5268': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '03ef72a5-fcae-4313-a2c4-770dd425cfe7',
        username: 'galeradonarguile',
        password: '03ef72a5',
        acl: {
            '03ef72a5-fcae-4313-a2c4-770dd425cfe7': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'a843c769-6ef9-464e-9508-5fe92d2b52cf',
        username: 'apiguana',
        password: 'a843c769',
        acl: {
            'a843c769-6ef9-464e-9508-5fe92d2b52cf': true,
        },
        token: uuid.v4(),
    },
    // {
    //   _id: '9bbbae70-9730-4216-bf22-b48be22c5d95',
    //   username: 'ergovita',
    //   password: '9bbbae70',
    //   acl: {
    //     '9bbbae70-9730-4216-bf22-b48be22c5d95': true,
    //   },
    //   token: uuid.v4(),
    // },
    {
        _id: 'b6fbde67-d181-4d3a-a007-f9db792985db',
        username: 'lojasrubi',
        password: 'b6fbde67',
        acl: {
            'b6fbde67-d181-4d3a-a007-f9db792985db': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '6b51d551-5fd9-4ce5-b43f-baca4654db00',
    //     username: 'oticasvix',
    //     password: '6b51d551',
    //     acl: {
    //         '6b51d551-5fd9-4ce5-b43f-baca4654db00': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: 'a0b04440-ef03-4f09-bed4-e199254d97fc',
        username: 'itau-click',
        password: 'a0b04440',
        acl: {
            'a0b04440-ef03-4f09-bed4-e199254d97fc': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '6fa777f5-9b8a-45bb-905e-27fe6afa91f5',
        username: 'itau-vivoplatinum',
        password: '6fa777f5',
        acl: {
            '6fa777f5-9b8a-45bb-905e-27fe6afa91f5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '8d231e11-a266-47f0-97ec-ac8f015f5df2',
        username: 'itau-latam',
        password: '8d231e11',
        acl: {
            '8d231e11-a266-47f0-97ec-ac8f015f5df2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '57d8e2cc-540e-477f-89d6-51a0218b1ea1',
        username: 'beon',
        password: '57d8e2cc',
        acl: {
            '57d8e2cc-540e-477f-89d6-51a0218b1ea1': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '80990804-c857-4202-ae38-d00e8a0ae055',
        username: 'upiara.online',
        password: '80990804',
        acl: {
            '80990804-c857-4202-ae38-d00e8a0ae055': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'a49970f2-3cb1-4f0d-a676-0c6665956c56',
        username: 'leonorab2c',
        password: 'ef60935a',
        acl: {
            'a49970f2-3cb1-4f0d-a676-0c6665956c56': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '4bb676c4-e018-4060-93f0-4a6da7722e72',
        username: 'leonorab2b',
        password: '4bb676c4',
        acl: {
            '4bb676c4-e018-4060-93f0-4a6da7722e72': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: 'a68bb2a0-4b9d-4298-b2c4-49be2c44642a',
    //     username: 'essencia-do-vale',
    //     password: 'a68bb2a0',
    //     acl: {
    //         'a68bb2a0-4b9d-4298-b2c4-49be2c44642a': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: '333cdf1b-13d4-4fce-ba7e-f1daa7d79f1c',
    //     username: 'simulassao',
    //     password: '333cdf1b',
    //     acl: {
    //         '333cdf1b-13d4-4fce-ba7e-f1daa7d79f1c': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: 'dcf3a4c4-01af-4443-87f9-09b4267c0d64',
        username: 'ipromove',
        password: 'dcf3a4c4',
        acl: {
            'dcf3a4c4-01af-4443-87f9-09b4267c0d64': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '37757692-6e80-48ad-b0fb-8e9b04e3248d',
    //     username: 'republic-paradise',
    //     password: '37757692',
    //     acl: {
    //         '37757692-6e80-48ad-b0fb-8e9b04e3248d': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: 'aed82a0a-1e9b-41bf-b2f0-d889397326ec',
    //     username: 'soulier',
    //     password: 'aed82a0a',
    //     acl: {
    //         'aed82a0a-1e9b-41bf-b2f0-d889397326ec': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: 'f2f8ccc4-f939-4b08-93a8-977c25da0497',
    //     username: 'portal-do-aroma',
    //     password: 'f2f8ccc4',
    //     acl: {
    //         'f2f8ccc4-f939-4b08-93a8-977c25da0497': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: 'f236141e-667d-4e1d-a94a-3231ecfe4388',
    //     username: 'livrarias-curitiba',
    //     password: 'f236141e',
    //     acl: {
    //         'f236141e-667d-4e1d-a94a-3231ecfe4388': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //   _id: '1eb12026-8a53-49b9-8299-b40f87daab47',
    //   username: 'megalivros',
    //   password: '1eb12026',
    //   acl: {
    //     '1eb12026-8a53-49b9-8299-b40f87daab47': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //     _id: 'c2097b72-42e9-4e4f-b7fe-516646a2c3d4',
    //     username: 'casa-da-mobília',
    //     password: 'c2097b72',
    //     acl: {
    //         'c2097b72-42e9-4e4f-b7fe-516646a2c3d4': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: '811d60c4-4bff-4347-8843-28629f0d5f16',
    //     username: 'geek-point',
    //     password: '811d60c4',
    //     acl: {
    //         '811d60c4-4bff-4347-8843-28629f0d5f16': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '61edaff5-9aa6-41a0-8ee6-257b06ab8af2',
        username: 'lojas-pes',
        password: '61edaff5',
        acl: {
            '61edaff5-9aa6-41a0-8ee6-257b06ab8af2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'a416f954-6297-4f9d-91cf-36914dbd6fcc',
        username: '4bio',
        password: 'a416f954',
        acl: {
            'a416f954-6297-4f9d-91cf-36914dbd6fcc': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '3ba60e93-80c9-4728-9bec-7a5d5745e4ef',
        username: 'mooui',
        password: '3ba60e93',
        acl: {
            '3ba60e93-80c9-4728-9bec-7a5d5745e4ef': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'e17018b9-d20f-413e-8cd2-c7aaea8920ff',
        username: 'livrariacultura',
        password: 'e17018b9',
        acl: {
            'e17018b9-d20f-413e-8cd2-c7aaea8920ff': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'a326c666-3c95-4cc7-b006-c3a89ccc39c1',
        username: 'fabrispuma',
        password: 'a326c666',
        acl: {
            'a326c666-3c95-4cc7-b006-c3a89ccc39c1': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '7172cb51-438d-45d2-8b20-85ea19c8c7ab',
        username: 'jm-equipamentos',
        password: '53d7a0c7',
        acl: {
            '7172cb51-438d-45d2-8b20-85ea19c8c7ab': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: 'c2345423-1fe3-4414-a44d-56505bc7efe7',
    //     username: 'charth',
    //     password: 'b552ff17',
    //     acl: {
    //         'c2345423-1fe3-4414-a44d-56505bc7efe7': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: 'a9e74451-1abd-4ab5-9413-a790b5e99989',
        username: 'pagmenos',
        password: 'ff2f7d24',
        acl: {
            'a9e74451-1abd-4ab5-9413-a790b5e99989': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'c3044deb-d088-4b70-ae88-09fffd65a450',
        username: 'edmizuno',
        password: 'c3044deb',
        acl: {
            'c3044deb-d088-4b70-ae88-09fffd65a450': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '51ccf30b-28f6-4e64-a141-731e02a5fbae',
    //     username: 'falconarmas',
    //     password: '51ccf30b',
    //     acl: {
    //         '51ccf30b-28f6-4e64-a141-731e02a5fbae': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: '5ae1ef3c-be2e-411b-a64d-2a751bac12b3',
    //     username: 'marshoes',
    //     password: '5ae1ef3c',
    //     acl: {
    //         '5ae1ef3c-be2e-411b-a64d-2a751bac12b3': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //   _id: '635a144e-b4b4-44f5-bd69-78dc5cc73b2b',
    //   username: 'a3parts',
    //   password: '635a144e',
    //   acl: {
    //     '635a144e-b4b4-44f5-bd69-78dc5cc73b2b': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //   _id: '6b34a890-39b5-41ee-bd4b-4d7310ee4c92',
    //   username: 'ativa-esportes',
    //   password: '6b34a890',
    //   acl: {
    //     '6b34a890-39b5-41ee-bd4b-4d7310ee4c92': true,
    //   },
    //   token: uuid.v4(),
    // },
    // {
    //     _id: 'fb3b90b8-0de7-4af6-aade-bf45a789cdf5',
    //     username: 'authen',
    //     password: '7a266641',
    //     acl: {
    //         'fb3b90b8-0de7-4af6-aade-bf45a789cdf5': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '64825ef8-c0e9-4072-b8fc-8340cb2b8ea5',
        username: 'verona',
        password: '64825ef8',
        acl: {
            '64825ef8-c0e9-4072-b8fc-8340cb2b8ea5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '3546dc07-07ff-431a-9696-a2f5f6f09c7d',
        username: 'jvtubos',
        password: '3546dc07',
        acl: {
            '3546dc07-07ff-431a-9696-a2f5f6f09c7d': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'd6bd534b-e943-445c-9df6-9dcb53001c8c',
        username: 'malina',
        password: 'b3c094c9',
        acl: {
            'd6bd534b-e943-445c-9df6-9dcb53001c8c': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '1767eb37-c55f-4445-869f-4a5c5b7b4d20',
        username: 'bennemann',
        password: '1767eb37',
        acl: {
            '1767eb37-c55f-4445-869f-4a5c5b7b4d20': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '008cc551-8881-4829-a26b-7e23c32958a0',
    //     username: 'gshock',
    //     password: 'c325d8c5',
    //     acl: {
    //         '008cc551-8881-4829-a26b-7e23c32958a0': true,
    //     },
    //     token: uuid.v4(),
    // },
    // {
    //     _id: 'c84548b6-06a8-4a03-935a-43b80165ae4e',
    //     username: 'newlooktime',
    //     password: 'dbe1620b',
    //     acl: {
    //         'c84548b6-06a8-4a03-935a-43b80165ae4e': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '2cdd9848-5b95-442a-88c4-95224f4e590f',
        username: 'moveisgruber',
        password: '2cdd9848',
        acl: {
            '2cdd9848-5b95-442a-88c4-95224f4e590f': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'f31491ea-3426-4ed9-a437-7f93fde875f2',
        username: 'nutri-nutri',
        password: 'f31491ea',
        acl: {
            'f31491ea-3426-4ed9-a437-7f93fde875f2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '9cec47dd-5d6b-489e-af2a-2d55317c7378',
        username: 'sportamerica',
        password: '9cec47dd',
        acl: {
            '9cec47dd-5d6b-489e-af2a-2d55317c7378': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '6b7d1b48-a0bc-4298-940c-1a007840b3e8',
        username: 'topwines',
        password: '6b7d1b48',
        acl: {
            '6b7d1b48-a0bc-4298-940c-1a007840b3e8': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'b26ee5a7-4e1f-4ca3-84ad-6dd242250214',
        username: 'es-cerealista',
        password: 'b26ee5a7',
        acl: {
            'b26ee5a7-4e1f-4ca3-84ad-6dd242250214': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'f8250180-2e08-48fd-9b19-307897273906',
        username: 'agricolatupa',
        password: 'f8250180',
        acl: {
            'f8250180-2e08-48fd-9b19-307897273906': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: '96c6afb2-ae1f-4020-aa93-ee9720c5b178',
    //     username: 'aquamaris',
    //     password: '96c6afb2',
    //     acl: {
    //         '96c6afb2-ae1f-4020-aa93-ee9720c5b178': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '94152086-d386-4c7f-a186-b57c69931a06',
        username: 'autoglass',
        password: '94152086',
        acl: {
            '94152086-d386-4c7f-a186-b57c69931a06': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'ebff3a50-e1bf-48b8-8a2e-3116367e6652',
        username: 'oxford',
        password: 'ebff3a50',
        acl: {
            'ebff3a50-e1bf-48b8-8a2e-3116367e6652': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'c0d2ef08-43c9-4d3e-8dc9-946f4a905a4e',
        username: 'smartkits',
        password: 'c0d2ef08',
        acl: {
            'c0d2ef08-43c9-4d3e-8dc9-946f4a905a4e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'f4683578-0dcb-4dc8-a563-a7ce00fbc302',
        username: 'lojawaz',
        password: 'f4683578',
        acl: {
            'f4683578-0dcb-4dc8-a563-a7ce00fbc302': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'e4b72e38-33b8-416c-9a8f-8526be23da5a',
        username: 'arraisshop',
        password: 'e4b72e38',
        acl: {
            'e4b72e38-33b8-416c-9a8f-8526be23da5a': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'cbaec2d1-d476-4c7d-a74d-38fd0097badf',
        username: 'dlentes',
        password: 'cbaec2d1',
        acl: {
            'cbaec2d1-d476-4c7d-a74d-38fd0097badf': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '78dadcd3-f495-489b-9cdb-2e7d47d0c1a0',
        username: 'personcanecas',
        password: '78dadcd3',
        acl: {
            '78dadcd3-f495-489b-9cdb-2e7d47d0c1a0': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '365fe4f9-59b5-4897-a08c-23bc953bd8f6',
        username: 'sixtini',
        password: '365fe4f9',
        acl: {
            '365fe4f9-59b5-4897-a08c-23bc953bd8f6': true,
        },
        token: uuid.v4(),
    },
    // {
    //     _id: 'a019d64d-a8fd-4bb6-a5a1-031259719b32',
    //     username: 'laconquista',
    //     password: 'a019d64d',
    //     acl: {
    //         'a019d64d-a8fd-4bb6-a5a1-031259719b32': true,
    //     },
    //     token: uuid.v4(),
    // },
    {
        _id: '471ee5b0-d4a7-4362-afca-9baae229ea32',
        username: 'drossi',
        password: '471ee5b0',
        acl: {
            '471ee5b0-d4a7-4362-afca-9baae229ea32': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'b9c5670f-26ff-44cb-b0c8-2a48ab0efa9f',
        username: '24print',
        password: 'b9c5670f',
        acl: {
            'b9c5670f-26ff-44cb-b0c8-2a48ab0efa9f': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '8f3d9875-9264-40fa-8c72-38567d4072f8',
        username: 'maxxitacos',
        password: '8f3d9875',
        acl: {
            '8f3d9875-9264-40fa-8c72-38567d4072f8': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '58d7098a-15a8-499d-ae1a-5dc167441fc2',
        username: 'nobbli',
        password: '58d7098a',
        acl: {
            '58d7098a-15a8-499d-ae1a-5dc167441fc2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '497cb924-b773-46f8-b25e-69e20ba57ea4',
        username: 'momispetit',
        password: '497cb924',
        acl: {
            '497cb924-b773-46f8-b25e-69e20ba57ea4': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'bb684d25-a547-426f-bce4-f185977b59f9',
        username: 'clovisvarejo',
        password: 'bb684d25',
        acl: {
            'bb684d25-a547-426f-bce4-f185977b59f9': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'e1b2d984-5bba-4baa-89ef-b1917a047e7a',
        username: 'clovisatacado',
        password: 'e1b2d984',
        acl: {
            'e1b2d984-5bba-4baa-89ef-b1917a047e7a': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'e53de932-e7bd-4c20-a2cc-c31daf6482e9',
        username: 'outpec',
        password: 'e53de932',
        acl: {
            'e53de932-e7bd-4c20-a2cc-c31daf6482e9': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'd8dc7ff6-3450-4685-864f-e024733206c8',
        username: 'acamargo',
        password: 'd8dc7ff6',
        acl: {
            'd8dc7ff6-3450-4685-864f-e024733206c8': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '7cce04ec-cda3-4889-8d2f-9d63856ae823',
        username: 'anjuss',
        password: '7cce04ec',
        acl: {
            '7cce04ec-cda3-4889-8d2f-9d63856ae823': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'da750463-7fb8-4693-9b7a-cabe89a3c7cb',
        username: 'itau-brastemp',
        password: 'da750463',
        acl: {
            'da750463-7fb8-4693-9b7a-cabe89a3c7cb': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '4c4dd5ca-d25d-4430-b0b8-8dfaf7dc6e18',
        username: 'itau-mit',
        password: '4c4dd5ca',
        acl: {
            '4c4dd5ca-d25d-4430-b0b8-8dfaf7dc6e18': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '5bfe1707-3291-49e7-a140-e8f4362cc6e9',
        username: 'itau-azul',
        password: '5bfe1707',
        acl: {
            '5bfe1707-3291-49e7-a140-e8f4362cc6e9': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '64cd906a-39f5-4d5c-ba78-0db7cda9df39',
        username: 'itau-fiat',
        password: '64cd906a',
        acl: {
            '64cd906a-39f5-4d5c-ba78-0db7cda9df39': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'ccc22261-bdc6-4ecd-bd4c-4139a75644a7',
        username: 'itau-ias',
        password: 'ccc22261',
        acl: {
            'ccc22261-bdc6-4ecd-bd4c-4139a75644a7': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '9ecad431-7c53-4934-8dde-3681b4cf88b4',
        username: 'itau-credicard',
        password: '9ecad431',
        acl: {
            '9ecad431-7c53-4934-8dde-3681b4cf88b4': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '20ce8f8c-f760-4a77-aa03-46964866636b',
        username: 'portalinfantil',
        password: '20ce8f8c',
        acl: {
            '20ce8f8c-f760-4a77-aa03-46964866636b': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '857dafc7-91c0-45d2-8830-dc558b0f4e2e',
        username: 'autoglassb2b',
        password: '857dafc7',
        acl: {
            '857dafc7-91c0-45d2-8830-dc558b0f4e2e': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'd2d0fbcb-7591-4778-b9b1-8a50ffe55a68',
        username: 'endocommerce',
        password: 'd2d0fbcb',
        acl: {
            'd2d0fbcb-7591-4778-b9b1-8a50ffe55a68': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'a63f98d1-6cd5-4fa4-9c8d-b4eb52799d60',
        username: 'atacadaonatural',
        password: 'a63f98d1',
        acl: {
            'a63f98d1-6cd5-4fa4-9c8d-b4eb52799d60': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'fd40f830-ea13-404d-b056-93ec30f4788f',
        username: 'maniadmulher',
        password: 'fd40f830',
        acl: {
            'fd40f830-ea13-404d-b056-93ec30f4788f': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '39ce87d1-d712-4449-8b33-63151d181911',
        username: 'leomaquinas',
        password: '39ce87d1',
        acl: {
            '39ce87d1-d712-4449-8b33-63151d181911': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '4d6d6fd5-f790-418f-aee3-e32bb81e14ab',
        username: 'doux',
        password: '4d6d6fd5',
        acl: {
            '4d6d6fd5-f790-418f-aee3-e32bb81e14ab': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'e20ecc45-5415-4376-8495-f86267bc79d5',
        username: 'chatuba',
        password: 'e20ecc45',
        acl: {
            'e20ecc45-5415-4376-8495-f86267bc79d5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '4f472933-645d-4deb-ae0f-49f3c8fe8a93',
        username: 'fiosdafazenda',
        password: '4f472933',
        acl: {
            '4f472933-645d-4deb-ae0f-49f3c8fe8a93': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '259420b1-f8eb-4c84-bae8-896a67a5f737',
        username: 'philliplondon',
        password: '259420b1',
        acl: {
            '259420b1-f8eb-4c84-bae8-896a67a5f737': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '57c9e40f-7ac3-48ce-bd79-4c1cbc3f3910',
        username: 'neshop',
        password: '57c9e40f',
        acl: {
            '57c9e40f-7ac3-48ce-bd79-4c1cbc3f3910': true,
        },
        token: uuid.v4(),
    },
    {
        _id: 'd58f685d-e124-4d09-90b9-091ae74d1562',
        username: 'mtnestore',
        password: 'd58f685d',
        acl: {
            'd58f685d-e124-4d09-90b9-091ae74d1562': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '57c3b31a-343b-49d0-ab2d-848690d22a91',
        username: 'eletroforte',
        password: '57c3b31a',
        acl: {
            '57c3b31a-343b-49d0-ab2d-848690d22a91': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '6ef228f6-56d3-4d41-9986-cc751bac04b5',
        username: 'planto',
        password: '6ef228f6',
        acl: {
            '6ef228f6-56d3-4d41-9986-cc751bac04b5': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '7ee11b1e-f2ca-4d9f-baaa-a3a6e77e26e2',
        username: 'plantei',
        password: '7ee11b1e',
        acl: {
            '7ee11b1e-f2ca-4d9f-baaa-a3a6e77e26e2': true,
        },
        token: uuid.v4(),
    },
    {
        _id: '62b98b5e-b171-410b-ac6b-c6e8ed767b34',
        username: 'dpa-b2b-dpk',
        password: '62b98b5e',
        acl: {
            '62b98b5e-b171-410b-ac6b-c6e8ed767b34': true,
        },
        token: uuid.v4(),
    },
];
