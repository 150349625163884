import uuid from 'uuid';
import createConsumer from '../../rest/createConsumer';
import staticContexts from '../static/contexts';
import staticPages from '../static/pages';
import staticElements from '../static/elements';

const ContentApiStaticConsumer = createConsumer(consumer => {
  // CONTEXTS TYPES

  const getContexts = () =>
    new Promise(resolve => {
      resolve(staticContexts);
    });

  const getContextsTree = clientId =>
    new Promise(async resolve => {
      const contexts = await getContexts();
      const pages = await getPages();

      const tree = contexts.map(context => {
        context.pagesSummary = pages.filter(
          page => page.context.name === context.name,
        );

        return context;
      });

      resolve(tree);
    });

  // PAGES

  const getPages = clientId =>
    new Promise(resolve => {
      resolve(staticPages);
    });

  const getPage = (clientId, pageId) =>
    new Promise(async resolve => {
      const pages = await getPages();
      const page = pages.find(page => page._id === pageId);

      resolve(page);
    });

  const savePage = page =>
    new Promise(async resolve => {
      let existingPage = await getPage(null, page._id);

      if (!existingPage) staticPages.push(page);
      else existingPage = page;

      resolve(page);
    });

  const deletePage = (clientId, pageId) =>
    new Promise(async resolve => {
      const deletingPage = await getPage(pageId);
      if (deletingPage) deletePage.deleted_at = Date.now();

      resolve(deletingPage);
    });

  // ELEMENTS

  const getElementsTypes = () =>
    new Promise(resolve => resolve(staticElements));

  // STATIC COMPONENTS

  const makePage = ({
    client_id,
    context,
    label = `Nova versão`,
    regions = [],
    elements = [],
    props = {},
  }) =>
    new Promise(resolve => {
      const finalProps = Object.assign(
        {
          isHidden: true,
          isDefault: false,
          status: `draft`,
          triggers: {
            pageType: null,
            url: { match: null, useRegex: false },
            date: { start: null, end: null },
          },
        },
        props,
        { label },
      );

      const page = {
        _id: uuid.v4(),
        client_id,
        context,
        regions,
        elements,
        props: finalProps,
      };

      resolve(page);
    });

  const duplicatePage = page =>
    new Promise(resolve => {
      const newest = JSON.parse(JSON.stringify(page));

      newest._id = uuid.v4();
      newest.props = Object.assign(newest.props, {
        isHidden: true,
        isDefault: false,
        status: `draft`,
        version: 2,
        label: `Cópia de ${newest.props.label}`,
      });

      // all regions and elements must receive new ids too
      newest.regions = newest.regions.map(region => {
        region._oid = region._id;
        region._id = uuid.v4();
        return region;
      });

      newest.elements = newest.elements.map(element => {
        element._id = uuid.v4();

        const region = newest.regions.find(
          region => region._oid === element.region_id,
        );

        element.region_id = region._id;

        return element;
      });

      console.log(page, newest);
      resolve(newest);
    });

  const makeRegion = ({
    label = `Nova área de layout`,
    placement = { op: null, selector: null, replace: false },
    props = { isHidden: false, region_type: 'normal', inactive_time: 0, auto_dismiss: 0, width: '1000px', height: '1000px', background_color: '#000', opacity: 0.7 },
    elements = [],
  }) =>
    new Promise(resolve => {
      const region = {
        _id: uuid.v4(),
        label,
        placement,
        props,
        elements,
      };

      resolve(region);
    });

  const makeElement = ({
    region_id,
    type,
    label = `Novo elemento`,
    props = {},
    template = {},
    segmentation = [],
  }) =>
    new Promise(resolve => {
      const element = {
        _id: uuid.v4(),
        region_id,
        type,
        label,
        props: { ...props, label },
        template,
        segmentation,
      };

      resolve(element);
    });

  // INTERFACE

  return Object.freeze({
    getContextsTree,
    getPages,
    getPage,
    savePage,
    deletePage,
    duplicatePage,
    makePage,
    makeRegion,
    makeElement,
    getElementsTypes,
    offline: true,
  });
});

export default ContentApiStaticConsumer;
