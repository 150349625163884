import React from 'react';
import { Button } from 'antd';
import { ControlOutlined } from '@ant-design/icons';

const RegionActionEdit = (props) => {
    const handle = props.handle;

    return (
        <Button
            type="ghost"
            shape="round"
            icon={<ControlOutlined />}
            size="small"
            onClick={handle}
        >
            <span>Configurações</span>
        </Button>
    );
};

export default RegionActionEdit;
