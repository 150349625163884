import EventEmitter from 'events';

export const withEmitter = function(factory, events) {
    const emitter = new EmitterInterface({ events });
    factory.prototype.emitter = emitter;

    return factory;
};

export const EmitterInterface = function({ events = {} }) {
    this.emitter = undefined;
    this.events = events;

    this.init();

    return this;
};

EmitterInterface.prototype.init = function() {
    this.emitter = new EventEmitter();

    Object.entries(this.events).forEach(([key, definition]) =>
        Object.defineProperty(this, key, {
            get: () => definition,
        })
    );
};

EmitterInterface.prototype.dispatch = function(event, ...eventArgs) {
    console.log(`page context dispatching ${event}`, eventArgs);

    if (!this.events.hasOwnProperty(event)) {
        throw Error(`Invalid event type at Content Page Emitter`);
    }

    this.emitter.emit(event, ...eventArgs);
};

EmitterInterface.prototype.on = function(event, fn) {
    console.log(`page context subscribing to ${event}`);

    if (!this.events.hasOwnProperty(event)) {
        throw Error(`Invalid event type at Content Page Emitter`);
    }

    this.emitter.on(event, fn);

    return [event, fn];
};

EmitterInterface.prototype.off = function([event, fn]) {
    console.log(`page context unsubscribing to ${event}`);

    if (!this.events.hasOwnProperty(event)) {
        throw Error(`Invalid event type at Content Page Emitter`);
    }

    this.emitter.off(event, fn);
};
