import React from 'react';
import { Checkbox, Form } from 'antd';

const CheckboxItem = ({ props }) => {
  const { defaultValue, hasFeedback, help, label, name, options, rules } =
    props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
    >
      <Checkbox.Group options={options} defaultValue={defaultValue} />
    </Form.Item>
  );
};

export default CheckboxItem;
