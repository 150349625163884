import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Popconfirm, Typography } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import AdjustmentsSettings from '../../../../../Common/Search/SearchConfig/AdjustmentsSettings';
import RelevanceOrderSettings from '../../../../../Common/Search/SearchConfig/RelevanceOrderSettings';

const { Title, Paragraph } = Typography;

const BACKEND = 'backend';
const GENERAL = 'general';
const ORDER = 'order';
const RELEVANCE = 'relevance';

const SearchTypesTab = ({
  formItems,
  handleSubmit,
  tenantConfig,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [adjustments, setAdjustments] = useState([]);
  const [relevanceOrder, setRelevanceOrder] = useState([]);

  useEffect(() => {
    const { properties } = formItems;

    const adjustmentsItems = filterByKeywords(properties, [
      BACKEND,
      RELEVANCE,
    ]).filter(([key, _]) => !key.includes(ORDER));

    const relevanceOrderItems = filterByKeywords(properties, [
      BACKEND,
      ORDER,
      RELEVANCE,
    ]);

    setAdjustments(adjustmentsItems);
    setRelevanceOrder(relevanceOrderItems);
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      size="large"
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <Title level={4}>Ajustes Finos</Title>

        <Paragraph>
          São pequenos ajustes na forma como a pesquisa funciona para refinar o
          funcionamento da solução na sua loja. Normalmente você não precisa se
          preocupar com essas configurações.
        </Paragraph>

        <RelevanceOrderSettings
          form={form}
          relevanceOrder={relevanceOrder}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <AdjustmentsSettings
          form={form}
          adjustments={adjustments}
          tenantConfig={tenantConfig}
        />
      </div>
    </Form>
  );
};

export default SearchTypesTab;
