export default [
    {
        _id: 'uuid-12312',
        name: 'showcase_slider',
        label: 'Vitrine no formato slider',
        description:
            'Lista de produtos em um carousel. Você pode controlar quantos produtos ficam visíveis por página.',
        elementType: 'showcase',
        paramsGroups: {
            default: {
                name: 'default',
                label: 'Configurações de layout',
            },
            slider: {
                name: 'slider',
                label: 'Configurações do slider',
            },
        },
        params: {
            title: {
                name: 'title',
                label: 'Título da vitrine',
                required: true,
                order: 0,
                groupName: 'default',
            },
            event_label: {
                name: 'event_label',
                label: 'Nome do evento de rastreamento',
                help:
                    'Nome do evento que será registrado no Google Analytics quando um usuário interagir com esse elemento. Exemplo: Vitrine mais vendidos.',
                required: true,
                order: 2,
                groupName: 'default',
            },
            cta_buy_label: {
                name: 'cta_buy_label',
                label: 'Nome do botão principal',
                help:
                    'Texto que aparecerá no botão principal do item na vitrine, normalmente indicando a compra do produto.',
                required: false,
                default: 'Adicionar ao Carrinho',
                order: 3,
                groupName: 'default',
            },
            cta_details_label: {
                name: 'cta_details_label',
                label: 'Nome do botão secundário',
                help:
                    'Texto que aparecerá no botão secundário do item na vitrine, normalmente indicando a busca por mais detalhes sobre o produto.',
                required: false,
                default: 'Ver mais',
                order: 4,
                groupName: 'default',
            },
            container_class: {
                name: 'container_class',
                label: 'Atributo class',
                help: 'Valor do atributo class do container do elemento.',
                required: false,
                default: '',
                order: 5,
                groupName: 'default',
            },
            container_style: {
                name: 'container_style',
                label: 'Atributo style',
                help: 'Valor do atributo style do container do elemento.',
                required: false,
                default: '',
                order: 6,
                groupName: 'default',
            },
            container_config: {
                name: 'container_config',
                label: 'Atributo data-config',
                help: 'Atributo reservado.',
                required: false,
                default:
                    '{"viewports":{"0":{"i": 1,"s": 1},"1024":{"i": 4,"s": 1,"b": 10}}}',
                order: 7,
                groupName: 'slider',
            },
            free_shipping_price: {
                name: 'free_shipping_price',
                label: 'Valor base para frete grátis',
                help:
                    'Valor mínimo para atribuição de frete grátis. Se o produto tiver um preço maior ou igual, a flag de Frete Grátis será exibida no item.',
                required: false,
                type: 'number',
                order: 8,
                groupName: 'default',
            },
        },
    },
    {
        _id: 'uuid-12211221',
        name: 'showcase_grid',
        label: 'Vitrine no formato Grid',
        description:
            'Lista de produtos dispostos lado a lado em múltiplas linhas. Você pode controlar quantos produtos por linha.',
        elementType: 'showcase',
        paramsGroups: {
            default: {
                name: 'default',
                label: 'Configurações de layout',
            },
            slider: {
                name: 'slider',
                label: 'Configurações do slider',
            },
        },
        params: {
            title: {
                name: 'title',
                label: 'Título da vitrine',
                required: true,
                order: 0,
                groupName: 'default',
                help:
                    'Título que acompanhará a listagem de produtos, aparece para seu cliente.',
            },
        },
    },
    {
        _id: 'uuid-8542',
        name: 'cta_popup',
        label: 'CTA popup',
        elementType: 'cta',
        paramsGroups: {
            default: {
                name: 'default',
                label: 'Configurações de layout',
            },
        },
        params: {
            event_label: {
                name: 'event_label',
                label: 'Nome do evento de rastreamento',
                help:
                    'Nome do evento que será rastreado no Google Analytics. Exemplo: homepage_mais_vendidos',
                required: true,
                order: 2,
                groupName: 'default',
            },
            cta_buy_label: {
                name: 'cta_buy_label',
                label: 'Label do botão principal',
                help: '',
                required: true,
                default: 'Adicionar ao Carrinho',
                order: 3,
                groupName: 'default',
            },
            container_class: {
                name: 'container_class',
                label: 'Atributo class',
                help: 'Valor do atributo class do container do elemento.',
                required: false,
                default: '',
                order: 5,
                groupName: 'default',
            },
            container_style: {
                name: 'container_style',
                label: 'Atributo style',
                help: 'Valor do atributo style do container do elemento.',
                required: false,
                default: '',
                order: 6,
                groupName: 'default',
            },
        },
    },
    {
        _id: 'uuid-5464',
        name: 'snippet_default',
        label: 'Código HTML',
        description: 'Imprime seu código HTML na página.',
        elementType: 'snippet',
    },
];
