import React, { useEffect, useState } from 'react';
import { Form, Table, Typography } from 'antd';

import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const { Title } = Typography;

const columns = [
  {
    key: 'sort',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
  },
  {
    title: 'Ordem de relevância',
    dataIndex: 'order',
  },
];

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const RelevanceOrderSettings = ({ form, relevanceOrder, tenantConfig }) => {
  const [dataSource, setDataSource] = useState([]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource(previous => {
        const activeIndex = previous.findIndex(i => i.key === active.id);
        const overIndex = previous.findIndex(i => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });

      setDataSource(previous => {
        const newOrderedItems = previous.map((p, i) => {
          return { ...p, order: i + 1 };
        });
        return newOrderedItems;
      });
    }
  };

  useEffect(() => {
    const dataSourceMapping = relevanceOrder
      .map(([key, value], i) => ({
        key,
        name: value.label,
        order: tenantConfig[key] || i + 1,
      }))
      .sort((a, b) => a.order - b.order);

    setDataSource(dataSourceMapping);
  }, [relevanceOrder]);

  useEffect(() => {
    form.setFieldsValue({ relevanceOrderItem: dataSource });
  }, [dataSource]);

  return (
    <>
      <Title level={4} style={{ margin: '30px 0 -17px 0' }}>
        Ordem de relevância
      </Title>

      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map(i => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Form.Item
            hasFeedback={false}
            name="relevanceOrderItem"
            style={{ width: '90%' }}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </Form.Item>
        </SortableContext>
      </DndContext>
    </>
  );
};
export default RelevanceOrderSettings;
