import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const IntegrationStatusGraph = ({ data }) => {
  const getColor = category => {
    switch (category) {
      case 'Success':
        return '#52c41a';
      case 'Error':
        return '#f5222d';
      default:
        return '#cccccc';
    }
  };

  return (
    <PieChart width={300} height={300}>
      <Pie
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        outerRadius={80}
        innerRadius={60}
        fill="#8884d8"
        label
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default IntegrationStatusGraph;
