import React from 'react';
import { Col, Typography } from 'antd';
import FormFieldComponent from '../Form/Field';

const { Title } = Typography;

const GeneralTemplateParams = props => {
  const { element, template } = props;

  if (!template.paramsGroups || !template.params) return null;

  const groups = Object.values(template.paramsGroups);

  return (
    <React.Fragment>
      {groups.map(group => (
        <div className="params_group" key={group.name}>
          <Col offset="6">
            <Title level={4}>{group.label}</Title>
          </Col>

          {Object.values(template.params)
            .filter(param => param.groupName === group.name)
            .map(param => (
              <div
                className="template_params_param"
                key={`${template._id}:${param.name}`}
              >
                <FormFieldComponent
                  form={props.form}
                  name={`template.params.${param.name}`}
                  label={param.label}
                  help={param.helper}
                  settedValue={
                    element.template &&
                    element.template.params &&
                    element.template.params[param.name]
                  }
                  defaultValue={param.default}
                  required={param.required}
                  frontendType={param.inputType}
                  dataType={param.type || 'string'}
                  options={param.options}
                  allowMulti={param.allow_multi}
                />
              </div>
            ))}
        </div>
      ))}
    </React.Fragment>
  );
};

export default GeneralTemplateParams;
