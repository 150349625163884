const ElementRecommendationTabValidate = ({
    element,
    fieldsValidation,
    fieldsValues,
}) =>
    new Promise(resolve => {
        const status = {
            success: true,
            tab: 'recommendation',
            message: null,
        };

        if (!fieldsValues.engine || !fieldsValues.engine._id) {
            status.success = false;
            status.message = `Elementos do tipo vitrine precisam de uma recomendação configurada.`;
        }

        // template fields reports errors
        if (fieldsValidation && fieldsValidation['engine']) {
            status.success = false;
            status.message = `Algumas informações são inválidas. Verifique os campos.`;
        }

        // validation passed, handle fields
        element.engine = fieldsValues['engine'];
        // handle fielters

        return resolve(status);
    });

export default ElementRecommendationTabValidate;
