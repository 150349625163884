import React from 'react';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

const DEFAULT_STYLE = { border: 'none', background: 'none' };

const ERROR = 'error';
const PROCESSING = 'processing';
const SUCCESS = 'success';

const StatusColumn = ({ status }) => {
  switch (status) {
    case SUCCESS:
      return (
        <Tag
          icon={<CheckCircleOutlined />}
          color="success"
          style={DEFAULT_STYLE}
        />
      );

    case ERROR:
      return (
        <Tag
          icon={<CloseCircleOutlined />}
          color="error"
          style={DEFAULT_STYLE}
        />
      );

    case PROCESSING:
      return (
        <Tag
          icon={<SyncOutlined spin />}
          color="processing"
          style={DEFAULT_STYLE}
        />
      );

    default:
      return (
        <Tag
          icon={<ClockCircleOutlined />}
          color="default"
          style={DEFAULT_STYLE}
        />
      );
  }
};

export default StatusColumn;
