import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, message, Typography, Upload } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

import FormFieldComponent from '../Form/Field';

const { Title } = Typography;

const ECMS_API_BASE_URL = process.env.REACT_APP_ECMS_API_URL;
const IMG_ERROR_MSG =
  'Esta imagem foi removida do nosso banco de dados pois encontrava-se inativa por mais de 30 dias';

const BasicNotificationTemplateParams = props => {
  const { client_id } = useParams();
  const { element, template } = props;

  const [useImage, setUseImage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState(null);

  const checkImageUrl = url => {
    const img = new Image();
    img.src = url;

    img.onerror = () => {
      setImageErrorMessage(IMG_ERROR_MSG);
    };
  };

  useEffect(() => {
    const imageUrl = props.element?.template?.params?.image_url;

    if (imageUrl) {
      setUseImage(true);
      checkImageUrl(imageUrl);

      setFileList([
        {
          uid: crypto.randomUUID(),
          name: `basic-notification-${element._id}-image.jpg`,
          status: 'done',
          url: imageUrl,
          thumbUrl: imageUrl,
        },
      ]);
    }
  }, [props.element, props.template]);

  if (!template.paramsGroups || !template.params) return null;

  const groups = Object.values(template.paramsGroups);

  const isUrlValidImage = url => {
    var imageExtensions = /\.(jpg|jpeg|png)$/i;
    return imageExtensions.test(url);
  };

  const uploadImage = async formData => {
    const response = await fetch(
      `${ECMS_API_BASE_URL}/presets/image/${client_id}/${element._id}`,
      {
        method: 'POST',
        body: formData,
      },
    );
    const { url } = await response.json();
    return url;
  };

  const deleteImage = async imageParams => {
    const payload = JSON.stringify({ imageParams });
    await fetch(`${ECMS_API_BASE_URL}/presets/image/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    });
  };

  const customRequest = async options => {
    try {
      const { file } = options;
      const formData = new FormData();
      formData.append('image', file);
      const url = await uploadImage(formData);

      props.form.setFieldsValue({ 'template.params.image_url': url });
      setImageUrl(url);
      setFileList([
        {
          uid: crypto.randomUUID(),
          name: `basic-notification-${element._id}-image.jpg`,
          status: 'done',
          url: url,
          thumbUrl: url,
        },
      ]);
    } catch (e) {
      message.error(
        'Ocorreu um erro ao subir a imagem. Por favor, tente novamente',
      );
      console.error('uploadImage failed with', e);
    }
  };

  const deleteImgFromBucket = async ({ url }) => {
    try {
      const isImage = isUrlValidImage(url);

      if (isImage) {
        const parts = url.split('/').filter(part => part !== '');
        const imageParams = parts.slice(-4).join('/');
        await deleteImage(imageParams);
      }

      setFileList([]);
      props.form.setFieldsValue({ 'template.params.image_url': '' });
    } catch (e) {
      console.error('uploadImage failed with', e);
    }
  };

  const handleUrlChange = ({ target: { value } }) => {
    const isImage = isUrlValidImage(value);

    setImageUrl(value);

    if (isImage) {
      setFileList([
        {
          uid: crypto.randomUUID(),
          name: `basic-notification-${element._id}-image.jpg`,
          status: 'done',
          url: value,
          thumbUrl: value,
        },
      ]);
    } else {
      setFileList([]);
    }
  };

  return (
    <>
      {groups.map(group => (
        <div className="params_group" key={group.name}>
          <Col offset="6">
            <Title level={4}>{group.label}</Title>
          </Col>

          {Object.values(template.params)
            .filter(param => param.groupName === group.name)
            .map((param, i) => (
              <React.Fragment key={`${param}-${i}`}>
                {group.name === 'default' && i === 3 && (
                  <>
                    <FormFieldComponent
                      form={props.form}
                      name={`template.params.use_image`}
                      label={'Imagem'}
                      settedValue={
                        element?.template?.params?.image_url ? true : false
                      }
                      defaultValue={false}
                      frontendType={'select'}
                      options={[
                        [true, 'Sim'],
                        [false, 'Não aplicar'],
                      ]}
                      onChange={v => setUseImage(v)}
                    />

                    {useImage && (
                      <>
                        <Form.Item
                          label="URL da imagem"
                          name="template.params.image_url"
                          initialValue={
                            element?.template?.params?.image_url || ''
                          }
                          help={'Dimensão padrão: 600x600px'}
                        >
                          <Input onChange={handleUrlChange} value={imageUrl} />
                        </Form.Item>

                        <Form.Item
                          label="Imagem"
                          name="template.params.uploadedImage"
                        >
                          <Upload
                            customRequest={customRequest}
                            listType="picture-card"
                            fileList={fileList}
                            maxCount={1}
                            accept="image/jpeg, image/png"
                            onRemove={deleteImgFromBucket}
                          >
                            {fileList.length < 1 && '+ Upload'}
                          </Upload>

                          {imageErrorMessage && (
                            <>
                              <InfoCircleTwoTone twoToneColor="red" />{' '}
                              <span style={{ color: 'rgba(0,0,0,0.45)' }}>
                                {imageErrorMessage}
                              </span>
                            </>
                          )}
                        </Form.Item>
                      </>
                    )}
                  </>
                )}

                <div
                  className="template_params_param"
                  key={`${template._id}:${param.name}`}
                >
                  <FormFieldComponent
                    form={props.form}
                    name={`template.params.${param.name}`}
                    label={param.label}
                    help={param.helper}
                    settedValue={
                      element.template &&
                      element.template.params &&
                      element.template.params[param.name]
                    }
                    defaultValue={param.default}
                    required={param.required}
                    frontendType={param.inputType}
                    dataType={param.type || 'string'}
                    options={param.options}
                    allowMulti={param.allow_multi}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      ))}
    </>
  );
};

export default BasicNotificationTemplateParams;
