import React from 'react';
import { Layout, PageHeader } from 'antd';
import EmptyComponent from '../Empty/Empty';
const { Content } = Layout;

const RegionItemComponent = props => {
    const { region, actions, handleAddElement } = props;

    return (
        <Content className="page-edit-panel">
            <PageHeader
                backIcon={false}
                title={
                    <span>
                        <span>{region.label}</span>
                    </span>
                }
                extra={actions}
            />

            {region.elements ? (
                props.elements
            ) : (
                <EmptyComponent
                    buttonProps={{ icon: 'plus' }}
                    action={<span>Adicionar um elemento</span>}
                    onClick={handleAddElement}
                />
            )}
        </Content>
    );
};

export default RegionItemComponent;
