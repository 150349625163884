import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const AutocompleteSettings = ({ autocompleteConfig, form, tenantConfig }) => {
  if (!autocompleteConfig?.length) return <></>;

  return (
    <>
      <Title level={4} style={{ marginTop: '35px' }}>
        Autocompletar
      </Title>

      {autocompleteConfig.map(([key, value], i) => {
        return (
          <React.Fragment key={`fragment_${key}_${i}`}>
            <FormFieldComponent
              form={form}
              name={key}
              key={key}
              {...value}
              defaultValue={value.default}
              dataType={value.type}
              defaultFormatter={'none'}
              hasFeedback={false}
              settedValue={tenantConfig[key]}
              className="search-form__grid"
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default AutocompleteSettings;
