import createConsumer from '../../rest/createConsumer';

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const AuthRestApiConsumer = createConsumer(consumer => {
  const authenticate = ({ username, password }) =>
    new Promise(resolve => {
      consumer
        .putSource(`${AUTH_API_URL}/login`, {
          username,
          password,
        })
        .then(response => {
          if (response.status >= 400) {
            resolve(new Error(response.statusText));
          } else {
            // resolve({
            //   _id: '17b210fb-2ebb-45c3-9aa5-c02750c1c667',
            //   acl: {
            //     'user:17b210fb-2ebb-45c3-9aa5-c02750c1c667': 'owner',
            //     'org:47dce34b-4c1d-44d3-bdcf-1c96456b2db4': 'owner',
            //     'org:96c6afb2-ae1f-4020-aa93-ee9720c5b178': 'owner',
            //     'bn:global': 'account_manager',
            //   },
            //   fullname: 'Lorem Ipsum Junior',
            //   type: 'admin',
            //   username: 'example@email.com',
            //   created_at: '2022-02-01T04:31:30.289Z',
            //   updated_at: '2022-02-01T04:31:40.205Z',
            //   token: 'ccf02d85-7903-44da-a478-6ebb00a07bc6',
            // });
            resolve(response.body);
          }
        });
    });

  const report = ({ scope, username, action, timestamp }) =>
    new Promise(resolve => {
      consumer
        .putSource(`${AUTH_API_URL}/user/${username}/log`, {
          scope,
          username,
          action,
          timestamp,
        })
        .then(response => {
          if (response.statusCode >= 400) {
            resolve(new Error(response.message));
          } else {
            resolve(response);
          }
        });
    });

  return Object.freeze({
    authenticate,
    report,
  });
});

export default AuthRestApiConsumer;
