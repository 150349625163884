import React from 'react';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';

import './prismjs/prism.css';

const EditorComponent = props => {
    const { code, onCodeChange, style } = props;

    const editorStyle = {
        ...{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            border: '1px solid #CCC',
            minHeight: '600px',
            outline: 'none',
        },
        ...style,
    };

    return (
        <Editor
            value={code}
            onValueChange={onCodeChange}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            tabSize={4}
            style={editorStyle}
        />
    );
};

export default EditorComponent;
