import createConsumer from '../../rest/createConsumer';
import staticTemplates from './templates';

const delay = 100;

const TemplateApiStaticConsumer = createConsumer(consumer => {
    const getTemplates = clientId =>
        new Promise(resolve => {
            const templates = staticTemplates.map(template => {
                const returnTemplate = { ...template };

                delete returnTemplate.params;
                delete returnTemplate.paramsGroups;

                return returnTemplate;
            });

            setTimeout(() => resolve(templates), delay);
        });

    const getTemplate = (clientId, templateId) =>
        new Promise(resolve => {
            const template = staticTemplates.find(
                template => template._id === templateId
            );

            setTimeout(() => resolve(template), delay);
        });

    return Object.freeze({
        getTemplates,
        getTemplate,
    });
});

export default TemplateApiStaticConsumer;
