import React from 'react';
import { Row, Col } from 'antd';

import ElementTypeName from './Type/Name';
import SegmentationDescribe from '../Segmentation/Describe';
import ElementStatus from './Status/Status';
import ElementActionMove from './Actions/Move';

import './row.css';

const ElementRowComponent = ({ actions, element, handleElementMove }) => {
  return (
    <Row
      className={[
        'page-element-row',
        element.props.isHidden
          ? 'hidden'
          : element.props.isPreview
          ? 'preview'
          : '',
      ].join(' ')}
      type="flex"
      align="middle"
    >
      <Col span={1} className="order-col">
        <ElementActionMove
          key={`${element._id}:action:move:up`}
          element={element}
          direction="up"
          handle={() => handleElementMove(element, 'up')}
        />
        <ElementActionMove
          key={`${element._id}:action:move:down`}
          element={element}
          direction="down"
          handle={() => handleElementMove(element, 'down')}
        />
      </Col>

      <Col span={3} className="tags-col">
        <ElementTypeName type={element.type} />

        <ElementStatus
          isHidden={element.props.isHidden}
          isPreview={element.props.isPreview}
        />
      </Col>

      <Col span={15} className="title-col">
        <span>{element.props.label}</span>
        <SegmentationDescribe segmentation={element.segmentation} />
      </Col>

      <Col span={5} className="actions-col">
        {actions}
      </Col>
    </Row>
  );
};

export default ElementRowComponent;
