import React from 'react';
import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const MerchandisingRulesHeader = () => {
  return (
    <Row
      className="page-element-row"
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Col span={17}>
        <Title level={5}>Classificações</Title>
      </Col>

      <Col span={3}>
        <Title level={5}>Pontuação</Title>
      </Col>

      <Col span={2}>
        <Title level={5}>Status</Title>
      </Col>
    </Row>
  );
};

export default MerchandisingRulesHeader;
