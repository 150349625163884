import React from 'react';
import './styles/customToolTip.css';

const CustomToolTip = ({ payload }) => {

  if (payload.length > 0) {
    const { payload: payloadValues } = payload[0];
    return (
      <div className="tool-tip">
        <div className="tool-tip-container">
          <p className="tool-tip-text">{payloadValues.value}</p>
        </div>
        <div className="arrow" />
      </div>
    );
  }

  return null;
};

export default CustomToolTip;
