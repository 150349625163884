import React, { useState } from 'react';
import { Button, Image, Modal } from 'antd';
import SupportMenu from './SupportMenu';
import HelpIcon from '../../../images/support/float_button.png';

import './styles/supportFloatButton.css';

const SupportFloatButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="support_float-btn-container">
      <Button
        className="support_float-btn"
        onClick={() => setIsModalOpen(true)}
      >
        <Image
          className="support_float-btn-img"
          src={HelpIcon}
          height={50}
          width={50}
          preview={false}
        />
      </Button>

      {isModalOpen && (
        <Modal
          className="support_float-btn-modal"
          title={null}
          footer={null}
          open={true}
          onCancel={() => setIsModalOpen(false)}
        >
          <SupportMenu />
        </Modal>
      )}
    </div>
  );
};

export default SupportFloatButton;
