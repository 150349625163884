export default [
    {
        _id: 'uuid-1',
        context: {
            name: 'homepage',
            label: 'Homepage',
        },
        regions: [
            {
                _id: 'uuid-1',
                label: 'Após banner principal - individualização',
                placement: {
                    op: 'append',
                    selector: 'body > main',
                    replace: false,
                },
                props: {
                    isHidden: false,
                },
            },
            {
                _id: 'uuid-2',
                label: 'Ofertas destaque',
                placement: {
                    op: 'before',
                    selector: 'body > main',
                    replace: false,
                },
                props: {
                    isHidden: false,
                },
            },
        ],
        elements: [
            {
                _id: 'uuid-14411',
                region_id: 'uuid-1',
                type: 'showcase',
                props: {
                    label: 'Escolhidos pra você',
                    isHidden: false,
                    order: 1,
                },
                template: {
                    _id: 'uuid-12312',
                    params: {
                        title: 'Escolhidos pra você',
                    },
                },
                segmentation: [],
            },
            {
                _id: 'uuid-1',
                region_id: 'uuid-2',
                type: 'showcase',
                props: {
                    label: 'Melhores ofertas - mobile',
                    isHidden: false,
                    order: 1,
                },
                template: {
                    _id: 'uuid-12312',
                    params: {
                        title: 'Os mais vendidos da semana',
                    },
                },
                segmentation: [
                    {
                        _id: '1',
                        variable: 'device_type',
                        operator: 'equals',
                        expected: 'mobile',
                    },
                ],
            },
            {
                _id: 'uuid-2',
                region_id: 'uuid-2',
                type: 'cta',
                props: {
                    label: 'Teste 2',
                    isHidden: true,
                    order: 2,
                },
                template: {},
                segmentation: [],
            },
            {
                _id: 'uuid-3',
                region_id: 'uuid-2',
                type: 'subscribe',
                props: {
                    label: 'Teste 3',
                    isHidden: true,
                    order: 2,
                },
                template: {},
                segmentation: [],
            },
        ],
        props: {
            label: 'Todas as páginas',
            status: 'published',
            isHidden: false,
            isDefault: true,
            triggers: {
                pageType: ['homepage'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
    {
        _id: 'uuid-2',
        context: {
            name: 'homepage',
            label: 'Homepage',
        },
        regions: [],
        elements: [],
        props: {
            label: 'Dia das mães',
            status: 'draft',
            isHidden: true,
            isDefault: false,
            triggers: {
                pageType: ['homepage'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
    {
        _id: 'uuid-3',
        context: {
            name: 'homepage',
            label: 'Homepage',
        },
        regions: [],
        elements: [],
        props: {
            label: 'Black Friday',
            status: 'suspended',
            isHidden: true,
            isDefault: false,
            triggers: {
                pageType: ['homepage'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
    {
      _id: 'uuid-4',
      context: {
          name: 'homepage',
          label: 'Homepage',
      },
      regions: [
        {
          _id: 'uuid-111',
          label: 'ÁREA DE LAYOUT TESTE',
          placement: {
              op: 'append',
              selector: 'body > main',
              replace: false,
          },
          props: {
              isHidden: false,
          },
      },
      ],
      elements: [
        {
          _id: 'uuid-55555',
          region_id: 'uuid-111',
          type: 'showcase',
          props: {
              label: 'Elemento teste',
              isHidden: false,
              order: 1,
          },
          template: {
              _id: 'uuid-12312',
              params: {
                  title: 'Elemento teste',
                  event_label: 'rastreio'
              },
          },
          segmentation: [],
      },
      ],
      props: {
          label: 'SAMPLE PAGE',
          status: 'draft',
          isHidden: true,
          isDefault: false,
          triggers: {
              pageType: ['homepage'],
              url: {
                  match: null,
                  useRegex: false,
              },
              date: {
                  start: null,
                  end: null,
              },
          },
      },
    },
    {
        _id: 'uuid-5',
        context: {
            name: 'product',
            label: 'Produto',
        },
        regions: [],
        elements: [],
        props: {
            label: 'Todas as páginas',
            status: 'published',
            isHidden: false,
            isDefault: true,
            triggers: {
                pageType: ['product'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
    {
        _id: 'uuid-6',
        context: {
            name: 'product',
            label: 'Produto',
        },
        regions: [],
        elements: [],
        props: {
            label: 'Categoria Uso pessoal',
            status: 'published',
            isHidden: false,
            isDefault: false,
            triggers: {
                pageType: ['product'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
    {
        _id: 'uuid-7',
        context: {
            name: 'product',
            label: 'Produto',
        },
        regions: [],
        elements: [],
        props: {
            label: 'Departamento eletrônicos',
            status: 'published',
            isHidden: false,
            isDefault: false,
            triggers: {
                pageType: ['product'],
                url: {
                    match: null,
                    useRegex: false,
                },
                date: {
                    start: null,
                    end: null,
                },
            },
        },
    },
];
