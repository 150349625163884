export const filterByKeywords = (properties, keywords) => {
  return Object.entries(properties).filter(([key, _]) =>
    keywords.every(keyword => key.includes(keyword)),
  );
};

export const flattenObject = (nestedObj, parentKey = '') => {
  let flatObj = {};
  for (let key in nestedObj) {
    let newKey = parentKey ? parentKey + '_' + key : key;
    if (typeof nestedObj[key] === 'object') {
      Object.assign(flatObj, flattenObject(nestedObj[key], newKey));
    } else {
      flatObj[newKey] = nestedObj[key];
    }
  }
  return flatObj;
};

export const unflattenObject = flatObj => {
  let nestedObj = {};
  for (let key in flatObj) {
    let keys = key.split('_');
    let obj = nestedObj;
    for (let i = 0; i < keys.length - 1; i++) {
      let k = keys[i];
      obj[k] = obj[k] || {};
      obj = obj[k];
    }
    obj[keys[keys.length - 1]] = flatObj[key];
  }
  return nestedObj;
};
