import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style/radialGraphCardStyle.css';

const RadialGraphCard = ({
  graphTile,
  graphWidth,
  graphInfo,
  percentageValue,
  graphText,
  trailColor,
  pathColor,
  textColor,
  transitionDuration,
  strokeWidth,
  complex,
  positiveValue,
  positiveText,
  negativeValue,
  negativeText,
  textAlign,
  strokeLinecap,
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPercentage(percentageValue);
    }, 200);
  }, []);

  return (
    <div className="radial-graph-card-main">
      <div className="radial-card-simple-section" style={{ width: graphWidth }}>
        <h1
          className={
            `radial-card-title ${textAlign === 'left' ? 'text-align-left' : 'text-align-center'}`
        }
        >
          {graphTile}
        </h1>
        <div className="circular-graph-container">
          <CircularProgressbar
            value={percentage}
            text={graphText}
            strokeWidth={strokeWidth}
            styles={buildStyles({
              trailColor: trailColor,
              pathColor: pathColor,
              textColor: textColor,
              pathTransitionDuration: transitionDuration,
              strokeLinecap: strokeLinecap === 'rounded' ? 'round' : 'butt',
            })}
          />
        </div>
        <p className="radial-card-info">{graphInfo}</p>
      </div>
      {complex && (
        <div className="radial-card-complex-section">
          <div className="complex-values-section">
            <div className="complex-values-container">
              <div className="positive-icon" />
              <p className="positive-text-value">{positiveValue}</p>
            </div>
            <p className="complex-text-info">{positiveText}</p>
          </div>
          <div className="complex-values-section">
            <div className="complex-values-container">
              <div className="negative-icon" />
              <p className="negative-text-value">{negativeValue}</p>
            </div>
            <p className="complex-text-info">{negativeText}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RadialGraphCard;
