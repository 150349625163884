import React, { useEffect, useState } from 'react';
import { Drawer, List, Button, Avatar } from 'antd';
import LoadingComponent from '../../../../Common/Loading/Loading';
import ElementTypeIcon from '../../../../Common/Element/Type/Icon';

import ContentApiConsumer from '../../../../../core/content/api';
import PresetsApiStaticConsumer from '../../../../../core/presets/static/api';

import './styles/edit.css';

const PageElementAddComponent = props => {
  const { store } = props;

  const [visible, setVisible] = useState(false);
  const [region, setRegion] = useState(null);
  const [types, setTypes] = useState(null);
  const [presets, setPresets] = useState([]);

  const [listeners, setListeners] = useState([]);

  useEffect(() => {
    setListeners([
      store.emitter.on(store.emitter.ELEMENT_ADD_START, region => {
        setVisible(true);
        setRegion(region);
      }),
    ]);

    return () => listeners.map(listener => store.emitter.off(listener));
  }, [store]);

  useEffect(() => {
    const loadContent = async () => {
      const staticPresets = PresetsApiStaticConsumer.getPresets();
      setPresets(staticPresets);

      const types = await ContentApiConsumer.getElementsTypes();
      setTypes(types);
    };

    loadContent();
  }, []);

  const closeDrawer = () => {
    setVisible(false);
  };

  const getTypesGroups = () => {
    return types
      .reduce((list, item) => {
        if (list.indexOf(item.group) < 0) list.push(item.group);
        return list;
      }, [])
      .sort((a, b) => a.localeCompare(b));
  };

  const getTypeActions = type => {
    const actions = [];

    if (type.is_available)
      actions.push([
        <Button
          key={type.name}
          type="primary"
          onClick={() => addNewElement(type)}
        >
          <span>Selecionar</span>
        </Button>,
      ]);
    else
      actions.push([
        <Button key={type.name} type="default" disabled>
          <span>Em breve</span>
        </Button>,
      ]);

    return actions;
  };

  const addNewElement = async type => {
    const element = await ContentApiConsumer.makeElement({
      region_id: region._id,
      label: 'Novo elemento',
      type: type.name,
    });

    store.emitter.dispatch(store.emitter.ELEMENT_EDIT_START, element);
    closeDrawer();
  };

  if (!visible) return null;

  if (!types) return <LoadingComponent />;

  return (
    <Drawer
      title={`Adicionar elemento: ${region?.label}`}
      placement="right"
      closable={true}
      onClose={() => closeDrawer()}
      visible={visible}
      width={900}
      className="page-element-add-wrapper"
    >
      {getTypesGroups().map(group => (
        <List
          key={group}
          header={group}
          dataSource={types.filter(type => type.group === group)}
          renderItem={type => (
            <List.Item actions={getTypeActions(type)}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size="large"
                    icon={<ElementTypeIcon type={type.name} />}
                  />
                }
                title={type.label}
                description={type.description}
              />
            </List.Item>
          )}
        />
      ))}
    </Drawer>
  );
};

export default PageElementAddComponent;
