import React from 'react';
import ContentPageStore from '../../../../stores/Content';
import LoadingComponent from '../../../Common/Loading/Loading';
import EmptyComponent from '../../../Common/Empty/Empty';
import PageViewComponent from './View';

import AuthStore from '../../../../stores/Auth';

export default class PageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.store = new ContentPageStore({ persistOnSave: true });

    this.state = {
      page: null,
      id: props.id,
      client_id: props.client_id,
      store: this.store,
    };

    this.onDuplication = props.onDuplication;

    this.listeners = [];
  }

  componentDidMount() {
    this.bindEventListeners();
    this.initPage(this.state.id);
  }

  componentWillUnmount() {
    this.unbindEventListeners();
  }

  componentWillReceiveProps(next) {
    if (this.state.id !== next.id) {
      this.initPage(next.id);
    }
  }

  bindEventListeners() {
    this.listeners.push(
      this.store.emitter.on(this.store.emitter.PAGE_CHANGED, page => {
        // prevent page update if no changes
        this.setState({ page, id: page._id });
      }),
    );

    this.listeners.push(
      this.store.emitter.on(this.store.emitter.PAGE_DUPLICATION_START, page => {
        this.onDuplication(page);
      }),
    );
  }

  unbindEventListeners() {
    this.listeners.map(listener => this.store.emitter.off(listener));
  }

  initPage(id) {
    if (!id) return;

    const { scope } = AuthStore.getScope();

    this.setState({ page: null, loading: true });

    this.state.store.initPage(scope, id).then(page => {
      if (page instanceof Error) {
        this.setState({ id, page: null, loading: false });
      } else {
        this.setState({ id, page, loading: false });
      }
    });
  }

  render() {
    if (this.state.loading) return <LoadingComponent />;

    if (!this.state.page || this.state.page.deleted_at)
      return (
        <div style={{ marginTop: 50 }}>
          <EmptyComponent description="A página que você está tentando acessar não existe ou foi excluída." />
        </div>
      );

    return <PageViewComponent {...this.state} />;
  }
}
