import React from 'react';
import { Row, Col, Typography, Badge, Tooltip } from 'antd';
import LoadingComponent from '../../../../../../../Common/Loading/Loading';
import SegmentationBulletsListComponent from './Bullets/List';
import * as segmentationApi from '../../../../../../../../core/segmentation/api';

import './styles/bullets.css';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default class ElementSegmentationTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            element: props.element,
            groups: null,
            vars: null,
            variableEditing: false,
        };
    }

    componentDidMount() {
        this.loadGroupsList();
        this.loadVarsList();
    }

    loadGroupsList() {
        segmentationApi.getVarsGroups().then((groups) => {
            this.setState({
                groups: groups.sort((a, b) => a.localeCompare(b)),
            });
        });
    }

    loadVarsList() {
        segmentationApi.getVars().then((vars) => {
            this.setState({ vars });
        });
    }

    getElementVarDefinitions(variable) {
        // return this.state.element.segmentation;

        const { element } = this.state;

        if (!element.segmentation) return [];

        return element.segmentation.filter(
            (segmentationVar) => segmentationVar.variable === variable.name
        );
    }

    getGroupVars(group) {
        return this.state.vars
            .filter((variable) => variable.group === group)
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getGroupVarsDefinitionsCount(group) {
        const groupVars = this.getGroupVars(group).map(
            (variable) => variable.name
        );

        const groupDefinitions = this.state.element.segmentation.filter(
            (definition) => groupVars.indexOf(definition.variable) > -1
        );

        return groupDefinitions.length;
    }

    getGroupSubgroups(name) {
        const list = this.state.vars
            .filter((item) => item.group === name)
            .reduce((list, item) => {
                if (list.indexOf(item.subgroup) < 0) list.push(item.subgroup);
                return list;
            }, [])
            .sort((a, b) => a.localeCompare(b));

        return list;
    }

    getSubgroupVars(subgroup) {
        return this.state.vars
            .filter((variable) => variable.subgroup === subgroup)
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getVariableRowClasses(variable) {
        const classes = [];

        const definitions = this.getElementVarDefinitions(variable);

        if (definitions.length) classes.push('has-definitions');

        return classes.join(' ');
    }

    addVariableDefinition = (definition) => {
        const { element } = this.state;

        if (!definition) return;

        element.segmentation = element.segmentation.filter(
            (item) => item._id !== definition._id
        );

        element.segmentation.push(definition);

        this.setState({ element });
    };

    removeVariableDefinition = (definition) => {
        const { element } = this.state;

        element.segmentation = element.segmentation.filter(
            (item) => item._id !== definition._id
        );

        this.setState({ element });
    };

    render() {
        if (!this.state.groups || !this.state.vars) return <LoadingComponent />;

        return (
            <div className="variables-groups">
                {this.state.groups.map((group) => (
                    <div key={group}>
                        <Row type="flex" justify="start" align="middle">
                            <Col span={21} offset={1}>
                                <div className="varible-group-label">
                                    <Badge
                                        count={this.getGroupVarsDefinitionsCount(
                                            group
                                        )}
                                    >
                                        <Title level={3}>{group}</Title>
                                    </Badge>
                                </div>
                            </Col>
                        </Row>

                        {this.getGroupSubgroups(group).map((subgroup) => (
                            <div
                                key={subgroup}
                                className="variable-subgroup-list"
                            >
                                <Row type="flex" justify="start" align="middle">
                                    <Col span={21} offset={1}>
                                        <Title
                                            level={4}
                                            className="variable-subgroup-label"
                                        >
                                            {subgroup}
                                        </Title>
                                    </Col>
                                </Row>

                                {this.getSubgroupVars(subgroup).map(
                                    (variable) => (
                                        <Row
                                            key={variable.name}
                                            type="flex"
                                            justify="start"
                                            align="middle"
                                            className={`variable-row ${this.getVariableRowClasses(
                                                variable
                                            )}`}
                                        >
                                            <Col span={1}>
                                                {this.getElementVarDefinitions(
                                                    variable
                                                ).length > 0 && <Badge dot />}
                                            </Col>
                                            <Col span={7}>
                                                <span className="variable-label">
                                                    {variable.label}
                                                </span>
                                            </Col>
                                            <Col span={14}>
                                                <div className="variable-bullets-wrapper">
                                                    <SegmentationBulletsListComponent
                                                        variable={variable}
                                                        definitions={
                                                            this.state.element
                                                                .segmentation
                                                        }
                                                        onChange={
                                                            this
                                                                .addVariableDefinition
                                                        }
                                                        onRemove={
                                                            this
                                                                .removeVariableDefinition
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                span={2}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {variable.help && (
                                                    <Tooltip
                                                        title={variable.help}
                                                        placement="left"
                                                    >
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                )}
                                            </Col>
                                        </Row>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}
