import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import './styles/describe.css';

const FINISHED = 'finished';
const PUBLISHED = 'published';
const SCHEDULED = 'scheduled';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
const DATETIME_TIMESTAMP = 'datetime_timestamp';
const GREATER_THAN_EQUALS_TO = 'greater_than_equals_to';
const LESS_THAN_EQUALS_TO = 'less_than_equals_to';

const SegmentationDescribe = props => {
  const [summary, setSummary] = useState([]);
  const segmentation = props.segmentation || [];

  const items = segmentation.sort((a, b) =>
    a.variable.localeCompare(b.variable),
  );

  const datetimes = segmentation.filter(
    ({ variable }) => variable === DATETIME_TIMESTAMP,
  );

  const getOperatorLabel = operator => operator;

  const getIntervalTag = (init, end, status) => {
    const initTime = moment(init).format(DATE_FORMAT);
    const endTime = moment(end).format(DATE_FORMAT);

    switch (status) {
      case SCHEDULED:
        return `(Agendado) inicia em ${initTime}`;

      case PUBLISHED:
        return `(Publicado) iniciou em ${initTime} e encerrará em ${endTime}`;

      case FINISHED:
        return `(Encerrado) encerrou em ${endTime}`;

      default:
        return '';
    }
  };

  const getIntervalSegmentationStatus = (init, end) => {
    const now = moment();

    if (now < init) return SCHEDULED;
    if (now > end) return FINISHED;
    return PUBLISHED;
  };

  const getDatetimeTag = item => {
    const { expected, operator, variable } = item;
    const timeDiff = moment() - Number(expected);
    const status = getDatetimeSegmentationStatus(operator, timeDiff);
    const segmentationTime = moment(Number(expected)).format(DATE_FORMAT);

    switch (status) {
      case SCHEDULED:
        return `(Agendado) inicia em ${segmentationTime}`;

      case PUBLISHED:
        return `(Publicado) ${
          operator === GREATER_THAN_EQUALS_TO ? 'iniciou' : 'encerra'
        } em ${segmentationTime}`;

      case FINISHED:
        return `(Encerrado) encerrou em ${segmentationTime}`;

      default:
        return `${variable} ${operator} ${moment(Number(expected)).format(
          DATE_FORMAT,
        )}`;
    }
  };

  const getDatetimeSegmentationStatus = (operator, timeDiff) => {
    if (operator === LESS_THAN_EQUALS_TO) {
      if (timeDiff > 0) {
        return FINISHED;
      }

      if (timeDiff < 0) {
        return PUBLISHED;
      }
    }

    if (operator === GREATER_THAN_EQUALS_TO) {
      if (timeDiff > 0) {
        return PUBLISHED;
      }

      if (timeDiff < 0) {
        return SCHEDULED;
      }
    }

    return '';
  };

  const getDatetimeTagColor = item => {
    const { operator, expected } = item;
    const timeDiff = moment() - Number(expected);
    const status = getDatetimeSegmentationStatus(operator, timeDiff);

    switch (item.status || status) {
      case SCHEDULED:
        return 'gray';
      case PUBLISHED:
        return 'green';
      case FINISHED:
        return 'orange';
      default:
        return 'blue';
    }
  };

  const getExpectedFormated = item => {
    const { variable, operator, expected, start, end, status } = item;

    if (variable === DATETIME_TIMESTAMP) {
      if (operator === 'interval') return getIntervalTag(start, end, status);
      return getDatetimeTag(item);
    }

    return expected;
  };

  const getSummary = () => {
    const segmentations = items.filter(
      ({ variable }) => variable !== DATETIME_TIMESTAMP,
    );

    if (datetimes.length > 1) {
      const sortedDatetimes = _.sortBy(datetimes, ['operator', 'expected']);
      const firstDatetime = sortedDatetimes[0];
      const lastDatetime = sortedDatetimes[sortedDatetimes.length - 1];

      const start = Number(firstDatetime.expected);
      const end = Number(lastDatetime.expected);

      if (
        start < end &&
        firstDatetime.operator === GREATER_THAN_EQUALS_TO &&
        lastDatetime.operator === LESS_THAN_EQUALS_TO
      ) {
        const intervalStatus = getIntervalSegmentationStatus(start, end);

        const intervalSegmentation = {
          operator: 'interval',
          variable: DATETIME_TIMESTAMP,
          start,
          end,
          status: intervalStatus,
        };

        return setSummary([intervalSegmentation, ...segmentations]);
      }
    }

    const newSummary =
      datetimes.length > 0 ? [...datetimes, ...segmentations] : items;

    return setSummary(newSummary);
  };

  useEffect(() => {
    getSummary();
  }, [segmentation]);

  return (
    <div className="segmentation-container">
      {summary.map((item, index, arr) => (
        <div key={`${item.variable}-${index}`}>
          {item.variable === DATETIME_TIMESTAMP ? (
            <Tag
              key={`${item.variable}-${index}-tag`}
              color={getDatetimeTagColor(item)}
            >
              {getExpectedFormated(item)}
            </Tag>
          ) : (
            <Tag
              className="segmentation-tag"
              key={`${item.variable}-${index}-tag`}
              color="blue"
            >
              {item.variable} <b>{getOperatorLabel(item.operator)}</b>{' '}
              {getExpectedFormated(item)}
            </Tag>
          )}

          {index < arr.length - 1 ? (
            <Tag
              key={`or-${index}`}
              color="transparent"
              style={{
                color: 'gray',
              }}
            >
              E
            </Tag>
          ) : (
            ``
          )}
        </div>
      ))}
    </div>
  );
};

export default SegmentationDescribe;
