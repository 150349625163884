import React, { useEffect, useState } from 'react';
import { Form, Button, Tooltip, Popconfirm, Tabs } from 'antd';

import ElementEditCommonTabs from './Types/Common';
import ElementEditShowcaseTabs from './Types/Showcase';
import ElementEditCtaTabs from './Types/Cta';
import ElementEditSubscribeTabs from './Types/Subscribe';
import ElementEditSnippetTabs from './Types/Snippet';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import unflatten from '../../../../../../utils/unflatten';

const { TabPane } = Tabs;

const ElementEditFormComponent = props => {
  const [form] = Form.useForm();
  const [tabs, setTabs] = useState([]);
  const { element, defaultTab = 'layout', page } = props;

  useEffect(() => {
    initTabs();
    resetFormErrors();
  }, []);

  const initTabs = () => {
    let newTabs = [...ElementEditCommonTabs];

    switch (element.type) {
      case 'showcase':
        newTabs = [...newTabs, ...ElementEditShowcaseTabs];
        break;

      case 'cta':
        newTabs = [...newTabs, ...ElementEditCtaTabs];
        break;

      case 'subscribe':
        newTabs = [...newTabs, ...ElementEditSubscribeTabs];
        break;

      case 'snippet':
        newTabs = [...newTabs, ...ElementEditSnippetTabs];
        break;

      default:
        break;
    }

    return setTabs(newTabs);
  };

  const resetFormErrors = () => {
    if (tabs.length > 0) {
      const newTabs = tabs.map(tab => {
        tab.hasErrors = false;
        tab.message = null;

        return tab;
      });

      setTabs(newTabs);
    }
  };

  const validateTabs = formResult => {
    const { errorFields = [], values } = formResult;

    const fieldsValues = unflatten(values);
    const fieldsValidation = unflatten(
      errorFields.reduce((obj, err) => {
        obj[err.name[0]] = err.errors[0];
        return obj;
      }, {}),
    );

    const validationPromises = tabs.reduce((validators, tab) => {
      if (tab.hasOwnProperty('validate'))
        validators.push(
          tab.validate({
            element,
            fieldsValidation,
            fieldsValues,
          }),
        );

      return validators;
    }, []);

    return Promise.all(validationPromises)
      .then(results => {
        let passed = true;
        let tabsWithErrors = {};

        results.forEach(result => {
          if (!result.success) {
            passed = false;
            tabsWithErrors[result.tab] = result;
          }
        });

        if (!passed) {
          const newTabs = tabs.map(tab => {
            tab.hasErrors = false;
            tab.message = null;

            if (tabsWithErrors[tab.key]) {
              tab.hasErrors = true;
              tab.message = tabsWithErrors[tab.key].message;
            }

            return tab;
          });

          setTabs(newTabs);
        } else {
          if (values.region_id) {
            element.region_id = values.region_id;
          }
          props.confirm(element);
        }
      })
      .catch(err => console.log(`validation failed`, err));
  };

  const handleSubmitFailed = result => {
    resetFormErrors();
    validateTabs(result);
  };

  const handleSubmit = result => {
    resetFormErrors();
    validateTabs({ values: result });
  };

  const handleCancel = () => {
    props.cancel(element);
  };

  const getTabsActions = () => {
    return [
      <Popconfirm
        title="Todas as alterações pendentes serão perdidas."
        okText="Confirmar"
        cancelText="Continuar editando"
        onConfirm={handleCancel}
        placement="bottom"
        key="page:element:edit:cancel"
      >
        <Button type="default">Cancelar</Button>
      </Popconfirm>,
      <Button htmlType="submit" type="primary" key="page:element:edit:save">
        Salvar
      </Button>,
    ];
  };

  return (
    <Form
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      layout="horizontal"
      labelCol={{ span: 6 }}
      requiredMark={true}
      size="large"
      form={form}
    >
      <Tabs
        defaultActiveKey={defaultTab}
        tabBarExtraContent={{ right: getTabsActions() }}
        animated={false}
        size="default"
        tabBarGutter="10"
        style={{ paddingLeft: '20px' }}
      >
        {tabs.map(tab => (
          <TabPane
            tab={
              <span>
                {tab.hasErrors ? (
                  <Tooltip
                    title={tab.message}
                    className="has-errors"
                    visible={true}
                  >
                    <ExclamationCircleOutlined /> {tab.title}
                  </Tooltip>
                ) : (
                  tab.title
                )}
              </span>
            }
            key={tab.key}
            forceRender={true}
          >
            <tab.component
              page={page}
              element={element}
              form={form}
              {...props}
            />
          </TabPane>
        ))}
      </Tabs>
    </Form>
  );
};

export default ElementEditFormComponent;
