import { loadSource } from './load';
import { putSource } from './put';
import { deleteSource } from './delete';
import { patchSource } from './patch';

const createConsumer = function (apiFactory, options) {
  // options pode trazer um header com o token de autenticação

  const consumer = Object.freeze({
    loadSource,
    putSource,
    deleteSource,
    patchSource,
  });

  return apiFactory(consumer);
};

export default createConsumer;
