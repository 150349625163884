import ElementRecommendationTabComponent from '../Tabs/Recommendation/Tab';
import ElementRecommendationTabValidate from '../Tabs/Recommendation/Validate';

export default [
    {
        key: `recommendation`,
        title: `Produtos`,
        component: ElementRecommendationTabComponent,
        validate: ElementRecommendationTabValidate,
    },
];
