import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const VoiceSettings = ({ voiceConfig, form, tenantConfig }) => {
  if (!voiceConfig?.length) return <></>;

  return (
    <>
      <Title level={4}>Pesquisa por voz</Title>

      {voiceConfig.map(([key, value]) => (
        <FormFieldComponent
          form={form}
          name={key}
          key={key}
          {...value}
          defaultValue={value.default}
          hasFeedback={false}
          help={value.description}
          dataType={value.type}
          settedValue={tenantConfig[key]}
          className="search-form__grid"
        />
      ))}
    </>
  );
};

export default VoiceSettings;
