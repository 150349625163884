import createConsumer from '../../rest/createConsumer';
import { PRESETS, PRESETS_APPLIED_TEXT } from './presets';

const PresetsApiStaticConsumer = createConsumer(() => {
  const getPresets = () => {
    return PRESETS;
  };

  const getPresetsAppliedText = () => {
    return PRESETS_APPLIED_TEXT;
  };

  return Object.freeze({
    getPresets,
    getPresetsAppliedText,
  });
});

export default PresetsApiStaticConsumer;
