import { clearResponseCache } from './cache';

export const deleteSource = (url, object) =>
    new Promise(resolve => {
        fetch(url, { method: 'DELETE' })
            .then(response => response.json())
            .then(response => {
                clearResponseCache(url);
                resolve(response);
            })
            .catch(err => {
                console.log(err);
                resolve(new Error(err));
            });
    });
