import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Divider } from 'antd';
import LoadingComponent from '../../Common/Loading/Loading';
import { useTenant } from '../../../context/useTenant';

const TenantSelectComponent = ({ filteredTenants, tenantId, onClick }) => {
  const { handleTenantData } = useTenant();
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTenantData = async () => {
      const data = await handleTenantData(tenantId, false);
      setTenantData(data);
      setLoading(false);
    };
    getTenantData();
  }, []);

  return (
    <Row
      justify="center"
      align="middle"
      key={tenantId}
      style={{
        display: `${
          (tenantData?.profile?.name || '')
            .toLowerCase()
            .includes(filteredTenants.toLowerCase())
            ? 'flex'
            : 'none'
        }`,
      }}
    >
      {loading ? (
        <Col span={4}>
          <LoadingComponent />
        </Col>
      ) : (
        <>
          <Col span={10} key="tenant-info">
            <strong>{tenantData?.profile?.name || ''}</strong>
            <br />
            <small>{tenantId}</small>
          </Col>
          <Col span={4} offset={10} key="tenant-actions">
            <Button onClick={() => onClick(tenantId)} type="primary">
              Acessar
            </Button>
          </Col>
          <Divider />
        </>
      )}
    </Row>
  );
};

export default TenantSelectComponent;
