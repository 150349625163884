import React, { useState } from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const BarsGraphCard = ({
  graphWidth,
  graphHeight,
  graphData,
  XAxisDataKey,
  BarDataKey,
  barSize,
  cartesianDashArray,
  cartesianOpacity,
  barColor,
  animationDuration,
  cursor,
  CustomToolTip,
}) => {
  const [tooltip, setTooltip] = useState({ show: false, position: { x: 0, y: 0 } });

  const onMouseOverBar = (e) => {
    setTooltip({ show: true, position: { x: e.x, y: e.y - 40 } });
  };

  return (
    <ResponsiveContainer width={graphWidth} height={graphHeight}>
      <BarChart
        data={graphData}
        barSize={barSize}
      >
        <XAxis dataKey={XAxisDataKey} />
        <YAxis />
        <Tooltip
          cursor={cursor ? true : false}
          content={CustomToolTip ? <CustomToolTip /> : null}
          offset={-25}
          position={{y: tooltip.position.y}}
        />
        <CartesianGrid
          strokeDasharray={cartesianDashArray}
          strokeOpacity={cartesianOpacity}
        />
        <Bar
          dataKey={BarDataKey}
          fill={barColor}
          animationDuration={animationDuration}
          onMouseOver={onMouseOverBar}
        />
      </BarChart>
    </ResponsiveContainer>
  )
};

export default BarsGraphCard;
