import React, { useDebugValue, useEffect, useState } from 'react';
import { Button, Divider, Form, Popconfirm } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import InputSettings from '../../../../../Common/Search/SearchConfig/InputSettings';
import VoiceSettings from '../../../../../Common/Search/SearchConfig/VoiceSettings';
import AutocompleteSettings from '../../../../../Common/Search/SearchConfig/AutocompleteSettings';

const AUTOCOMPLETE = 'autocomplete';
const FIELDS = 'fields';
const FRONTEND = 'frontend';
const INPUT = 'input';
const VOICE = 'voice';

const SearchInputTab = ({
  formItems,
  handleSubmit,
  tenantConfig,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [autocompleteConfig, setAutocompleteConfig] = useState([]);
  const [inputConfigs, setInputConfigs] = useState([]);
  const [voiceConfig, setVoiceConfig] = useState([]);

  const { properties } = formItems;

  useEffect(() => {
    const autocompleteItems = filterByKeywords(properties, [
      FRONTEND,
      FIELDS,
      AUTOCOMPLETE,
    ]);
    const inputItems = filterByKeywords(properties, [FRONTEND, FIELDS, INPUT]);
    const voiceItems = filterByKeywords(properties, [FRONTEND, FIELDS, VOICE]);

    setAutocompleteConfig(autocompleteItems);
    setInputConfigs(inputItems);
    setVoiceConfig(voiceItems);
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <VoiceSettings
          form={form}
          tenantConfig={tenantConfig}
          voiceConfig={voiceConfig}
        />

        <Divider />

        <AutocompleteSettings
          form={form}
          tenantConfig={tenantConfig}
          autocompleteConfig={autocompleteConfig}
        />

        <Divider />

        <InputSettings
          form={form}
          tenantConfig={tenantConfig}
          inputConfigs={inputConfigs}
        />
      </div>
    </Form>
  );
};

export default SearchInputTab;
