import React from 'react';
import { createContext, useContext, useState } from 'react';
import createConsumer from '../core/rest/createConsumer';
import {
  isAuthenticated,
  destroyAuthenticated,
  setAuthenticated,
  isAuthorized,
  refreshAuth,
  authenticate,
  getAuthenticated,
} from '../domain/Auth';

const TENANT_API_URL = process.env.REACT_APP_TENANT_API_URL;

const UserContext = createContext(null);
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [operationsList, setOperationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('login');
  const [authSuccess, setAuthSuccess] = useState(undefined);

  const login = async (username, password) =>
    new Promise(async resolve => {
      if (isAuthenticated()) await destroyAuthenticated();

      setLoading(true);
      const authResponse = await authenticate({ username, password });

      if (authResponse.success) {
        await setAuthenticated(authResponse);
        setUser(authResponse);
        const { acl } = getAuthenticated();

        let allowedOrganizations;

        const isGlobalAdmin = Object.keys(acl).find(
          aclKey => aclKey.indexOf(`bn:global`) === 0,
        );

        if (isGlobalAdmin) {
          const tenantsResponse = await createConsumer(async consumer =>
            consumer.loadSource(`${TENANT_API_URL}/tenants/actives?name=false`),
          );

          if (tenantsResponse) allowedOrganizations = tenantsResponse;
        } else
          allowedOrganizations = Object.keys(acl)
            .filter(aclKey => aclKey.indexOf(`org:`) === 0)
            .map(aclKey => aclKey.split(`:`).pop());

        if (allowedOrganizations.length === 1) {
          setAuthSuccess(true);
          resolve(allowedOrganizations.pop());
        } else {
          setOperationsList(allowedOrganizations);
          setAuthSuccess(true);
          setStep('select');
        }
        resolve(null);
      } else {
        setAuthSuccess(false);
        setStep('login');
        resolve(new Error(authResponse.message));
      }

      setLoading(false);
    });

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        authSuccess,
        setAuthSuccess,
        isAuthenticated,
        isAuthorized,
        login,
        destroyAuthenticated,
        refreshAuth,
        operationsList,
        loading,
        step,
        setStep,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
