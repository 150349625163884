import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Form, Select } from 'antd';

const SelectItem = ({ props }) => {
  const {
    allowMulti,
    allowMultiTags,
    className,
    defaultValue,
    fetching,
    hasFeedback,
    help,
    label,
    name,
    onChange,
    onSearch,
    options,
    rules,
  } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
    >
      <Select
        mode={allowMulti ? 'multiple' : allowMultiTags ? 'tags' : 'default'}
        allowClear={allowMulti}
        defaultValue={defaultValue || []}
        showSearch
        notFoundContent={
          fetching ? (
            <Empty
              imageStyle={{
                height: 40,
              }}
              image={<LoadingOutlined style={{ fontSize: '40px' }} />}
              description="Carregando opções..."
            />
          ) : null
        }
        filterOption={false}
        onChange={onChange ? onChange : () => {}}
        onSearch={onSearch() || (() => {})}
        options={options}
        loading={fetching}
      />
    </Form.Item>
  );
};

export default SelectItem;
