import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const RegionActionDelete = (props) => {
    const { region, handle } = props;

    return region.elements.length ? (
        <Tooltip title="Áreas de layout com elementos associados não podem ser excluídas.">
            <Button
                type="danger"
                shape="round"
                icon={<DeleteOutlined />}
                size="small"
                disabled={true}
            >
                <span>Excluir</span>
            </Button>
        </Tooltip>
    ) : (
        <Popconfirm
            title="Confirma a exclusão dessa região?"
            okText="Sim"
            cancelText="Não"
            onConfirm={handle}
        >
            <Button
                type="danger"
                shape="round"
                icon={<DeleteOutlined />}
                size="small"
            >
                <span>Excluir</span>
            </Button>
        </Popconfirm>
    );
};

export default RegionActionDelete;
