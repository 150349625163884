import React from 'react';

import StatusColumn from './CustomColumn/StatusColumn';
import VirtualTable from './VirtualTable';
import TableFilter from './Filter';
import VtexIntegrationStatsHeader from '../StatsHeader';
import NameColumn from './CustomColumn/NameColumn';

const columns = [
  {
    dataIndex: 'integrationStatus',
    render: status => <StatusColumn status={status} />,
    width: 50,
  },
  {
    title: 'Nome do produto',
    dataIndex: 'productInfo',
    render: productInfo => <NameColumn productInfo={productInfo} />,
    width: 600,
  },
  {
    title: 'RefID',
    dataIndex: 'refid',
    width: 110,
  },
  {
    title: 'ID',
    dataIndex: 'product_id',
    width: 110,
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    width: 110,
  },
];

const CatalogTable = props => (
  <>
    <VtexIntegrationStatsHeader {...props} />
    <TableFilter {...props} />
    <VirtualTable
      {...props}
      columns={columns}
      scroll={{
        y: 300,
        x: '100vw',
      }}
    />
  </>
);

export default CatalogTable;
