import React, { useEffect, useState } from 'react';
import { Form, Button, Select, List, Space } from 'antd';

import * as recommendationApi from '../../../../../../../../core/recommendation/api';
import EmptyComponent from '../../../../../../../Common/Empty/Empty';

const RecommendationEngineSelectComponent = ({
  onChange,
  recommendationId: recId,
  store,
}) => {
  const [recommendationId, setRecommendationId] = useState(recId);
  const [recommendationsList, setRecommendationsList] = useState([]);
  const [recommendationsGroups, setRecommendationsGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    const loadRecommendations = async () => {
      const recommendations = await recommendationApi.getRecommendations();
      const contextRecommendations = recommendations.filter(
        r =>
          r.contexts.includes('all') ||
          r.contexts.includes(store?.page?.context?.name),
      );

      const groups = contextRecommendations
        .reduce((list, recommendation) => {
          if (list.indexOf(recommendation.group) < 0)
            list.push(recommendation.group);
          return list;
        }, [])
        .sort((a, b) => a.localeCompare(b));

      const optGroups = [];

      groups.forEach(g => optGroups.push({ label: g, options: [] }));

      contextRecommendations.map(({ _id, group, label }) => {
        optGroups.map(g => {
          if (g.label === group) {
            g.options.push({ label, value: _id });
          }
        });
      });

      setGroupOptions(optGroups);
      setRecommendationsList(contextRecommendations);
      setRecommendationsGroups(groups);
    };

    loadRecommendations();
  }, []);

  const handleSelect = id => {
    const recommendation = recommendationsList.find(({ _id }) => _id === id);

    onChange(recommendation);
    setRecommendationId(id);
  };

  const getRecommendationsByGroup = group =>
    recommendationsList
      .filter(recommendation => recommendation.group === group)
      .sort((a, b) => a.label.localeCompare(b.label));

  if (!recommendationsList.length || !recommendationsGroups.length) {
    return (
      <EmptyComponent description="Não existem recomendações disponíveis." />
    );
  }

  return (
    <>
      {recommendationId ? (
        <Form.Item
          label="Tipo da recomendação"
          name="engine._id"
          initialValue={recommendationId}
        >
          <Select onChange={v => handleSelect(v)} options={groupOptions} />
        </Form.Item>
      ) : (
        <Space
          direction="vertical"
          style={{
            display: 'flex',
          }}
        >
          {recommendationsGroups.map(group => (
            <List
              key={group}
              header={group}
              dataSource={getRecommendationsByGroup(group)}
              size="large"
              renderItem={recommendation => (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      key="recommendation:engine:select"
                      onClick={() => handleSelect(recommendation._id)}
                    >
                      <span>Selecionar</span>
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={recommendation.label}
                    description={recommendation.description}
                  />
                </List.Item>
              )}
            />
          ))}
        </Space>
      )}
    </>
  );
};

export default RecommendationEngineSelectComponent;
