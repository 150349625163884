import React, { useEffect, useState } from 'react';
import { Spin, Form, Typography, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import RadialGraphCard from './RadialGraphCard';
import BarsGraphCard from './BarsGraphCard';
import {
  getSummarizeInfo,
  getSummarize7days,
} from '../../../../domain/Catalog';
import CustomToolTip from './CustomToolTip';
import './styles/catalogHealthPage.css';

const { Title, Paragraph } = Typography;

const CatalogHealthPage = () => {
  const { client_id } = useParams();
  const [summarizedInfo, setsummarizedInfo] = useState({});
  const [log7Days, setLog7Days] = useState({});
  const [loading, setLoading] = useState(true);
  const [skuAvailableInfo, setSkuAvailableInfo] = useState({});
  const [skuStatusInfo, setSkuStatusInfo] = useState({});

  useEffect(() => {
    const getSummarize = async () => {
      const [result, log7DaysResult] = await Promise.all([
        getSummarizeInfo(client_id),
        getSummarize7days(client_id),
      ]);

      setLoading(false);

      if (result.data) {
        setsummarizedInfo(result.data.summary);
      }

      if (log7DaysResult.data) {
        setLog7Days(log7DaysResult.data);
      }
    };

    getSummarize();
  }, []);

  useEffect(() => {
    const getSkuAvailabilityInfo = () => {
      if (!Object.entries(summarizedInfo).length) return;

      const {
        count_availability_status,
        count_summarized_skus,
        count_unavailable_with_stock,
      } = summarizedInfo;

      const total = count_summarized_skus[0]?.count;

      const positiveInfo = count_availability_status.find(
        ({ _id }) => _id === true,
      );

      let totalAvailable;

      if (count_unavailable_with_stock[0]) {
        totalAvailable =
          positiveInfo?.total + count_unavailable_with_stock[0]?.count;
      } else {
        totalAvailable = positiveInfo?.total;
      }

      const totalUnavailable = total - totalAvailable;

      const availablePercentage = ((totalAvailable / total) * 100).toFixed();
      const unavailablePercentage = (
        (totalUnavailable / total) *
        100
      ).toFixed();

      if (!total || !positiveInfo || !totalAvailable || !availablePercentage) {
        return false;
      }

      setSkuAvailableInfo({
        availableText: `${totalAvailable} (${availablePercentage}%)`,
        unavailableText: `${totalUnavailable} (${unavailablePercentage}%)`,
        availablePercentage,
        totalAvailable,
        unavailablePercentage,
        total,
      });
    };

    getSkuAvailabilityInfo();
  }, [summarizedInfo]);

  useEffect(() => {
    if (!Object.entries(skuAvailableInfo).length) return;

    const getSkuStatusInfo = () => {
      const { count_availability_status } = summarizedInfo;
      const { total: availableTotal } = skuAvailableInfo;

      const activeInfo = count_availability_status.find(
        ({ _id }) => _id === true,
      );
      const inactiveInfo = count_availability_status.find(
        ({ _id }) => _id === false,
      );

      let activeValue = 0;
      let inactiveValue = 0;
      let activePercentage = 0;
      let inactivePercentage = 0;
      let activeText = '';
      let inactiveText = '';

      if (activeInfo) {
        activeValue = activeInfo.total;
        activePercentage = ((activeValue / availableTotal) * 100).toFixed();
        activeText = `${activeValue} (${activePercentage}%)`;
      }

      if (inactiveInfo) {
        inactiveValue = inactiveInfo.total;
        inactivePercentage = ((inactiveValue / availableTotal) * 100).toFixed();
        inactiveText = `${inactiveValue} (${inactivePercentage}%)`;
      }

      setSkuStatusInfo({
        active: activeValue,
        inactive: inactiveValue,
        activePercentage,
        inactivePercentage,
        activeText,
        inactiveText,
      });
    };

    getSkuStatusInfo();
  }, [skuAvailableInfo]);

  if (loading) {
    return <Spin tip="Carregando. Por favor aguarde!" />;
  }

  const getBarsGraphData = () => {
    const currDay = new Date().getDay();
    const { logs } = log7Days;
    const daysInOrder = {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    };

    const data = [];

    for (let index = 6; index >= 0; index -= 1) {
      if (index === 0) {
        data.push({ name: 'hoje', value: 0 });
      } else if (index === 1) {
        data.push({ name: `há ${index} dia`, value: 0 });
      } else {
        data.push({ name: `há ${index} dias`, value: 0 });
      }
    }

    if (logs) {
      Object.entries(logs).forEach(entry => {
        const [entryName, entryValue] = entry;

        daysInOrder[entryName] = entryValue.updated_products_in_day;
      });
    }

    const daysValues = Object.values(daysInOrder);
    const firstDays = daysValues.slice(currDay + 1);
    const lastDays = daysValues.slice(0, currDay + 1);
    const allValues = [...firstDays, ...lastDays];

    for (let index = 0; index < allValues.length; index += 1) {
      data[index].value = allValues[index];
    }

    return data;
  };

  return (
    <div className="catalog-health-main">
      <Title level={3}>Informações gerais</Title>

      {Object.entries(summarizedInfo).length > 0 &&
      Object.entries(skuAvailableInfo).length > 0 &&
      Object.entries(skuStatusInfo).length > 0 ? (
        <>
          <div className="catalog-sku-section">
            <Title level={4} className="catalog-section-title">
              SKUs
            </Title>
            <div className="catalog-sku-cards-section">
              <RadialGraphCard
                graphTile="Total de produtos"
                graphInfo="no catálogo"
                percentageValue={100}
                pathColor="#52C41A"
                trailColor="#D9D9D9"
                strokeWidth={10}
                transitionDuration={2}
                graphText={summarizedInfo?.count_summarized_skus[0]?.count}
                graphWidth="60%"
              />
              <RadialGraphCard
                complex
                graphTile="Estoque"
                strokeWidth={15}
                transitionDuration={2}
                graphText={summarizedInfo?.count_summarized_skus[0]?.count}
                percentageValue={skuAvailableInfo?.availablePercentage}
                pathColor="#1890FF"
                trailColor="#D9D9D9"
                textAlign="left"
                textColor="#1890FF"
                positiveText="disponíveis"
                positiveValue={skuAvailableInfo?.availableText}
                negativeText="indisponíveis"
                negativeValue={skuAvailableInfo?.unavailableText}
                graphWidth="45%"
              />
              <RadialGraphCard
                complex
                graphTile="Status"
                strokeWidth={15}
                transitionDuration={2}
                graphText={summarizedInfo?.count_summarized_skus[0]?.count}
                percentageValue={skuStatusInfo?.activePercentage}
                pathColor="#1890FF"
                trailColor="#D9D9D9"
                textAlign="left"
                textColor="#1890FF"
                positiveText="ativos na loja"
                positiveValue={skuStatusInfo?.activeText}
                negativeText="inativos na loja"
                negativeValue={skuStatusInfo?.inactiveText}
                graphWidth="45%"
              />
            </div>
          </div>
          <div className="catalog-products-section">
            <Title level={3}>Produtos</Title>
            <div className="catalog-products-card-section">
              <RadialGraphCard
                graphTile="Total de produtos"
                graphInfo="últimas 24h"
                percentageValue={100}
                pathColor="#52C41A"
                trailColor="#D9D9D9"
                strokeWidth={10}
                transitionDuration={2}
                graphText={summarizedInfo?.count_summarized_items[0]?.count}
                graphWidth="60%"
              />
            </div>
          </div>
        </>
      ) : (
        <Paragraph type="secondary">
          Não foi possível coletar estas informações no momento. Tente novamente
          mais tarde.
        </Paragraph>
      )}

      <Divider />

      <Title level={3}>Histórico de atualização</Title>
      {Object.entries(log7Days).length > 0 ? (
        <div className="catalog-products-updated">
          <Form.Item
            tooltip="Para visualizar o total exato de cada dia, passe o mouse sobre a barra correspondente"
            label={'Quantidade de produtos atualizados nos últimos 7 dias'}
            colon={false}
          />
          <BarsGraphCard
            graphWidth="85%"
            graphHeight={295}
            graphData={getBarsGraphData()}
            XAxisDataKey="name"
            BarDataKey="value"
            barSize={50}
            barColor="#1890FF"
            cartesianDashArray="0"
            cartesianOpacity={0.25}
            animationDuration={2000}
            CustomToolTip={CustomToolTip}
          />
        </div>
      ) : (
        <Paragraph type="secondary">
          Não foi possível coletar estas informações no momento. Tente novamente
          mais tarde.
        </Paragraph>
      )}
    </div>
  );
};

export default CatalogHealthPage;
