import React from 'react';
import { Form } from 'antd';
import { Colorpicker } from 'antd-colorpicker';

const ColorPickerItem = ({ props }) => {
  const { className, defaultValue, hasFeedback, help, label, name, rules } =
    props;

  return (
    <>
      <Form.Item
        hasFeedback={hasFeedback ?? true}
        help={help}
        initialValue={defaultValue}
        label={label}
        name={name}
        rules={rules}
        className={className}
      >
        <Colorpicker
          popup
          onColorResult={c => c.hex}
          blockStyles={{ width: '90px', height: '40px' }}
        />
      </Form.Item>
    </>
  );
};

export default ColorPickerItem;
