import uuid from 'uuid';
import createConsumer from '../../rest/createConsumer';
import staticContexts from '../static/contexts';
import staticElements from '../static/elements';

const CONTENT_API_URL = process.env.REACT_APP_CONTENT_API_URL;

const ContentApiConsumer = createConsumer(consumer => {
  // CONTEXTS TYPES

  const getContexts = () =>
    new Promise(resolve => {
      resolve(staticContexts);
    });

  const getContextsTree = clientId =>
    new Promise(async resolve => {
      const contexts = await getContexts();
      const pages = await getPages(clientId);

      const tree = contexts.map(context => {
        context.pagesSummary = pages.filter(
          page => page.context.name === context.name,
        );

        return context;
      });

      resolve(tree);
    });

  // PAGES

  const getPages = clientId =>
    new Promise(resolve => {
      consumer
        .loadSource(`${CONTENT_API_URL}/${clientId}/contexts`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const getPage = (clientId, pageId) =>
    new Promise(resolve => {
      consumer
        .loadSource(`${CONTENT_API_URL}/${clientId}/contexts/${pageId}`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const savePage = page =>
    new Promise(async resolve => {
      consumer
        .putSource(
          `${CONTENT_API_URL}/${page.client_id}/contexts/${page._id}`,
          page,
        )
        .then(results => {
          resolve(page);
        });
    });

  const deletePage = (clientId, pageId) =>
    new Promise(async resolve => {
      consumer
        .deleteSource(`${CONTENT_API_URL}/${clientId}/contexts/${pageId}`)
        .then(results => {
          resolve(results);
        });
    });

  // ELEMENTS

  const getElementsTypes = () =>
    new Promise(resolve => resolve(staticElements));

  // STATIC COMPONENTS

  const makePage = ({
    client_id,
    context,
    label = `Nova versão`,
    regions = [],
    elements = [],
    props = {},
  }) =>
    new Promise(resolve => {
      const finalProps = Object.assign(
        {
          isHidden: true,
          isDefault: false,
          status: `draft`,
          version: 2,
          triggers: {
            pageType: null,
            url: { match: null, useRegex: false },
            date: { start: null, end: null },
          },
        },
        props,
        { label },
      );

      const page = {
        _id: uuid.v4(),
        client_id,
        context,
        regions,
        elements,
        props: finalProps,
        updated_at: new Date().toISOString(),
        hidden_at: new Date().toISOString(),
      };

      resolve(page);
    });

  const duplicatePage = page =>
    new Promise(resolve => {
      const newest = JSON.parse(JSON.stringify(page));

      newest._id = uuid.v4();
      newest.props = Object.assign(newest.props, {
        isHidden: true,
        isDefault: false,
        status: `draft`,
        version: 2,
        label: `Cópia de ${newest.props.label}`,
      });

      // all regions and elements must receive new ids too
      newest.regions = newest.regions.map(region => {
        region._oid = region._id;
        region._id = uuid.v4();
        return region;
      });

      newest.elements = newest.elements.map(element => {
        element._id = uuid.v4();

        const region = newest.regions.find(
          region => region._oid === element.region_id,
        );

        element.region_id = region._id;

        return element;
      });

      resolve(newest);
    });

  const makeRegion = ({
    label = `Nova área de layout`,
    placement = { op: null, selector: null, replace: false },
    props = {
      isHidden: false,
      region_type: 'normal',
      inactive_time: 0,
      auto_dismiss: 0,
      width: '1000px',
      height: '1000px',
      background_color: '#000',
      opacity: 0.7,
    },
    elements = [],
  }) =>
    new Promise(resolve => {
      const region = {
        _id: uuid.v4(),
        label,
        placement,
        props,
        elements,
      };

      resolve(region);
    });

  const makeElement = ({
    region_id,
    type,
    label = `Novo elemento`,
    props = {},
    template = {},
    segmentation = [],
  }) =>
    new Promise(resolve => {
      const element = {
        _id: uuid.v4(),
        region_id,
        type,
        label,
        props: { ...props, label },
        template,
        segmentation,
      };

      resolve(element);
    });

  // INTERFACE

  return Object.freeze({
    getContextsTree,
    getPages,
    getPage,
    savePage,
    deletePage,
    duplicatePage,
    makePage,
    makeRegion,
    makeElement,
    getElementsTypes,
    offline: false,
  });
});

export default ContentApiConsumer;
