export default [
  {
    _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
    name: 'best_sellers',
    label: 'Mais vendidos',
    group: 'Melhores ofertas',
    description:
      'Lista os produtos priorizando os mais vendidos na sua operação.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
    },
    cache: {
      enabled: true,
      lifetime: 600,
    },
    visible: true,
    contexts: ['all'],
  },
  {
    _id: 'ea1cc50c-f284-4327-b1db-f51f55779f00',
    name: 'most_seen',
    label: 'Mais visitados',
    group: 'Melhores ofertas',
    description: 'Lista os produtos com maior volume de visitas.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
    },
    cache: {
      enabled: true,
      lifetime: 600,
    },
    visible: true,
    contexts: ['all'],
  },
  // {
  //   _id: 'f21b87a6-d674-44b2-98ad-8660c62f1823',
  //   name: 'high_demand',
  //   label: 'Com poucas unidades',
  //   description:
  //     'Lista os produtos por ordem de estoque, aqueles com menor quantidade aparecem primeiro.',
  //   params: {
  //     filters: true,
  //     limit: {
  //       name: 'limit',
  //       label: 'Quantidade de produtos',
  //       order: 1,
  //       type: 'number',
  //       frontend_type: 'number',
  //       default: 16,
  //       required: true,
  //     },
  //   },
  //   cache: {
  //     enabled: true,
  //     lifetime: 10 * 60,
  //   },
  //   visible: true,
  //   contexts: ['all'],
  // },
  {
    _id: '66361f52-1824-4b0b-8466-cb906487c981',
    name: 'tree_pivot_highlights',
    label: 'Destaques da categoria',
    group: 'Departamentos e categorias',
    description:
      'Lista os produtos da categoria pivot aplicando a inteligência indicada.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      trees_pivot_types: {
        name: 'trees_pivot_types',
        label: 'Filtrar o catálogo sendo visto pelos tipos',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: false,
        options: 'catalog:trees:types',
      },
      tree_type: {
        name: 'tree_type',
        label: 'Personalizar pelo interesse do usuário por',
        order: 4,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: false,
        options: 'catalog:trees:types',
      },
      engine: {
        name: 'engine',
        label: 'Inteligência',
        order: 5,
        type: 'enum',
        frontend_type: 'select',
        required: true,
        default: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
        options: [
          ['0f765f85-3a4c-4990-b3dd-d1a13e310b53', 'Performance'],
          ['ea1cc50c-f284-4327-b1db-f51f55779f00', 'Mais procurados'],
          ['202beafa-966f-4cd1-9f7e-fe5713a76033', 'Mais vendidos'],
          ['2857b1a0-c623-422e-9d4c-0d3f42466d46', 'Lançamentos'],
          ['ca3b97f6-0519-4770-8747-6a6d00324e51', 'Baixaram de preço'],
        ],
      },
    },
    cache: {
      enabled: true,
      lifetime: 1800,
    },
    visible: true,
    contexts: ['catalog'],
  },
  // {
  //   _id: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
  //   name: 'tree_performance',
  //   label: 'Com melhor performance',
  //   group: 'Departamentos e categorias',
  //   description: 'Lista os melhores produtos de uma ou mais categorias.',
  //   is_available: true,
  //   params: {
  //     filters: true,
  //     limit: {
  //       name: 'limit',
  //       label: 'Quantidade de produtos',
  //       order: 1,
  //       type: 'number',
  //       frontend_type: 'number',
  //       default: 16,
  //       required: true,
  //     },
  //   },
  //   cache: {
  //     enabled: true,
  //     lifetime: 600,
  //   },
  //   visible: true,
  //   contexts: ['all'],
  // },
  {
    _id: 'ca3b97f6-0519-4770-8747-6a6d00324e51',
    name: 'low_prices',
    label: 'Baixaram de preço',
    group: 'Melhores ofertas',
    description: 'Lista os produtos que baixaram de preço.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      sort: {
        name: 'sort',
        label: 'Ordenar por',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        default: 'discount_amount',
        required: true,
        options: [
          ['discount_amount', 'Percentual de desconto', true],
          ['discount_absolute', 'Desconto absoluto'],
          ['newest', 'Mais novos primeiro'],
          ['performance', 'Performance do produto'],
          ['best_sellers', 'Posição no índice Mais Vendidos'],
          ['most_seen', 'Posição no índice Mais Vistos'],
        ],
      },
    },
    cache: {
      enabled: true,
      lifetime: 600,
    },
    visible: true,
    contexts: ['all'],
  },
  {
    _id: '2857b1a0-c623-422e-9d4c-0d3f42466d46',
    name: 'new_products',
    label: 'Lançados recentemente',
    group: 'Melhores ofertas',
    description: 'Lista os produtos mais novos na sua operação.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      // sort: {
      //     name: 'sort',
      //     label: 'Ordenar por',
      //     order: 2,
      //     type: 'string',
      //     frontend_type: 'select',
      //     default: 'newest',
      //     required: true,
      //     options: [
      //         ['newest', 'Mais novos primeiro', true],
      //         ['performance', 'Performance do produto'],
      //         ['best_sellers', 'Posição no índice Mais Vendidos'],
      //         ['most_seen', 'Posição no índice Mais Vistos'],
      //         ['high_demand', 'Posição no índice Alta Demanda'],
      //     ],
      // },
    },
    cache: {
      enabled: true,
      lifetime: 1800,
    },
    visible: true,
    contexts: ['all'],
  },
  // {
  //     _id: 'c4a680ba-a3f6-414d-86b7-6e7e0316860b',
  //     name: 'seen_bought',
  //     label: 'Quem viu, comprou.',
  //     group: 'Produtos',
  //     description:
  //         'Lista os produtos que outras pessoas compraram ao ver este.',
  //     is_available: true,
  //     params: {
  //         filters: true,
  //         limit: {
  //             name: 'limit',
  //             label: 'Quantidade de produtos',
  //             order: 1,
  //             type: 'number',
  //             frontend_type: 'number',
  //             default: 16,
  //             required: true,
  //         },
  //         pivot_source: {
  //             name: 'pivot_source',
  //             label: 'Produto de referência',
  //             order: 2,
  //             type: 'string',
  //             frontend_type: 'select',
  //             required: true,
  //             default: 'request',
  //             options: [
  //                 ['request', 'Página atual', true],
  //                 ['param', 'Especificar um ID'],
  //             ],
  //         },
  //     },
  //     cache: {
  //         enabled: true,
  //         lifetime: 1800,
  //     },
  //     visible: true,
  //     contexts: ['product'],
  // },
  // {
  //     _id: '78c550fc-66a5-4a14-b448-60e9ff537964',
  //     name: 'bought_together',
  //     label: 'Quem comprou, comprou também.',
  //     group: 'Produtos',
  //     description: 'Lista os produtos comprados junto com este.',
  //     is_available: true,
  //     params: {
  //         filters: true,
  //         limit: {
  //             name: 'limit',
  //             label: 'Quantidade de produtos',
  //             order: 1,
  //             type: 'number',
  //             frontend_type: 'number',
  //             default: 16,
  //             required: true,
  //         },
  //         pivot_source: {
  //             name: 'pivot_source',
  //             label: 'Produto de referência',
  //             order: 2,
  //             type: 'string',
  //             required: true,
  //             default: 'request',
  //             frontend_type: 'select',
  //             options: [
  //                 ['request', 'Página atual', true],
  //                 ['param', 'Especificar um ID'],
  //             ],
  //         },
  //     },
  //     cache: {
  //         enabled: true,
  //         lifetime: 1800,
  //     },
  //     visible: true,
  //     contexts: ['all'],
  // },
  {
    _id: '3fa7fc89-8c36-454c-b721-d98726291805',
    name: 'product_pivot_tree',
    label: 'Produtos relacionados',
    group: 'Produtos',
    description: 'Lista os produtos relacionados a uma mesma árvore que este.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      tree_type: {
        name: 'tree_type',
        label: 'Combinar com a característica',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: true,
        options: 'catalog:trees:types',
      },
      tree_dyn_filter: {
        name: 'tree_dyn_filter',
        label: 'Filtros dinâmicos',
        order: 4,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: false,
        options: 'catalog:trees:types',
      },
      price_condition: {
        name: 'price_condition',
        label: 'Condição de preço',
        order: 5,
        type: 'string',
        frontend_type: 'select',
        required: true,
        default: 'none',
        options: [
          ['none', 'Não aplicar', true],
          ['lte', 'Menor ou igual'],
          ['gte', 'Maior ou igual'],
        ],
      },
      engine: {
        name: 'engine',
        label: 'Inteligência',
        order: 6,
        type: 'string',
        frontend_type: 'select',
        required: true,
        default: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
        options: [
          ['0f765f85-3a4c-4990-b3dd-d1a13e310b53', 'Performance', true],
          ['ea1cc50c-f284-4327-b1db-f51f55779f00', 'Mais procurados', true],
          ['202beafa-966f-4cd1-9f7e-fe5713a76033', 'Mais vendidos', true],
        ],
      },
    },
    cache: {
      enabled: true,
      lifetime: 1800,
    },
    visible: true,
    contexts: ['product'],
  },
  // {
  //     _id: '397e121d-f190-44ad-81f0-3baef0a278a2',
  //     name: 'cart_bought_together',
  //     label: 'Compre junto no carrinho',
  //     group: 'Carrinho',
  //     description:
  //         'Lista os produtos que foram comprados juntos com algum dos produtos do carrinho.',
  //     is_available: false,
  //     params: {
  //         filters: true,
  //         limit: {
  //             name: 'limit',
  //             label: 'Quantidade de produtos',
  //             order: 1,
  //             type: 'number',
  //             frontend_type: 'number',
  //             default: 16,
  //             required: true,
  //         },
  //     },
  //     cache: {
  //         enabled: true,
  //         lifetime: 1800,
  //     },
  //     visible: true,
  //     contexts: ['homepage', 'cart'],
  // },
  // {
  //     _id: 'f150efe4-28a4-4ebc-91b3-c9f6147b99f2',
  //     name: 'cart_out_of_stock',
  //     label: 'Alternativas aos produtos esgotados no carrinho',
  //     group: 'Carrinho',
  //     description:
  //         'Seleciona produtos alternativos àqueles esgotados no carrinho do cliente.',
  //     is_available: false,
  //     params: {
  //         filters: true,
  //         limit: {
  //             name: 'limit',
  //             label: 'Quantidade de produtos',
  //             order: 1,
  //             type: 'number',
  //             frontend_type: 'number',
  //             default: 16,
  //             required: true,
  //         },
  //         engine: {
  //             name: 'engine',
  //             label: 'Inteligência',
  //             order: 2,
  //             type: 'string',
  //             frontend_type: 'select',
  //             required: true,
  //             default: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
  //             options: [
  //                 [
  //                     '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
  //                     'Performance',
  //                     true,
  //                 ],
  //                 ['ea1cc50c-f284-4327-b1db-f51f55779f00', 'Mais procurados'],
  //                 ['202beafa-966f-4cd1-9f7e-fe5713a76033', 'Mais vendidos'],
  //             ],
  //         },
  //     },
  //     cache: {
  //         enabled: false,
  //         lifetime: 0,
  //     },
  //     visible: true,
  //     contexts: ['homepage', 'cart'],
  // },
  {
    _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
    name: 'profile_recent_products',
    label: 'Visualizados pelo usuário',
    group: 'Perfil e histórico',
    description: 'Exibe os produtos vistos recentemente.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      order_by: {
        name: 'order_by',
        label: 'Ordenação dos produtos',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        required: true,
        default: 'relevance',
        options: [
          ['relevance', 'Relevância para o usuário', true],
          ['time', 'Ordem cronológica'],
        ],
      },
    },
    cache: {
      enabled: false,
      lifetime: 0,
    },
    visible: true,
    contexts: ['all'],
  },
  {
    _id: '4d854a1b-79db-4edb-ae44-44a73596c370',
    name: 'profile_recent_trees',
    label: 'Para os interesses do usuário',
    group: 'Perfil e histórico',
    description: 'Exibe produtos das categorias de maior relevância.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      tree_type: {
        name: 'tree_type',
        label: 'Personalizar pelo interesse do usuário por',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: true,
        options: 'catalog:trees:types',
      },
      personalization_type: {
        name: 'personalization_type',
        label: 'Organizando os produtos',
        order: 4,
        type: 'string',
        frontend_type: 'select',
        allow_multi: false,
        required: true,
        options: [
          ['priority', 'Priorizando os de maior interesse'],
          ['restrict', 'Trazendo apenas os produtos relacionados ao interesse'],
        ],
        default: 'priority',
      },
      engine: {
        name: 'engine',
        label: 'Inteligência',
        order: 5,
        type: 'string',
        frontend_type: 'select',
        required: true,
        default: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
        options: [
          ['0f765f85-3a4c-4990-b3dd-d1a13e310b53', 'Performance', true],
          ['ea1cc50c-f284-4327-b1db-f51f55779f00', 'Mais procurados'],
          ['202beafa-966f-4cd1-9f7e-fe5713a76033', 'Mais vendidos'],
          ['2857b1a0-c623-422e-9d4c-0d3f42466d46', 'Lançamentos'],
          ['ca3b97f6-0519-4770-8747-6a6d00324e51', 'Baixaram de preço'],
          ['f21b87a6-d674-44b2-98ad-8660c62f1823', 'Últimas unidades'],
        ],
      },
    },
    cache: {
      enabled: false,
      lifetime: 0,
    },
    visible: true,
    contexts: ['all'],
  },
  {
    _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
    name: 'profile_view_also',
    label: 'Você viu, veja também.',
    group: 'Perfil e histórico',
    description: 'Exibe produtos relacionados aos últimos vistos pelo cliente.',
    is_available: true,
    params: {
      filters: true,
      limit: {
        name: 'limit',
        label: 'Quantidade de produtos',
        order: 1,
        type: 'number',
        frontend_type: 'number',
        default: 16,
        required: true,
      },
      show_variations: {
        name: 'show_variations',
        label: 'Exibir variações dos produtos',
        help: 'Exibe as variações dos produtos nas vitrines (ex: tamanho, cor e etc), caso essas informações estejam disponíveis.',
        order: 2,
        type: 'boolean',
        frontend_type: 'switch',
        default: false,
        required: false,
      },
      tree_type: {
        name: 'tree_type',
        label: 'Recomendar produtos pela classificação:',
        order: 3,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: true,
        options: 'catalog:trees:types',
      },
      tree_combine: {
        name: 'tree_combine',
        label: 'Combinar com as características:',
        order: 4,
        type: 'string',
        frontend_type: 'select',
        allow_multi: true,
        required: false,
        options: 'catalog:trees:types',
      },
      engine: {
        name: 'engine',
        label: 'Inteligência',
        order: 5,
        type: 'string',
        frontend_type: 'select',
        required: true,
        default: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
        options: [
          ['0f765f85-3a4c-4990-b3dd-d1a13e310b53', 'Performance', true],
          ['ea1cc50c-f284-4327-b1db-f51f55779f00', 'Mais procurados'],
          ['202beafa-966f-4cd1-9f7e-fe5713a76033', 'Mais vendidos'],
          ['2857b1a0-c623-422e-9d4c-0d3f42466d46', 'Lançamentos'],
          ['ca3b97f6-0519-4770-8747-6a6d00324e51', 'Baixaram de preço'],
        ],
      },
    },
    cache: {
      enabled: false,
      lifetime: 0,
    },
    visible: true,
    contexts: ['all'],
  },
];
