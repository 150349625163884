import React, { useState } from 'react';
import { Layout, Collapse, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import CatalogHealthPage from './CatalogHealthPage';
import VtexIntegrationTab from './VtexIntegration';

import { useTenant } from '../../../context/useTenant';
import './advancedTab.css';

const pages = [
  {
    active: true,
    page: 'vtex-integration',
    pageName: 'Integração VTEX',
    pageSection: 'Integrações',
  },
  {
    active: true,
    page: 'catalog-health-page',
    pageName: 'Status do catálogo',
    pageSection: 'Integrações',
  },
];

const VTEX_PLATFORMS = ['vtex', 'VTEX', 'vtexio', 'VTEXIO'];

const AdvancedTab = () => {
  const { tenantData } = useTenant();
  const { Sider, Header, Content } = Layout;
  const { Panel } = Collapse;

  const [activePage, setActivePage] = useState({
    active: true,
    page: 'catalog-health-page',
    pageName: 'Status do catálogo',
    pageSection: 'Integrações',
  });

  const onMenuItemClick = pageClicked => {
    const pageFounded = pages.find(({ page }) => page === pageClicked);
    setActivePage(pageFounded);
  };

  const getActivePage = () => {
    switch (activePage.page) {
      case 'vtex-integration':
        return <VtexIntegrationTab />;
      case 'catalog-health-page':
        return <CatalogHealthPage />;
      default:
        return null;
    }
  };

  return (
    <Layout className="sider-layout-style">
      <Sider width="20%">
        <Collapse
          className="collapse-panel-style"
          ghost
          expandIconPosition="right"
          defaultActiveKey="1"
        >
          <Panel header="Integrações" key="1">
            <Button onClick={() => onMenuItemClick('catalog-health-page')}>
              Status do catálogo
            </Button>

            {VTEX_PLATFORMS.includes(tenantData?.profile?.platform) && (
              <Button
                className="vtex-integration-button"
                onClick={() => onMenuItemClick('vtex-integration')}
              >
                Integração VTEX
              </Button>
            )}
          </Panel>
        </Collapse>
      </Sider>

      <Layout className="content-layout-style">
        <Header>
          {activePage.pageSection} <RightOutlined /> {activePage.pageName}
        </Header>
        <Content className="content">{getActivePage()}</Content>
      </Layout>
    </Layout>
  );
};

export default AdvancedTab;
