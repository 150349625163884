import React from 'react';
import { Button, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const DEFAULT_STYLE = { width: '350px' };

const SyncText = ({ syncTextMode, restartSync }) => {
  const getContent = () => {
    switch (syncTextMode) {
      case 'finished':
        return (
          <>
            <Title level={3}>Sincronização concluída</Title>
            <Paragraph>
              Todos os produtos encontrados foram processados para
              sincronização.
            </Paragraph>
            <Button type="primary" onClick={restartSync}>
              Nova sincronização
            </Button>
          </>
        );

      case 'batching':
        return (
          <>
            <Title level={3}>Processando requisição</Title>
            <Paragraph>
              Buscando produtos relativos à sua busca, com um máximo de 2000
              SKUs. Mantenha esta janela aberta durante o processamento.
            </Paragraph>
          </>
        );

      default:
        return (
          <>
            <Title level={3}>Sincronização em andamento</Title>
            <Paragraph>
              Mantenha esta janela aberta enquanto sincronizamos o seu catálogo.
            </Paragraph>
          </>
        );
    }
  };

  return <div style={DEFAULT_STYLE}>{getContent()}</div>;
};

export default SyncText;
