const ElementSnippetTabValidate = ({
    element,
    fieldsValidation,
    fieldsValues,
}) =>
    new Promise((resolve) => {
        const status = {
            success: true,
            tab: 'snippet',
            message: null,
        };

        // // validation passed, handle fields
        // elemento já é manipulado no estado
        // element.snippet = fieldsValues['snippet'];

        // // handle fielters

        return resolve(status);
    });

export default ElementSnippetTabValidate;
