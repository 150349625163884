import React from 'react';
import { Form, Switch } from 'antd';

const SwitchItem = ({ props }) => {
  const { className, defaultValue, hasFeedback, help, label, name, rules } =
    props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
    >
      <Switch
        defaultChecked={defaultValue}
        checkedChildren={'On'}
        unCheckedChildren={'Off'}
      />
    </Form.Item>
  );
};

export default SwitchItem;
