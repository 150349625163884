import axios from 'axios';

const catalogUrl = process.env.REACT_APP_CATALOG_API_URL;

const catalogAPI = axios.create({
  baseURL: catalogUrl,
  timeout: 20000,
});

export const getSummarizeInfo = async id => {
  try {
    const result = await catalogAPI.get(`/${id}/status/summarize`)

    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getSummarize7days = async id => {
  try {
    const result = await catalogAPI.get(`/${id}/status/logs/7days`);

    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};