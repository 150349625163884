import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const SynonymActionEdit = ({ onEdit }) => {
  return (
    <Button
      type="default"
      shape="circle"
      icon={<EditOutlined />}
      onClick={onEdit}
    />
  );
};

export default SynonymActionEdit;
