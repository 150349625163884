import createConsumer from '../../rest/createConsumer';
import staticUsers from './users';

const AuthApiConsumer = createConsumer(consumer => {
  const authenticate = (username, password) =>
    new Promise(resolve => {
      const user = staticUsers.find(
        user => user.username === username && user.password === password,
      );

      if (user) {
        return resolve(user);
      } else {
        return resolve(new Error(`Credenciais inválidas`));
      }
    });

  return Object.freeze({
    authenticate,
  });
});

export default AuthApiConsumer;
