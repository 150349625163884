import React from 'react';
import produce from 'immer';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EmptyComponent from '../../../../Common/Empty/Empty';
import RegionsList from '../../../../Common/Region/List';
import RegionItem from '../../../../Common/Region/Item';
import RegionActionEdit from '../../../../Common/Region/Actions/Edit';
import RegionActionDelete from '../../../../Common/Region/Actions/Delete';

import PageElementsListContainer from '../Elements/List';

import ContentApiConsumer from '../../../../../core/content/api';

import './styles/region.css';
import { generateLog } from '../../../../../domain/Logs';

export default class PageRegionsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: props.regions,
      elements: props.elements,
    };

    this.store = props.store;
    this.client_id = props.client_id;
  }

  componentWillMount() {
    this.setState({
      regions: this.mapElementsToRegions(
        this.state.regions,
        this.state.elements,
      ),
    });
  }

  componentWillReceiveProps(nextProps) {
    const { regions, elements } = nextProps;
    this.setState({
      regions: this.mapElementsToRegions(regions, elements),
      elements,
    });
  }

  mapElementsToRegions(regions, elements) {
    return regions.map(region => {
      region.elements = elements.filter(
        element => element.region_id === region._id,
      );
      return region;
    });
  }

  async addRegion() {
    this.setState(
      produce(draft => {
        draft.addRegionLoading = true;
      }),
    );

    // essa ação deveria estar próxima das demais ações de manipulação,
    // é uma boa prática centralizar esse tipo de lógica.
    // no entanto, faz sentido que a lista de regions saiba gerar uma nova,
    // delegando pra cima apenas a responsabilidade de persistência.
    const region = await ContentApiConsumer.makeRegion({
      label: 'Nova área de layout',
    });

    // this region will be added to local state.
    // when user saves its configurations, this region will be sent to API.

    this.setState(
      produce(draft => {
        draft.addRegionLoading = false;
        draft.regions.push(region);
      }),
    );

    // init region edit
    this.store.emitter.dispatch(this.store.emitter.REGION_EDIT_START, region);
  }

  handleAddRegionButton = event => {
    this.addRegion();
  };

  handleSettingsButton = region => {
    this.store.emitter.dispatch(this.store.emitter.REGION_EDIT_START, region);
  };

  handleDeleteButton = region => {
    this.store.deleteRegion(region._id);
    message.success(`Área de layout removida da página.`);

    const user_email = JSON.parse(localStorage.getItem('current_user_meta')).email;
    const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;
    const pageRegion = this.store.getPage().context.name;
    const pageName = this.store.getPage().props.label;

    const log = {
      app_location: 'Beon Studio',
      tenant_id: this.client_id,
      time_stamp: new Date().toJSON(),
      user_email,
      user_id,
      resource: `${pageRegion}-${pageName}-${region.label}`,
      action_type: 'Área de layout deletada',
    };

    generateLog(log).then(result => {
      if (result.status !== 201) {
        message.error('Falha ao gerar log');
      }
    })
  };

  handleAddElement(event) {
    event.preventDefault();

    this.store.emitter.dispatch(
      this.store.emitter.ELEMENT_ADD_START,
      this.state.region,
    );
  }

  render() {
    return (
      <>
        <RegionsList
          regions={this.state.regions}
          empty={
            <EmptyComponent
              description={<span>Sua página não contém áreas de layout.</span>}
              action={<span>Crie a primeira agora</span>}
              buttonProps={{
                icon: <PlusOutlined />,
                type: 'primary',
                onClick: this.handleAddRegionButton.bind(this),
                loading: this.state.addRegionLoading,
              }}
            />
          }
          addItem={
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={this.handleAddRegionButton.bind(this)}
              loading={this.state.addRegionLoading}
            >
              Adicionar área de layout
            </Button>
          }
        >
          {region => (
            <RegionItem
              key={region._id}
              region={region}
              actions={[
                <RegionActionEdit
                  key={`${region._id}:action:edit`}
                  region={region}
                  handle={() => this.handleSettingsButton(region)}
                />,
                <RegionActionDelete
                  key={`${region._id}:action:delete`}
                  region={region}
                  handle={() => this.handleDeleteButton(region)}
                />,
              ]}
              elements={
                <PageElementsListContainer
                  store={this.store}
                  elements={region.elements}
                  region={region}
                  client_id={this.client_id}
                />
              }
            />
          )}
        </RegionsList>
      </>
    );
  }
}
