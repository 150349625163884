import React, { useEffect, useState } from 'react';
import { Button, Form, Popconfirm, Typography } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import ClassificationsSettings from '../../../../../Common/Search/SearchConfig/ClassificationsSettings';
import LoadingComponent from '../../../../../Common/Loading/Loading';

const { Title, Paragraph } = Typography;

const BACKEND = 'backend';
const CLASSIFICATIONS = 'classifications';

const ClassificationsTab = ({
  formItems,
  handleSubmit,
  tenantConfig,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [productsClassifications, setProductsClassifications] = useState([]);

  useEffect(() => {
    const { properties } = formItems;

    const classifications = filterByKeywords(properties, [
      BACKEND,
      CLASSIFICATIONS,
    ]);

    setProductsClassifications(classifications);
  }, []);

  if (!productsClassifications.length) {
    return <LoadingComponent />;
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      size="large"
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <Title level={4}>Classificações do produto</Title>

        <Paragraph>
          Ajusta como as classificações de produto serão utilizadas na pesquisa.
          Alterações nessas configurações podem levar um tempo para refletir
          mudanças para os usuários.
        </Paragraph>

        <ClassificationsSettings
          form={form}
          productsClassifications={productsClassifications}
          tenantConfig={tenantConfig}
        />
      </div>
    </Form>
  );
};

export default ClassificationsTab;
