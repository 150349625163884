import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import createConsumer from '../core/rest/createConsumer';

const TENANT_API_URL = process.env.REACT_APP_TENANT_API_URL;

const TenantContext = createContext(null);
export const TenantProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [error, setError] = useState(false);

  const handleTenantData = (tenantId, setTenant = true) =>
    new Promise((resolve, _) => {
      const options = { cache: false };

      createConsumer(consumer => {
        consumer
          .loadSource(`${TENANT_API_URL}/${tenantId}`, options)
          .then(response => {
            if (response.statusCode >= 400) {
              console.log(new Error(response.message));
              setError(true);
              setLoading(false);
              resolve(false);
            } else {
              if (setTenant) {
                setTenantData(response);
              }
              localStorage.setItem('tenantDomain', response.profile?.base_url);
              setLoading(false);
              setError(false);
              resolve(response);
            }
          });
      });
    });

  return (
    <TenantContext.Provider
      value={{
        setTenantData,
        tenantData,
        handleTenantData,
        loading,
        error,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
