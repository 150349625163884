import createConsumer from '../../rest/createConsumer';

const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API_URL;

const CatalogApiRestConsumer = createConsumer(consumer => {
  const getTrees = clientId =>
    new Promise(resolve => {
      consumer
        .loadSource(`${CATALOG_API_URL}/${clientId}/tree/catalog`)
        .then(trees => {
          const unique = [];

          if (!trees) {
            return resolve([]);
          }

          // remove duplicate trees
          const final = trees.filter(tree => {
            const id = `${tree.kind}:${tree.tree_id}`;

            if (unique.indexOf(id) < 0) {
              unique.push(id);
              return true;
            }

            return false;
          });

          resolve(final);
        });
    });

  const getTreesTypes = clientId =>
    new Promise(resolve => {
      consumer
        .loadSource(`${CATALOG_API_URL}/${clientId}/tree/types`)
        .then(types => {
          if (!types) {
            return resolve([]);
          }

          resolve(types);
        });
    });

  const getTreesByType = (clientId, type) =>
    new Promise(resolve => {
      getTrees(clientId).then(trees =>
        resolve(trees.filter(tree => tree.kind === type)),
      );
    });

  const searchTrees = ({ clientId, term }) =>
    new Promise(resolve => {
      consumer
        .loadSource(`${CATALOG_API_URL}/${clientId}/tree/search/?term=${term}`)
        .then(trees => {
          if (!trees) {
            return resolve([]);
          }

          resolve(trees);
        });
    });

  return Object.freeze({
    getTrees,
    getTreesTypes,
    getTreesByType,
    searchTrees,
  });
});

export default CatalogApiRestConsumer;
