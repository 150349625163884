import React from 'react';
import produce from 'immer';
import uuid from 'uuid';
import { Button, Tag } from 'antd';
import SegmentationForm from '../../../../../../../../Common/Segmentation/Form';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

export default class SegmentationBulletsListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variable: props.variable,
      definitions: props.definitions,
    };

    this.state.editing = this.getDefaultDefinition();

    this.inputs = {};
  }

  componentDidMount() {
    // bind modal trigger
  }

  getDefaultDefinition() {
    return {
      visible: false,
      definition: null,
    };
  }

  handleTagClose = definition => {
    const definitions = this.state.definitions.filter(
      item => item._id !== definition._id,
    );

    this.setState({ definitions });

    this.props.onRemove(definition);
  };

  handleTagEdit = definition => {
    this.setState(
      produce(draft => {
        draft.editing = {
          definition,
          visible: true,
        };
      }),
    );
  };

  handleInputVisibility = visible => {
    this.setState(
      produce(draft => {
        draft.editing.visible = visible;
      }),
    );
  };

  // put new definition into list
  handleConfirm = data => {
    let { definitions } = this.state;
    let definition;

    const now = new Date().toISOString();

    if (data.expected) {
      if (data._id) {
        definitions = definitions.map(item => {
          if (item._id === data._id) {
            item.updated_at = now;
            item.operator = data.operator;
            item.expected = data.expected;
          }

          return item;
        });
      } else {
        definition = {
          _id: uuid.v4(),
          created_at: now,
          updated_at: now,
          operator: data.operator,
          expected: data.expected,
          variable: this.state.variable.name,
        };

        definitions.push(definition);
      }
    }

    this.setState({
      definitions,
      editing: this.getDefaultDefinition(),
    });

    this.props.onChange(definition);
  };

  handleCancel = () => {
    this.setState({
      editing: this.getDefaultDefinition(),
    });
  };

  getOperatorLabel = operator => operator;

  getExpectedFormated = item => {
    const { variable, expected } = item;

    if (variable === `datetime_timestamp`)
      return moment(Number(expected)).format('DD/MM/YYYY HH:mm');
    return expected;
  };

  render() {
    let { variable, definitions } = this.state;
    const editingVisible = this.state.editing.visible;

    return (
      <>
        {definitions
          .filter(definition => definition.variable === variable.name)
          .map(definition => (
            <Tag
              closable
              key={definition._id}
              onClick={event => {
                this.handleTagEdit(definition);
              }}
              onClose={event => {
                event.stopPropagation();
                this.handleTagClose(definition);
              }}
              className="variable-bullet"
            >
              {this.getOperatorLabel(definition.operator)}:{' '}
              {this.getExpectedFormated(definition)}
            </Tag>
          ))}

        {editingVisible && (
          <SegmentationForm
            confirm={this.handleConfirm}
            cancel={this.handleCancel}
            variable={this.state.variable}
            definition={this.state.editing.definition}
          />
        )}

        {!editingVisible && (
          <Button
            onClick={() => this.handleInputVisibility(true)}
            style={{ background: '#fff', borderStyle: 'dashed' }}
            className="variable-bullet-add"
            icon={<PlusOutlined />}
            shape="circle"
            size="small"
          />
        )}
      </>
    );
  }
}
