import ElementLayoutTabComponent from '../Tabs/Layout/Tab';
import ElementLayoutTabValidate from '../Tabs/Layout/Validate';
import ElementSegmentationTabComponent from '../Tabs/Segmentation/Tab';

export default [
    {
        key: `layout`,
        title: `Layout`,
        component: ElementLayoutTabComponent,
        validate: ElementLayoutTabValidate,
    },
    {
        key: `segments`,
        title: `Segmentação`,
        component: ElementSegmentationTabComponent,
    },
];
