import React from 'react';
import { Col, Typography } from 'antd';
import FormFieldComponent from '../../../../../../../Common/Form/Field';
const { Title } = Typography;

export default class RecommendationEngineFiltersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: props.element,
      recommendation: props.recommendation,
    };
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  getAppliedFilters = type => {
    const { element } = this.state;

    if (
      element.engine &&
      element.engine.params &&
      element.engine.params.filters
    ) {
      return element.engine.params.filters[type];
      // .filter(filter => filter.type === type)
      // .map(filter => `${filter.tree.type}:${filter.tree.id}`);
    } else {
      return;
    }
  };

  getAppliedAttributeFilters = name => {
    const { element } = this.state;

    if (
      element.engine &&
      element.engine.params &&
      element.engine.params.filters &&
      element.engine.params.filters.attributes
    ) {
      return element.engine.params.filters.attributes[name];
      // .filter(filter => filter.type === type)
      // .map(filter => `${filter.tree.type}:${filter.tree.id}`);
    } else {
      return;
    }
  };

  getAppliedParam = name => {
    const { element } = this.state;

    if (
      element.engine &&
      element.engine.params &&
      element.engine.params[name]
    ) {
      return element.engine.params[name];
      // .filter(filter => filter.type === type)
      // .map(filter => `${filter.tree.type}:${filter.tree.id}`);
    } else {
      return;
    }
  };

  render() {
    return (
      <>
        <Col offset="6">
          <Title level={4}>Filtro por Classificações</Title>
        </Col>

        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.include`}
          label="Inclusão"
          help="Filtros de inclusão indicam em quais categorias o produto deve estar inserido para ser selecionado nessa recomendação."
          settedValue={this.getAppliedFilters('include')}
          required={false}
          frontendType="select"
          options="catalog:trees:nodes"
          allowMulti={true}
        />

        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.exclude`}
          label="Exclusão"
          help="Filtros de exclusão indicam em quais categorias o produto não deve estar inserido para ser selecionado nessa recomendação."
          settedValue={this.getAppliedFilters('exclude')}
          required={false}
          frontendType="select"
          options="catalog:trees:nodes"
          allowMulti={true}
        />

        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.include_all`}
          label="Comportamento"
          help="Filtros de catálogo podem atuar de duas formas: a) Amplo: o produto precisa atender a pelo menos uma regra do filtro, atuando como um filtro de combinação - combinando Tênis e Sapatos das marcas Nike e Adidas, por exemplo; b) Restrito: o produto deve atender a todas as regras ao mesmo tempo, atuando como um filtro de seleção específica - selecionando Tênis da marca Nike, por exemplo."
          settedValue={this.getAppliedFilters('include_all')}
          defaultValue="one"
          required={false}
          frontendType="select"
          options={[
            ['one', 'Amplo'],
            ['all', 'Restrito'],
          ]}
          allowMulti={false}
        />

        <Col offset="6">
          <Title level={4}>Produtos selecionados</Title>
        </Col>
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.product_selection_key`}
          label="Tipo de identificação"
          help="Informe qual o campo de identificação a ser utilizado."
          settedValue={this.getAppliedFilters('product_selection_key')}
          required={false}
          defaultValue="none"
          frontendType="select"
          options={[
            ['none', 'Não se aplica'],
            ['sku', 'ID da SKU'],
            ['product_id', 'ID do produto'],
          ]}
          allowMulti={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.product_selection_behavior`}
          label="Comportamento"
          help="Define se você irá incluir ou excluir os itens selecionados"
          settedValue={this.getAppliedFilters('product_selection_behavior')}
          required={false}
          defaultValue="none"
          frontendType="select"
          options={[
            ['none', 'Não se aplica'],
            ['include', 'Incluir'],
            ['exclude', 'Excluir'],
          ]}
          allowMulti={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.product_selection_ids`}
          label="IDs dos produtos"
          help="Informa os IDs separados por vírgula."
          settedValue={this.getAppliedFilters('product_selection_ids')}
          required={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.product_selection_keep_order`}
          label="Manter a ordem"
          help="Se sim, ignora a ordenação da recomendação e mantém os itens na ordem em que aparecem na lista."
          settedValue={this.getAppliedFilters('product_selection_keep_order')}
          required={false}
          defaultValue="no"
          frontendType="select"
          options={[
            ['no', 'Não'],
            ['yes', 'Sim'],
          ]}
          allowMulti={false}
        />
        <Col offset="6">
          <Title level={4}>Preços</Title>
        </Col>
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.price_range_from`}
          label="De"
          help="Informe apenas números sem casa decimal."
          settedValue={this.getAppliedFilters('price_range_from')}
          required={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.price_range_to`}
          label="Até"
          help="Informe apenas números sem casa decimal."
          settedValue={this.getAppliedFilters('price_range_to')}
          required={false}
        />

        <Col offset="6">
          <Title level={4}>Situação do produto</Title>
        </Col>
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.attributes.is_promo`}
          label="Promocionais"
          help="Determina como os produtos em promoção serão tratados nessa recomendação."
          settedValue={this.getAppliedAttributeFilters('is_promo')}
          required={false}
          defaultValue="none"
          frontendType="select"
          options={[
            ['none', 'Não se aplica'],
            ['include', 'Exibir apenas produtos promocionais'],
            ['exclude', 'Remover os produtos promocionais'],
          ]}
          allowMulti={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.attributes.is_new`}
          label="Lançamentos"
          help="Determina como os produtos em lançamento serão tratados nessa recomendação."
          settedValue={this.getAppliedAttributeFilters('is_new')}
          required={false}
          defaultValue="none"
          frontendType="select"
          options={[
            ['none', 'Não se aplica'],
            ['include', 'Exibir apenas lançamentos'],
            ['exclude', 'Remover os lançamentos'],
          ]}
          allowMulti={false}
        />
        <FormFieldComponent
          form={this.props.form}
          name={`engine.params.filters.attributes.is_available`}
          label="Estoque"
          help="Determina como os produtos sem estoque serão tratados nessa recomendação."
          settedValue={this.getAppliedAttributeFilters('is_available')}
          required={false}
          defaultValue="exclude"
          frontendType="select"
          options={[
            ['none', 'Não se aplica'],
            ['include', 'Exibir apenas produtos esgotados'],
            ['exclude', 'Remover produtos esgotados'],
          ]}
          allowMulti={false}
        />
      </>
    );
  }
}
