import React, { useEffect, useState } from 'react';
import { Form, Select, Slider, Switch, Table } from 'antd';

const CLASSIFICATION_KEYS = [
  'searcheable',
  'nameResolution',
  'useAsAutofilter',
  'autofilterPartial',
  'useAsFacet',
  'boost',
];

const columns = [
  {
    title: 'Classificação',
    dataIndex: 'classification',
    key: 'classification',
  },
  {
    title: 'Campo de pesquisa?',
    dataIndex: 'searcheable',
    key: 'searcheable',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_searcheable`}
        initialValue={value}
        hasFeedback={false}
      >
        <Switch defaultChecked={value} />
      </Form.Item>
    ),
  },
  {
    title: 'Nome exato ou aproximado',
    dataIndex: 'nameResolution',
    key: 'nameResolution',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_nameResolution`}
        initialValue={value}
        hasFeedback={false}
      >
        <Select
          options={[
            {
              label: 'exact',
              value: 'exact',
            },
            {
              label: 'fuzzy',
              value: 'fuzzy',
            },
          ]}
        />
      </Form.Item>
    ),
  },
  {
    title: 'Auto-filtro?',
    dataIndex: 'useAsAutofilter',
    key: 'useAsAutofilter',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_useAsAutofilter`}
        initialValue={value}
        hasFeedback={false}
      >
        <Switch defaultChecked={value} />
      </Form.Item>
    ),
  },
  {
    title: 'Auto-filtro parcial?',
    dataIndex: 'autofilterPartial',
    key: 'autofilterPartial',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_autofilterPartial`}
        initialValue={value}
        hasFeedback={false}
      >
        <Switch defaultChecked={value} />
      </Form.Item>
    ),
  },
  {
    title: 'Filtro de pesquisa?',
    dataIndex: 'useAsFacet',
    key: 'useAsFacet',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_useAsFacet`}
        initialValue={value}
        hasFeedback={false}
      >
        <Switch defaultChecked={value} />
      </Form.Item>
    ),
  },
  {
    title: 'Boost score',
    dataIndex: 'boost',
    key: 'boost',
    width: '20%',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_backend_classifications_${record.classification}_boost`}
        initialValue={value}
        hasFeedback={false}
      >
        <Slider
          min={0}
          max={5}
          marks={{
            0: 0,
            5: 5,
          }}
        />
      </Form.Item>
    ),
  },
];

const ClassificationsSettings = ({ productsClassifications, tenantConfig }) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const newDataSource = [];

    productsClassifications.forEach(([key, values]) => {
      const [third, fourth] = key.split('_').slice(3);
      const classification = !CLASSIFICATION_KEYS.includes(fourth)
        ? `${third}_${fourth}`
        : `${third}`;

      const formKey = key.split('_').pop();

      const classificationInSource = newDataSource.find(
        c => c.classification === classification,
      );

      if (classificationInSource) {
        classificationInSource[formKey] = tenantConfig[key] ?? values.default;
      } else {
        newDataSource.push({
          key: crypto.randomUUID(),
          classification,
          [formKey]: tenantConfig[key] ?? values.default,
        });
      }
    });

    setDataSource(newDataSource);
  }, []);

  if (!productsClassifications?.length) return null;

  return (
    <Table
      className="search-form__table"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      size="small"
      scroll={{
        y: 560,
      }}
    />
  );
};

export default ClassificationsSettings;
