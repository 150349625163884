import React from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const ElementActionEdit = props => {
  const handle = props.handle;

  return (
    <Tooltip title="Editar">
      <Button
        type="default"
        shape="circle"
        icon={<EditOutlined />}
        onClick={handle}
      />
    </Tooltip>
  );
};

export default ElementActionEdit;
