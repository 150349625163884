import React from 'react';
import { Form, Radio } from 'antd';

const RadioItem = ({ props }) => {
  const {
    defaultValue,
    hasFeedback,
    help,
    label,
    name,
    optionType,
    options,
    rules,
  } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
    >
      <Radio.Group options={options} optionType={optionType} />
    </Form.Item>
  );
};

export default RadioItem;
