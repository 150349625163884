import React, { useRef } from 'react';
import classNames from 'classnames';
import { VariableSizeGrid as Grid } from 'react-window';
import { Table } from 'antd';

const VirtualTable = props => {
  const { columns, scroll } = props;
  const gridRef = useRef();
  const tableWidth = 1000;

  const renderVirtualList = (rawData, { scrollbarSize, onScroll }) => {
    const totalHeight = rawData.length * 54;

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={columns.length}
        columnWidth={index => {
          const { width } = columns[index];
          return totalHeight > scroll.y && index === columns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 54}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const column = columns[columnIndex];
          const cellData = rawData[rowIndex][column.dataIndex];
          const renderFunction = column.render || (text => text);

          return (
            <div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === columns.length - 1,
              })}
              style={{
                ...style,
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                padding: '5px 10px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {renderFunction(cellData)}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <Table
      {...props}
      className="virtual-table"
      columns={columns}
      pagination={false}
      size="small"
      components={{
        body: renderVirtualList,
      }}
      style={{ width: '980px' }}
    />
  );
};

export default VirtualTable;
