import React from 'react';
import produce from 'immer';
import { Drawer, message } from 'antd';
// import { Form } from '@ant-design/compatible';
import PageEditFormComponent from './Form';
import { generateLog } from '../../../../../domain/Logs';

export default class PageEditComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      visible: false,
    };

    this.listeners = [];

    this.store = props.store;
    this.client_id = props.client_id;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  componentDidMount() {
    this.listeners.push(
      this.store.emitter.on(this.store.emitter.PAGE_EDIT_START, page => {
        this.setState({
          visible: true,
          page,
        });
      }),
    );
  }

  componentWillUnmount() {
    this.listeners.map(listener => this.store.emitter.off(listener));
  }

  savePage(updatedPage) {
    this.setState(
      produce(draft => {
        draft.loading = true;
      }),
    );

    const now = new Date().toISOString();
    const page = { ...this.state.page, ...updatedPage, updated_at: now };

    // @todo handle errors
    this.store.savePage(page).then(result => {
      console.log(result);
      this.setState({
        loading: false,
        visible: false,
      });

      const user_email = JSON.parse(
        localStorage.getItem('current_user_meta'),
      ).email;
      const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;

      const log = {
        app_location: 'Beon Studio',
        tenant_id: this.client_id,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        resource: `${updatedPage.context.name}-${updatedPage.props.label}`,
        action_type: 'Página editada',
      };

      generateLog(log).then(result => {
        if (result.status !== 201) {
          message.error('Falha ao salvar log');
        }
      });
    });
  }

  // getForm() {
  //     return Form.create()(PageEditFormComponent);
  // }

  onClose = event => {
    this.setState({
      visible: false,
    });
  };

  render() {
    if (!this.state.visible) return null;

    // const EditForm = this.getForm();

    return (
      <Drawer
        title={`${this.state.page.props.label} > Configurações`}
        width={650}
        visible={this.state.visible}
        onClose={this.onClose}
      >
        <PageEditFormComponent
          page={this.state.page}
          confirm={this.savePage.bind(this)}
        />
      </Drawer>
    );
  }
}
