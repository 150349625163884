import React from 'react';

// @todo should usa a HOC to wrap list structure

const RegionListComponent = props => {
    const { regions, addItem, empty = <></> } = props;

    if (!regions.length) return empty;

    return (
        <>
            {regions.map(region => props.children(region))}
            {addItem}
        </>
    );
};

export default RegionListComponent;
