import createConsumer from '../../rest/createConsumer';

const SEARCH_API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

const SearchApiConsumer = createConsumer(consumer => {
  const getSearchForm = tenantId =>
    new Promise(resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/config/describe/${tenantId}`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const getTenantConfig = tenantId =>
    new Promise(resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/config/${tenantId}`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const savePage = (page, tenantId) =>
    new Promise(async resolve => {
      consumer
        .patchSource(`${SEARCH_API_BASE_URL}/config/${tenantId}`, page)
        .then(results => {
          resolve(results);
        });
    });

  // SYNONYMS
  const getSynonymForm = () =>
    new Promise(async resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/synonym/describe`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const getSynonyms = tenantId =>
    new Promise(async resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/synonym/${tenantId}`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const createSynonyms = (tenantId, payload) =>
    new Promise(async resolve => {
      consumer
        .putSource(`${SEARCH_API_BASE_URL}/synonym/${tenantId}`, payload)
        .then(res => {
          resolve(res);
        });
    });

  const deleteSynonyms = (tenantId, synonymId) =>
    new Promise(async resolve => {
      consumer
        .deleteSource(`${SEARCH_API_BASE_URL}/synonym/${tenantId}/${synonymId}`)
        .then(res => {
          resolve(res);
        });
    });

  // INDEXATION
  const getIndexationStatus = tenantId =>
    new Promise(async resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/index/${tenantId}/status`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  const rebuildSearchIndex = tenantId =>
    new Promise(async resolve => {
      consumer
        .loadSource(`${SEARCH_API_BASE_URL}/index/${tenantId}/rebuild`, {
          cache: false,
        })
        .then(results => {
          resolve(results);
        });
    });

  // INTERFACE
  return Object.freeze({
    getSearchForm,
    getTenantConfig,
    savePage,
    getSynonymForm,
    createSynonyms,
    getSynonyms,
    deleteSynonyms,
    getIndexationStatus,
    rebuildSearchIndex,
    offline: false,
  });
});

export default SearchApiConsumer;
