import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export default class ElementEditCtaTabComponent extends React.Component {
    render() {
        return(
            <Title level={4}>Call to Action {this.props.element._id}</Title>
        )
    }
}
