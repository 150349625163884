export default [
    {
        name: 'homepage',
        label: 'Homepage',
        props: {
            editorOrder: 1,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'catalog',
        label: 'Catálogo',
        props: {
            editorOrder: 2,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'product',
        label: 'Produto',
        props: {
            editorOrder: 3,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'cart',
        label: 'Carrinho',
        props: {
            editorOrder: 4,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'success',
        label: 'Sucesso',
        props: {
            editorOrder: 5,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'notfound',
        label: '404',
        props: {
            editorOrder: 6,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'search',
        label: 'Busca',
        props: {
            editorOrder: 7,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'all',
        label: 'Todas as páginas',
        props: {
            editorOrder: 8,
            isNewPageAllowed: true,
        },
    },
    {
        name: 'other',
        label: 'Outras páginas',
        props: {
            editorOrder: 9,
            isNewPageAllowed: true,
        },
    },
];
