import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import { useTenant } from '../../../../../../../../../context/useTenant';
import FormFieldComponent from '../../../../../../../../Common/Form/Field';

const NOT_AVAILABLE_TEXT =
  'Sua operação ainda não está habilitada para exibir as variações de produto. Entre em contato com o suporte para checar a disponibilidade desta funcionalidade.';

const ShowVariations = ({ _id, elementEngineParams, form, param }) => {
  const { tenantData } = useTenant();

  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (tenantData?.profile?.flags?.product_variations) {
      setIsAvailable(true);
    }
  }, [tenantData]);

  return (
    <div className="engine_params_param" key={`${_id}:${param.name}`}>
      {isAvailable ? (
        <FormFieldComponent
          form={form}
          name={`engine.params.${param.name}`}
          label={param.label}
          help={param.help}
          settedValue={elementEngineParams[param.name]}
          defaultValue={param.default}
          required={param.required}
          frontendType={param.frontend_type || 'input'}
          dataType={param.type || 'string'}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <p style={{ margin: '0 1rem' }}>
            {param.label} {'(BETA)'}
          </p>
          <Tooltip title={NOT_AVAILABLE_TEXT}>
            <QuestionCircleTwoTone />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ShowVariations;
