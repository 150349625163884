import ElementSnippetTabComponent from '../Tabs/Snippet/Tab';
import ElementSnippetTabValidate from '../Tabs/Snippet/Validate';

export default [
    {
        key: `snippet`,
        title: `Código HTML`,
        component: ElementSnippetTabComponent,
        validate: ElementSnippetTabValidate,
    },
];
