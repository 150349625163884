// import uuid from 'uuid';
import staticOperators from './static/operators';
import staticVars from './static/vars';

const delay = 0;

export const getOperators = () =>
    new Promise(resolve => {
        setTimeout(() => resolve(staticOperators), delay);
    });

export const getVars = () =>
    new Promise(resolve => {
        setTimeout(() => resolve(staticVars), delay);
    });

export const getVarsGroups = () =>
    new Promise(resolve => {
        const groups = staticVars.reduce((final, variable) => {
            if (final.indexOf(variable.group) < 0) final.push(variable.group);
            return final;
        }, []);

        setTimeout(() => resolve(groups), delay);
    });

export const getVarsByGroup = group =>
    new Promise(resolve => {
        const vars = staticVars.filter(variable => variable.group === group);
        setTimeout(() => resolve(vars), delay);
    });
