import React, { useEffect, useState } from 'react';
import { Drawer, message } from 'antd';

import PageElementEditFormComponent from './Form';
import { generateLog } from '../../../../../../domain/Logs';

import '../styles/edit.css';

const PageElementEditComponent = props => {
  const { store, client_id } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [element, setElement] = useState({});
  const [listeners, setListeners] = useState([]);

  useEffect(() => {
    const listener = [
      store.emitter.on(store.emitter.ELEMENT_EDIT_START, element => {
        setIsVisible(true);
        setElement(JSON.parse(JSON.stringify(element)));
      }),
    ];

    setListeners(listener);

    return () => listeners.map(listener => store.emitter.off(listener));
  }, []);

  const closeDrawer = () => {
    setIsVisible(false);
    setElement({});
  };

  const saveElement = el => {
    const element = { ...el };
    const isNewElement = !element.created_at && !element.visible_at;
    const now = new Date().toISOString();

    if (isNewElement) {
      element.visible_at = now;
      element.props.isPreview = true;
    }

    element.updated_at = now;

    store.saveElement(element).then(async () => {
      closeDrawer();

      message.success(`Elemento salvo com sucesso`);

      const user_email = JSON.parse(
        localStorage.getItem('current_user_meta'),
      )?.email;
      const user_id = JSON.parse(
        localStorage.getItem('current_user_meta'),
      )?._id;
      const pageRegion = store?.getPage()?.context?.name;
      const pageName = store?.getPage()?.props?.label;

      let resource = '';

      if (!element?.label) {
        resource = `${pageRegion}-${pageName}-${element.props.label}`;
      } else {
        resource = `${pageRegion}-${pageName}-${element.label}`;
      }

      const log = {
        app_location: 'Beon Studio',
        tenant_id: client_id,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        resource,
        action_type: 'Elemento salvo',
      };

      generateLog(log);
    });
  };

  if (!isVisible || !Object.keys(element).length) return null;

  return (
    <Drawer
      title={`${store?.page?.context?.label} - ${element.props.label} -> Editando`}
      placement="right"
      closable={false}
      onClose={closeDrawer}
      maskClosable={false}
      visible={true}
      width={900}
      className="page-edit-wrapper"
    >
      <PageElementEditFormComponent
        page={store.getPage()}
        element={element}
        confirm={saveElement}
        cancel={closeDrawer}
        {...props}
      />
    </Drawer>
  );
};

export default PageElementEditComponent;
