import React from 'react';
import { Form, Slider } from 'antd';

const SliderItem = ({ props }) => {
  const {
    className,
    defaultFormatter,
    defaultValue,
    hasFeedback,
    help,
    label,
    min,
    max,
    name,
    rules,
    step,
  } = props;

  let formatter = value => `${Number(value)}%`;
  if (defaultFormatter === 'seconds') {
    formatter = value => `${Number(value)} segundos`;
  } else if (defaultFormatter === 'none') {
    formatter = value => `${Number(value)}`;
  }

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
    >
      <Slider
        tooltip={{ formatter }}
        className="customSlider"
        marks={{
          [min]: formatter(min),
          [max]: formatter(max),
        }}
        min={min}
        max={max}
        step={step}
      />
    </Form.Item>
  );
};

export default SliderItem;
