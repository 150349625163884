import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const MerchandisingRuleDelete = ({ onDelete }) => {
  return (
    <Popconfirm
      title="Tem certeza que deseja excluir este grupo de regras?"
      okText="Excluir"
      cancelText="Cancelar"
      onConfirm={onDelete}
    >
      <Button type="danger" shape="circle" icon={<DeleteOutlined />} />
    </Popconfirm>
  );
};

export default MerchandisingRuleDelete;
