import React from 'react';
import { Tag } from 'antd';

const IN_PROGRESS = 'IN_PROGRESS';
const STEADY = 'STEADY';
const FAILED = 'FAILED';
const MIGRATING = 'MIGRATING';
const PAUSED = 'PAUSED';

const IndexationStatusTag = ({ index, noIndex }) => {
  if (noIndex) {
    return <Tag color={'gray'}>Sem informação de indexação</Tag>;
  }

  const { label, status } = index;

  let tagColor;
  switch (status) {
    case IN_PROGRESS:
      tagColor = 'blue';
      break;

    case STEADY:
      tagColor = 'green';
      break;

    case FAILED:
      tagColor = 'red';
      break;

    case MIGRATING:
      tagColor = 'orange';
      break;

    case PAUSED:
      tagColor = 'gray';
      break;

    default:
      tagColor = 'gray';
      break;
  }

  return (
    <Tag color={tagColor} style={{ marginTop: '2px', fontSize: '10px' }}>
      {label}
    </Tag>
  );
};

export default IndexationStatusTag;
