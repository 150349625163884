import ElementSubscribeFormTabComponent from '../Tabs/Subscribe/Form';
import ElementSubscribeSubscribersTabComponent from '../Tabs/Subscribe/Subscribers';

export default [
    {
        key: `subscribe:form`,
        title: `Formulário`,
        component: ElementSubscribeFormTabComponent
    },
    {
        key: `subscribe:subscribers`,
        title: `Assinaturas`,
        component: ElementSubscribeSubscribersTabComponent
    }
];
