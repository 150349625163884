import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popconfirm, message } from 'antd';
import SearchApiConsumer from '../../../../core/search/api';

const SearchIndexRebuildButton = ({ getIndexationStatus }) => {
  const { client_id } = useParams();

  const rebuildSearchIndex = async () => {
    try {
      await SearchApiConsumer.rebuildSearchIndex(client_id);
      getIndexationStatus();
      message.success(
        'Sucesso! Os indíces de busca estão sendo reconstruídos.',
      );
    } catch (e) {
      message.error('Algo deu errado. Por favor, tente novamente.');
    }
  };

  return (
    <Popconfirm
      title={
        <p>
          A reconstrução do índice pode deixar algumas aplicações do Beonly
          <br></br>
          indisponíveis por alguns minutos no seu site. Deseja prosseguir?
        </p>
      }
      onConfirm={rebuildSearchIndex}
      okText="Confirmar"
      cancelText="Cancelar"
    >
      <Button type="primary" style={{ margin: '10px', justifySelf: 'center' }}>
        Reconstruir índice de busca
      </Button>
    </Popconfirm>
  );
};

export default SearchIndexRebuildButton;
