import ElementEditCtaTabComponent from '../Tabs/Cta/Tab';
import ElementEditCtaHandler from '../Tabs/Cta/Handler';

export default [
    {
        key: `cta`,
        title: `Call-to-action`,
        component: ElementEditCtaTabComponent,
        handler: ElementEditCtaHandler
    }
];
