export const PRESETS_APPLIED_TEXT =
  'Aqui irá um texto a ser determinado, dizendo que as configurações foram setadas e que agora basta completar/editar os itens desejados para por a vitrine no ar.';

export const PRESETS = [
  // HOMEPAGE
  {
    _id: crypto.randomUUID(),
    name: 'preset_bem-vindo-de-volta',
    title: 'Bem-vindo de volta',
    label: 'Bem-vindo de volta',
    description: 'Vitrine focada em clientes que retornam à página.',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Bem-vindo de volta',
        order: 1,
      },
      engine: {
        _id: '4d854a1b-79db-4edb-ae44-44a73596c370',
        params: {
          limit: 16,
          tree_type: ['category'],
          personalization_type: 'priority',
          engine: 'ca3b97f6-0519-4770-8747-6a6d00324e51',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Bem-vindo de volta!',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homepage_ofertas_retornantes',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'visitor_returning',
          operator: 'truthy',
          expected: 'true',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_produtos-imperdiveis',
    title: 'Produtos imperdíveis',
    label: 'Produtos imperdíveis',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Produtos imperdíveis',
      },
      engine: {
        _id: 'ea1cc50c-f284-4327-b1db-f51f55779f00',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'include',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Produtos imperdíveis',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homepage_imperdiveis_campanha',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_ofertas-baseadas-no-seu-historico',
    title: 'Descubra mais: ofertas baseadas no seu histórico',
    label: 'Descubra mais: ofertas baseadas no seu histórico',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Descubra mais: ofertas baseadas no seu histórico',
      },
      engine: {
        _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
        params: {
          limit: 16,
          tree_type: ['category'],
          engine: 'ca3b97f6-0519-4770-8747-6a6d00324e51',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '03002b07-f30e-4bf6-ab33-e91dcf4b4941',
        params: {
          title: 'Descubra mais: ',
          subtitle: 'ofertas baseadas no seu histórico',
          target_label: 'Veja mais',
          image_hover: 'sim',
          pivot_label: 'Você viu esse:',
          sliders_label: 'veja também:',
          event_label: 'homepage_vt_ofertas',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          navigation_type: 'button',
          navigation_button_label: 'Trocar',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2,"b": 0},"1024":{"i": 3,"s": 1,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
          free_shipping_badge_price: '0',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_visited_products',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_continue-navegando',
    title: 'Continue navegando: mais ofertas pra você curtir',
    label: 'Continue navegando: mais ofertas pra você curtir',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Continue navegando: mais ofertas pra você curtir',
      },
      engine: {
        _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
        params: {
          limit: 16,
          tree_type: ['category'],
          engine: '202beafa-966f-4cd1-9f7e-fe5713a76033',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '03002b07-f30e-4bf6-ab33-e91dcf4b4941',
        params: {
          title: 'Continue navegando:',
          subtitle: 'Mais ofertas pra você curtir',
          target_label: 'Veja mais',
          image_hover: 'sim',
          pivot_label: 'Você viu esse:',
          sliders_label: 'veja também:',
          event_label: 'homepage_vt_ofertas',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          navigation_type: 'button',
          navigation_button_label: 'Trocar',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2,"b": 0},"1024":{"i": 3,"s": 1,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
          free_shipping_badge_price: '0',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_visited_products',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_selecao-especial-de-produtos-da-categoria',
    title: 'Seleção especial de produtos da categoria',
    label: 'Seleção especial de produtos da categoria',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Seleção especial de produtos da categoria',
      },
      engine: {
        _id: 'ea1cc50c-f284-4327-b1db-f51f55779f00',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Seleção especial de produtos da categoria',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapage_selecao_categoria',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_sugestoes-perfeitas-para-voce',
    title: 'Sugestões perfeitas para você: produtos que você procura',
    label: 'Sugestões perfeitas para você: produtos que você procura',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Sugestões perfeitas para você: produtos que você procura',
      },
      engine: {
        _id: '4d854a1b-79db-4edb-ae44-44a73596c370',
        params: {
          limit: 16,
          tree_type: ['category'],
          personalization_type: 'priority',
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Sugestões perfeitas para você: ',
          subtitle: 'produtos que você procura',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homepage_sugestoes_interesses',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_visited_products',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_best-sellers',
    title: 'Best-sellers',
    label: 'Best-sellers',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Best-sellers',
        isHidden: false,
        order: 1,
      },
      engine: {
        _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
        params: {
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
          limit: 16,
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Best-sellers',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapage_bestsellers_novosusuarios',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'visitor_new',
          operator: 'truthy',
          expected: 'true',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_new-in',
    title: 'New in',
    label: 'New in',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'New in',
      },
      engine: {
        _id: '2857b1a0-c623-422e-9d4c-0d3f42466d46',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'New in',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapegae_newin_novosusuarios',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      region_id: '305afa7f-0941-4612-acff-07f44e588de6',
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'visitor_new',
          operator: 'truthy',
          expected: 'true',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_sale',
    title: 'Sale!',
    label: 'Sale!',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Sale!',
      },
      engine: {
        _id: 'ca3b97f6-0519-4770-8747-6a6d00324e51',
        params: {
          limit: 16,
          sort: 'discount_amount',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Sale!',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapegae_sale_novosusuarios',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'visitor_new',
          operator: 'truthy',
          expected: 'true',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_nao-perca-de-vista',
    title: 'Não perca de vista: os seus favoritos!',
    label: 'Não perca de vista: os seus favoritos!',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Não perca de vista: os seus favoritos!',
      },
      engine: {
        _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
        params: {
          limit: 16,
          order_by: 'relevance',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Não perca de vista: os seus favoritos!',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homepage_ultimos_vistos',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_visited_products',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_seus-favoritos-em-promocao',
    title: 'Seus favoritos em promoção: não deixe passar!',
    label: 'Seus favoritos em promoção: não deixe passar!',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Seus favoritos em promoção: não deixe passar!',
      },
      engine: {
        _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
        params: {
          limit: 16,
          order_by: 'time',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'include',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Seus favoritos em promoção:',
          subtitle: 'não deixe passar! ',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapege_historico_promocao',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_visited_products',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_new-in-separados-para-voce',
    title: 'New-in separados para você',
    label: 'New-in separados para você',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Homepage',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'New-in separados para você',
      },
      engine: {
        _id: '4d854a1b-79db-4edb-ae44-44a73596c370',
        params: {
          limit: 16,
          tree_type: ['category'],
          personalization_type: 'restrict',
          engine: '2857b1a0-c623-422e-9d4c-0d3f42466d46',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'New-in separados pra você',
          subtitle: '',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'homapege_historico_lancamentos',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'visitor_returning',
          operator: 'truthy',
          expected: 'true',
        },
      ],
    },
  },

  // CATALOGO
  {
    _id: crypto.randomUUID(),
    name: 'preset_destaques-da-categoria',
    title: 'Destaques da categoria',
    label: 'Destaques da categoria',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Catálogo',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Destaques da categoria ',
      },
      engine: {
        _id: '66361f52-1824-4b0b-8466-cb906487c981',
        params: {
          limit: 16,
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '438df6a4-fa33-486c-b3a7-b5c65a241b25',
        params: {
          layout: 'compact',
          width: 'container',
          container_class: '',
          container_style: '',
          event_label: 'catalog_highlights',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          image_or_text: 'cta_use_text',
          headline: 'Destaques da categoria  ',
          lead: 'nossos best sellers separados especialmente para você',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 1,"s": 1},"768":{"i": 1,"s": 1,"b": 0}}}',
          free_shipping_price: '0',
        },
      },
      _id: crypto.randomUUID(),
      region_id: '023b41f3-0b1d-4029-9523-031669e54e74',
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_produtos-que-voce-vai-amar',
    title: 'Produtos que você vai amar',
    label: 'Produtos que você vai amar',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Catálogo',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Produtos que você vai amar',
      },
      engine: {
        _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
        params: {
          limit: 16,
          tree_type: ['category'],
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '03002b07-f30e-4bf6-ab33-e91dcf4b4941',
        params: {
          title: 'Produtos que você vai amar',
          target_label: 'Veja mais',
          image_hover: 'sim',
          pivot_label: 'Você viu esse:',
          sliders_label: 'veja também:',
          event_label: 'catalog_vejamais',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          navigation_type: 'button',
          navigation_button_label: 'Trocar',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2,"b": 0},"1024":{"i": 3,"s": 1,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
          free_shipping_badge_price: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_separamos-os-seus-favoritos',
    title: 'Separamos os seus favoritos',
    label: 'Separamos os seus favoritos',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Catálogo',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Separamos os seus favoritos',
      },
      engine: {
        _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
        params: {
          limit: 16,
          order_by: 'relevance',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Separamos os seus favoritos',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'catalog_seusfavoritos',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
    },
  },

  // PRODUTO
  {
    _id: crypto.randomUUID(),
    name: 'preset_veja-tambem-departamento-complementares',
    title: 'Veja também - departamento complementares',
    label: 'Veja também - departamento complementares',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Veja também - departamento complementares',
      },
      engine: {
        _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Veja também:',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'product_vt_departamento',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
      hooks: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_compre-junto-itens-complementares',
    title: 'Compre Junto - Itens complementares',
    label: 'Compre Junto - Itens complementares',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Compre Junto - Itens complementares',
        isHidden: false,
        order: 1,
      },
      engine: {
        _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Compre junto:',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'product_comprejunto',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
      hooks: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_promocoes-relacionadas-produtos-com-descontos',
    title: 'Promoções relacionadas: produtos com descontos',
    label: 'Promoções relacionadas: produtos com descontos',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Promoções relacionadas: produtos com descontos',
      },
      engine: {
        _id: '3fa7fc89-8c36-454c-b721-d98726291805',
        params: {
          limit: 16,
          tree_type: ['department'],
          price_condition: 'none',
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Promoções relacionadas',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'product_promocoes',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
      hooks: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_aproveite-a-promocao-enquanto-ainda-da-tempo',
    title: 'Aproveite a promoção enquanto ainda dá tempo',
    label: 'Aproveite a promoção enquanto ainda dá tempo',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Aproveite a promoção enquanto ainda dá tempo',
      },
      engine: {
        _id: '3fa7fc89-8c36-454c-b721-d98726291805',
        params: {
          limit: 16,
          tree_type: ['department'],
          price_condition: 'lte',
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Aproveite a promoção enquanto ainda dá tempo',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'product_relacionados_promocoes',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [
        {
          _id: crypto.randomUUID(),
          variable: 'navigation_product_ispromo',
          operator: 'truthy',
          expected: 'true',
        },
      ],

      hooks: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_seu-historico-de-navegacao',
    title: 'Seu histórico de navegação',
    label: 'Seu histórico de navegação',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Seu histórico de navegação',
      },
      engine: {
        _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
        params: {
          limit: 16,
          order_by: 'relevance',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Vistos recentemente:',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'product_vistos_recentemente',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
      hooks: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_continue-navegando-sugestoes-baseadas',
    title: 'Continue navegando: sugestões baseadas no que você viu',
    label: 'Continue navegando: sugestões baseadas no que você viu',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Produto',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Continue navegando: sugestões baseadas no que você viu',
      },
      engine: {
        _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
        params: {
          limit: 16,
          tree_type: ['category'],
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '03002b07-f30e-4bf6-ab33-e91dcf4b4941',
        params: {
          title: 'Continue navegando: ',
          subtitle: 'sugestões baseadas no que você viu',
          target_label: 'Veja mais',
          image_hover: 'sim',
          pivot_label: 'Você viu esse:',
          sliders_label: 'veja também:',
          event_label: 'product_continue_navegando',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          navigation_type: 'button',
          navigation_button_label: 'Trocar',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2,"b": 0},"1024":{"i": 3,"s": 1,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
          free_shipping_badge_price: '0',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      segmentation: [],
      hooks: [],
    },
  },

  // CARRINHO
  {
    _id: crypto.randomUUID(),
    name: 'preset_aproveite-o-frete-e-leve-junto',
    title: 'Aproveite o frete e leve junto',
    label: 'Aproveite o frete e leve junto',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Carrinho',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Aproveite o frete e leve junto',
        order: 1,
      },
      engine: {
        _id: 'ca3b97f6-0519-4770-8747-6a6d00324e51',
        params: {
          limit: 16,
          sort: 'discount_amount',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: 'de540b3f-f6df-41e3-906b-b91234f88458',
        params: {
          title: 'Aproveite o frete e leve junto',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'cart_frete',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 1,"s": 1},"768":{"i": 3,"s": 3,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_itens-que-voce-tambem-vai-precisar',
    title: 'Itens que você também vai precisar',
    label: 'Itens que você também vai precisar',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Carrinho',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Itens que você também vai precisar',
      },
      engine: {
        _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
        params: {
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
          limit: 16,
        },
      },
      template: {
        _id: 'de540b3f-f6df-41e3-906b-b91234f88458',
        params: {
          title: 'Itens que você também vai precisar',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'cart_frete',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 1,"s": 1},"768":{"i": 3,"s": 3,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    name: 'preset_nao-esqueca-deles-carrinho',
    title: 'Ei, não esqueça deles! - Histórico do usuário',
    label: 'Ei, não esqueça deles! - Histórico do usuário',
    description: 'Descrição do preset',
    elementType: 'showcase',

    group: 'Carrinho',
    img: '',

    element: {
      keysToBeFilled: [],

      props: {
        label: 'Ei, não esqueça deles! - Histórico do usuário',
      },
      engine: {
        _id: 'b7907433-3cc7-41e5-8258-b024b6c92aef',
        params: {
          limit: 16,
          order_by: 'relevance',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },
      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Ei, não esqueça deles!',
          target_label: 'Veja mais',
          image_hover: 'sim',
          event_label: 'cart_naoesqueca',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },
      _id: crypto.randomUUID(),
      type: 'showcase',
      doNotRepeatFrom: [],
      hooks: [],
      segmentation: [],
    },
  },
];
