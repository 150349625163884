/* eslint-disable no-loop-func */
function unflatten(data){
  const result = {}
  for (var i in data) {
    var keys = i.split('.')
    keys.reduce(function(r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1]))
       ? (keys.length - 1 === j 
        ? data[i] : {}) 
        : [])
    }, result)
  }
  return result
};

export default unflatten