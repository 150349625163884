import React from 'react';
import produce from 'immer';
import moment from 'moment';
import {
  Select,
  Input,
  Button,
  DatePicker,
  TimePicker,
  TreeSelect,
  Empty,
} from 'antd';
import * as segmentationApi from '../../../core/segmentation/api';
import debounce from 'lodash.debounce';

import AuthStore from '../../../stores/Auth';
import CatalogApiConsumer from '../../../core/catalog/api';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class SegmentationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      variable: props.variable,
      operators: [],
      trees: [],
      definition: props.definition || {
        variable: props.variable.name,
        operator: null,
        expected: null,
      },
      options: [],
    };

    this.inputs = {};
  }

  async componentDidMount() {
    // @todo fix this
    await this.loadOperatorsList();
    await this.loadCatalogTreesTypes();

    if (this.inputs['operator']) this.inputs['operator'].focus();
  }

  async loadOperatorsList() {
    segmentationApi.getOperators().then(operators => {
      const { variable } = this.state;

      const allowAll = variable.operators.find(operator => operator === 'all');

      if (allowAll) {
        return this.setState({ operators, loading: false });
      }

      const allowed = operators.filter(
        operator => variable.operators.indexOf(operator.name) > -1,
      );

      return this.setState({ operators: allowed, loading: false });
    });
  }

  async loadCatalogTreesTypes() {
    const { scope } = AuthStore.getScope();

    let trees = await CatalogApiConsumer.getTreesTypes(scope);
    trees = trees.map(({ _id }) => _id).sort((a, b) => a.localeCompare(b));

    this.setState({ trees });
  }

  getOperator(name) {
    return this.state.operators.find(operator => operator.name === name);
  }

  saveInputRef = (field, element) => {
    this.inputs[field] = element;
  };

  // persist input values to state
  handleInputChange = event => {
    const value = event.target.value;

    this.setState(
      produce(draft => {
        draft.definition.expected = value;
      }),
    );
  };

  handleDateTimeChange = (date, dateString) => {
    const value = date.valueOf();

    this.setState(
      produce(draft => {
        draft.definition.expected = value;
      }),
    );
  };

  handleSelectChange = value => {
    const expected = Array.isArray(value) ? value.join(',') : value;

    this.setState(
      produce(draft => {
        draft.definition.expected = expected;
      }),
    );
  };

  // persist input values to state
  handleOperatorChange = selected => {
    const operator = this.getOperator(selected);

    this.setState(
      produce(draft => {
        draft.definition.operator = selected;
        if (operator.type === 'boolean') draft.definition.expected = true;
      }),
    );

    if (this.inputs['expected']) this.inputs['expected'].focus();
  };

  // put new definition into list
  handleConfirm = event => {
    // a good place to handle value formats?
    console.log(this.state.definition, this.props.confirm);
    this.props.confirm(this.state.definition);
  };

  handleCancel = () => {
    this.props.cancel();
  };

  getCatalogTreesOptions = async ({ term }) => {
    const { scope } = AuthStore.getScope();
    const trees = await CatalogApiConsumer.searchTrees({
      clientId: scope,
      term,
    });

    return trees
      .map(tree => ({
        label: tree.name,
        kind: tree.kind,
        value: tree.tree_key,
      }))
      .sort((a, b) => a[`label`].localeCompare(b[`label`]));
  };

  debounceFetcher = (fetcher, timeout = 500) => {
    const fetchOptions = async value => {
      this.setState({
        loading: true,
        options: [],
      });

      const opts = await fetcher(value);

      const treeOptions = [];

      for (let i = 0; i < opts.length; i += 1) {
        const { label, kind, value: treeValue } = opts[i];
        const alreadyHaveKind = treeOptions.find(tree => tree.value === kind);

        let childrenTitle = label;
        if (kind === 'cluster') {
          const clusterId = Number(treeValue.split(':')[1]);

          if (clusterId) {
            childrenTitle = `${label} (#${clusterId})`;
          }
        }

        if (!alreadyHaveKind) {
          treeOptions.push({
            value: kind,
            title: kind?.toUpperCase(),
            children: [
              {
                value: treeValue,
                title: childrenTitle,
              },
            ],
          });
        } else
          alreadyHaveKind.children = [
            ...alreadyHaveKind.children,
            { value: treeValue, title: childrenTitle },
          ];
      }

      this.setState({
        options: treeOptions.sort((a, b) =>
          a[`title`].localeCompare(b[`title`]),
        ),
        loading: false,
      });
    };

    return debounce(fetchOptions, timeout);
  };

  debounceOnSearch = () => {
    return this.debounceFetcher(
      async value =>
        await this.getCatalogTreesOptions({
          term: value,
        }),
    );
  };

  getInputComponent() {
    const { variable } = this.state;
    let component;
    const operator = this.getOperator(this.state.definition.operator);

    const componentProps = {
      ref: input => this.saveInputRef('expected', input),
      size: 'small',
      style: { width: 220 },
    };

    // if (operator.list )

    // variable informs the allowed options, we will give to user a select.
    // selected operator must accept options too
    if (operator && operator.options && variable.allowed_options) {
      if (this.state.definition.expected)
        componentProps.defaultValue = this.state.definition.expected.split(',');

      componentProps.tokenSeparators = [','];
      componentProps.onChange = this.handleSelectChange;
      componentProps.onPressEnter = this.handleConfirm;

      // operator that must be responsible for this
      if (operator.multi) componentProps.mode = 'multiple';

      // dynamic lists
      if (
        typeof variable.allowed_options === 'string' &&
        variable.allowed_options === 'catalog:trees:types'
      ) {
        let dynamicOptions = [];
        dynamicOptions = this.state.trees.map(kind => [kind, kind]);
        variable.allowed_options = dynamicOptions;
      }

      if (
        typeof variable.allowed_options === 'string' &&
        variable.allowed_options === 'catalog:trees:nodes'
      ) {
        component = (
          <TreeSelect
            allowClear
            showSearch
            style={{
              width: '100%',
            }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{
              maxHeight: 700,
              overflow: 'auto',
              width: 425,
            }}
            placeholder="Pesquisar"
            multiple
            notFoundContent={
              this.state.loading ? (
                <Empty
                  imageStyle={{
                    height: 40,
                  }}
                  image={<LoadingOutlined style={{ fontSize: '40px' }} />}
                  description="Carregando opções..."
                />
              ) : null
            }
            onSearch={this.debounceOnSearch()}
            treeData={this.state.options}
            treeLine={{ treeLine: true, showLeafIcon: false }}
            filterTreeNode={false}
            {...componentProps}
          />
        );
      } else {
        component = (
          <Select {...componentProps}>
            {variable.allowed_options.map(option => (
              <Option key={option[0]} value={option[0]}>
                {option[1]}
              </Option>
            ))}
          </Select>
        );
      }
    } else if (operator && operator.type === 'boolean') {
      component = <></>;
    } else if (operator) {
      componentProps.value = this.state.definition.expected;
      componentProps.onChange = this.handleInputChange;
      componentProps.onPressEnter = this.handleConfirm;

      componentProps.type =
        !operator || operator.type === 'any' ? 'text' : operator.type;

      if (
        variable.input_type === 'date' ||
        variable.input_type === 'datetime' ||
        variable.input_type === 'time'
      ) {
        if (componentProps.value) {
          const timestamp = Number(componentProps.value);
          componentProps.value =
            variable.input_type === 'time'
              ? moment(timestamp, 'HH:mm')
              : moment(timestamp);

          componentProps.initialValue = componentProps.value;
        }

        componentProps.onChange = this.handleDateTimeChange;
      }

      switch (variable.input_type) {
        case 'date':
          component = (
            <DatePicker
              format={'DD/MM/YYYY'}
              defaultValue={moment()}
              {...componentProps}
            />
          );
          break;
        case 'datetime':
          component = (
            <DatePicker
              format={'DD/MM/YYYY HH:mm'}
              showTime
              {...componentProps}
            />
          );
          break;
        case 'time':
          component = (
            <TimePicker
              format={'HH:mm'}
              defaultValue={moment('12:00', 'HH:mm')}
              {...componentProps}
            />
          );
          break;

        default:
          component = <Input {...componentProps} />;
          break;
      }
    } else {
      component = <></>;
    }

    return component;
  }

  render() {
    return (
      <div className="segmentation-bullet-form">
        <Input.Group compact>
          <Select
            ref={input => this.saveInputRef('operator', input)}
            defaultValue={this.state.definition.operator}
            style={{ width: 120 }}
            size="small"
            onChange={this.handleOperatorChange}
          >
            {this.state.operators.map(operator => (
              <Option key={operator.name} value={operator.name}>
                {operator.label}
              </Option>
            ))}
          </Select>

          {this.getInputComponent()}

          <Button.Group>
            <Button
              size="small"
              shape="circle"
              type="primary"
              onClick={this.handleConfirm}
              icon={<CheckOutlined />}
            />
            <Button
              size="small"
              shape="circle"
              type="default"
              onClick={this.handleCancel}
              icon={<CloseOutlined />}
            />
          </Button.Group>
        </Input.Group>
      </div>
    );
  }
}
