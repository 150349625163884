import React, { useEffect, useState } from 'react';

import { Col, Row, Tag } from 'antd';
import { ArrowRightOutlined, SwapOutlined } from '@ant-design/icons';

import SynonymActionEdit from './Actions/Edit';
import SynonymActionDelete from './Actions/Delete';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
const EQUIVALENT = 'equivalent';
const EQUIVALENTE = 'Equivalente';
const EXPLICIT = 'explicit';
const EXPLICITO = 'Explícito';

const SynonymComponent = ({ deleteSynonym, editSynonym, synonym }) => {
  const { _id, mappingType, input, synonyms, createdAt, updatedAt } = synonym;
  const [groupType, setGroupType] = useState('');

  const defineMappingType = () => {
    return setGroupType(mappingType === EQUIVALENT ? EQUIVALENTE : EXPLICITO);
  };

  useEffect(() => {
    defineMappingType();
  }, []);

  return (
    <Row
      className="page-element-row"
      style={{ alignItems: 'center', backgroundColor: 'white' }}
    >
      <Col span={3}>
        <Tag color={groupType === EQUIVALENTE ? 'geekblue' : 'purple'}>
          {groupType}
        </Tag>
      </Col>

      {mappingType === EXPLICIT && (
        <>
          <Col span={3}>{input?.toString().toUpperCase()}</Col>
          <Col span={3}>
            <ArrowRightOutlined />
          </Col>
        </>
      )}

      <Col span={mappingType === EXPLICIT ? 9 : 15}>
        {synonyms.map((s, i) => (
          <span key={`synonym-${s}-${i}`}>
            {s.toString().toUpperCase()}
            {i < synonyms.length - 1 &&
              (mappingType === EQUIVALENT ? (
                <span style={{ margin: '0 7px' }}>
                  <SwapOutlined />
                </span>
              ) : (
                ', '
              ))}
          </span>
        ))}
      </Col>

      <Col span={4}>{moment(updatedAt ?? createdAt).format(DATE_FORMAT)}</Col>

      <Col span={1}>
        <SynonymActionEdit onEdit={() => editSynonym(synonym)} />
      </Col>

      <Col span={1}>
        <SynonymActionDelete onDelete={() => deleteSynonym(_id)} />
      </Col>
    </Row>
  );
};

export default SynonymComponent;
