import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from '../../context/useUser';

import AuthStore from '../../stores/Auth';

const LogoutComponent = () => {
  const [logoutSuccess, setLogoutSuccess] = useState(undefined);
  const { setStep, setAuthSuccess, setUser } = useUser();

  const handleLogout = () => {
    AuthStore.logout().then(result => {
      if (result instanceof Error) {
        setLogoutSuccess(false);
      } else {
        setStep('login');
        setUser(null);
        setAuthSuccess(undefined);
        setLogoutSuccess(true);
      }
    });
  };

  useEffect(() => handleLogout(), []);

  return <>{logoutSuccess && <Redirect to="/" />}</>;
};

export default LogoutComponent;
