import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Popconfirm } from 'antd';

import FormFieldComponent from '../../../Form/Field';

const SynonymsForm = ({
  isAdding,
  onCancel,
  onSave,
  settedValues,
  synonymForm,
}) => {
  const { _id } = settedValues;
  const { properties, required } = synonymForm;

  const [form, setForm] = useState({});
  const [currentMappingType, setCurrentMappingType] = useState('');

  const setFullForm = () => {
    const { mappingType: settedMappingType } = settedValues;
    const { mappingType } = properties;

    mappingType.onChange = v => setCurrentMappingType(v);

    const newForm = {
      ...properties,
    };

    if (settedMappingType) setCurrentMappingType(settedMappingType);

    setForm(newForm);
  };

  useEffect(() => {
    setFullForm();
  }, []);

  return (
    <Drawer
      title={`Sinônimos -> ${isAdding ? 'Adicionando novo grupo' : 'Editando'}`}
      placement="right"
      closable={false}
      maskClosable={false}
      visible={true}
      width={900}
      className="page-edit-wrapper"
    >
      <Form
        onFinish={onSave}
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={true}
        size="large"
        style={{
          width: '90%',
          margin: '10px 50px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form.Item style={{ alignSelf: 'flex-end' }}>
          <Popconfirm
            title="Todas as informações não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button htmlType="button">Cancelar</Button>
          </Popconfirm>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '7px' }}
          >
            Salvar
          </Button>
        </Form.Item>

        {_id && (
          <Form.Item
            initialValue={_id}
            name="_id"
            style={{ display: 'none' }}
          />
        )}

        {Object.entries(form).map(([key, values]) => {
          const {
            allow_multi,
            is_system,
            label,
            helper,
            type,
            inputType,
            options,
            description,
            onChange,
          } = values;

          return (
            !is_system &&
            (key === 'input' && currentMappingType !== 'explicit' ? (
              ''
            ) : (
              <FormFieldComponent
                key={key}
                allowMultiTags={allow_multi}
                dataType={type}
                description={description}
                frontendType={inputType}
                helper={helper}
                label={label}
                name={key}
                onChange={onChange}
                options={options}
                required={required.includes(key)}
                settedValue={settedValues[key]}
                help={helper}
              />
            ))
          );
        })}
      </Form>
    </Drawer>
  );
};

export default SynonymsForm;
