import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthStore from '../../../stores/Auth';

export class AuthenticateBeforeRender extends React.Component {
  state = {
    isAuthenticated: undefined,
  };

  componentDidMount() {
    // shoulf perform local authentication befora rendering
    // like token refresh
    AuthStore.refreshAuth().then(isAuthenticated => {
      console.log('autenticado?', isAuthenticated);
      this.setState({ isAuthenticated });
    });
  }

  render() {
    if (this.state.isAuthenticated === false) return <Redirect to="/login" />;

    return this.state.isAuthenticated === true ? this.props.render() : null;
  }
}
