import { setResponseCache, getResponseCache } from './cache';

export const loadSource = (url, options = { cache: true }) =>
    new Promise(resolve => {
        if (options.cache) {
            const cached = getResponseCache(url);
            if (cached) {
                return resolve(cached);
            }
        }

        fetch(url)
            .then(response => response.json())
            .then(response => {
                if (options.cache) setResponseCache(url, response);
                return response;
            })
            .then(response => resolve(response))
            .catch(err => {
                console.log(err);
                resolve(null);
            });
    });
