import React from 'react';
import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import './styles/actions.css';

const ElementActionVisibility = ({ element, handle }) => {
  const { isHidden } = element.props || {};

  return (
    <Tooltip title="Ativar/Desativar visibilidade">
      <Button
        className={`actions_visibility-icon ${isHidden ? 'hidden' : 'visible'}`}
        type="default"
        shape="circle"
        icon={<EyeOutlined />}
        onClick={handle}
      />
    </Tooltip>
  );
};

export default ElementActionVisibility;
