import { clearResponseCache } from './cache';

export const putSource = (url, object) =>
  new Promise(resolve => {
    const headers = new Headers();
    headers.set('Content-type', 'application/json');

    fetch(url, { method: 'POST', body: JSON.stringify(object), headers })
      // .then(response => response.json())
      .then(async response => {
        clearResponseCache(url);

        const res = {
          body: null,
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          ok: response.ok,
        };

        try {
          res.body = await response.json();
        } catch (e) {
          console.log(`failed to parse undefined response`, response);
        }

        resolve(res);
      })
      .catch(err => {
        console.log(err);
        resolve(new Error(err));
      });
  });
