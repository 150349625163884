import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const AdjustmentsSettings = ({ adjustments, form, tenantConfig }) => {
  if (!adjustments?.length) return <></>;

  return (
    <>
      <Title level={4} style={{ marginTop: '35px' }}>
        Ajustes de relevância
      </Title>

      {adjustments.length > 0 &&
        adjustments.map(([key, value], i) => {
          return (
            <React.Fragment key={`fragment_${key}_${i}`}>
              <FormFieldComponent
                form={form}
                name={key}
                key={key}
                {...value}
                defaultValue={value.default}
                dataType={value.type}
                defaultFormatter={'none'}
                hasFeedback={false}
                settedValue={tenantConfig[key]}
                className="search-form__grid__adjustments"
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default AdjustmentsSettings;
