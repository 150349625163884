import axios from 'axios';

const logsAPIUrl = process.env.REACT_APP_API_LOGS;

const logsAPI = axios.create(
  {
    baseURL: logsAPIUrl,
  },
);

export const generateLog = async log => {
  try {
    const result = await logsAPI.post('/logs', log);

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export default logsAPI;
