const FieldsTypes = type => {
    return types[type] || undefined;
};

const types = {
    string: {
        label: 'texto'
    },
    number: {
        label: 'número'
    },
    boolean: {
        label: 'falso ou verdadeiro'
    },
    enum: {
        label: 'valor da lista'
    },
    url: {
        label: 'URL'
    },
    email: {
        label: 'email'
    }
};

export default FieldsTypes;