import React from 'react';
import RecommendationEngineSelectComponent from './EngineSelect';
import RecommendationEngineParamsComponent from './EngineParams';
import * as recommendationApi from '../../../../../../../../core/recommendation/api';

export default class ElementRecommendationTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            element: props.element,
            recommendation: null,
        };
    }

    componentDidMount() {
        if (this.state.element.engine && this.state.element.engine._id) {
            this.loadRecommendation(this.state.element.engine._id);
        }
    }

    loadRecommendation(recommendationId) {
        recommendationApi
            .getRecommendation(recommendationId)
            .then(recommendation => {
                this.setState({ recommendation });
            });
    }

    handleEngineChange = recommendation => {
        this.setState({ recommendation });
    };

    render() {
        const { engine = {} } = this.state.element;

        return (
            <>
                <RecommendationEngineSelectComponent
                    {...this.props}
                    onChange={this.handleEngineChange.bind(this)}
                    recommendationId={engine._id}
                />

                {this.state.recommendation ? (
                    <RecommendationEngineParamsComponent
                        {...this.props}
                        element={this.state.element}
                        recommendation={this.state.recommendation}
                    />
                ) : (
                    ''
                )}
            </>
        );
    }
}
