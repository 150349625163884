import React from 'react';
import { Button } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';

import './helperPopup.css';

const popupClickHandler = ({ url, screenX = 0, screenY = 0 }) => {
  const windowFeatures = [];
  const [boundaryWidth, boundaryHeight] = [
    window.screen.width,
    window.screen.height,
  ];
  let [width, height, lPos, tPos, lOffset, tOffset] = [
    650,
    750,
    screenX,
    screenY,
    40,
    40,
  ];

  const xOverflow = width + screenX > boundaryWidth;
  const yOverflow = height + screenY > boundaryHeight;

  if (xOverflow)
    lPos = boundaryWidth - (width + screenX - boundaryWidth) - lOffset;
  if (yOverflow)
    tPos = boundaryHeight - (height + screenY - boundaryHeight) - tOffset;

  windowFeatures.push(`popup`);
  windowFeatures.push(`width=${width}`);
  windowFeatures.push(`height=${height}`);
  windowFeatures.push(`left=${lPos}`);
  windowFeatures.push(`top=${tPos}`);

  window.open(url, 'beonly-helper-popup', windowFeatures.join(','));
};

const HelperPopup = ({ mode, url, text }) => {
  const getButtonShape = () => {
    return mode === 'icon' ? 'circle' : 'default';
  };

  const getButtonIcon = () => {
    return mode === 'icon' ? <QuestionOutlined /> : null;
  };

  return (
    <Button
      className="helper-popup-btn"
      onClick={({ screenX, screenY }) =>
        popupClickHandler({ url, screenX, screenY })
      }
      icon={getButtonIcon()}
      shape={getButtonShape()}
    >
      {text && text}
    </Button>
  );
};

export default HelperPopup;
