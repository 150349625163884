import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';

const RichTextItem = ({ props }) => {
  const { defaultValue, hasFeedback, label, name, rules } = props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
    >
      <ReactQuill theme="snow" />
    </Form.Item>
  );
};

export default RichTextItem;
