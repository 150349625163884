import React from 'react';
import { Typography } from 'antd';

import CustomCssSettings from './CustomCssSettings';
import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const PAGE_ITEMS = 'search_frontend_results_pageItems';

const SearchResultsSettings = ({
  searchResultsSettings,
  form,
  tenantConfig,
}) => {
  if (!searchResultsSettings?.length) return <></>;

  return (
    <>
      <Title level={4}>Página de resultados</Title>

      {searchResultsSettings.map(([key, value]) => {
        if (key === PAGE_ITEMS) return;
        if (value.frontendType === 'code') {
          return (
            <CustomCssSettings
              key={`search_page_settings_${key}`}
              form={form}
              keyName={key}
              value={value}
              tenantConfig={tenantConfig}
            />
          );
        } else {
          return (
            <FormFieldComponent
              form={form}
              name={key}
              key={key}
              help={value.description}
              {...value}
              defaultValue={value.default}
              dataType={value.type}
              hasFeedback={false}
              settedValue={tenantConfig[key]}
              className="search-form__grid"
            />
          );
        }
      })}
    </>
  );
};

export default SearchResultsSettings;
