import React from 'react';
import { Col, Typography } from 'antd';

import FormFieldComponent from '../../../../../../../Common/Form/Field';
import RecommendationEngineFiltersComponent from './EngineFilters';
import ShowVariations from './Flags/ShowVariations';

const { Title } = Typography;

const RecommendationEngineParamsComponent = props => {
  const { element, recommendation } = props;

  const elementEngine = element.engine || {};
  const elementEngineParams = elementEngine.params || {};

  const { params, _id } = recommendation;
  const useFilter = params.filters;
  const usePersonalization = params.personalization;

  return (
    <div className="params_group" key="recommendation-config">
      <Col offset="6">
        <Title level={4}>Configurações da recomendação</Title>
      </Col>

      {Object.values(params)
        .filter(param => param.hasOwnProperty('name'))
        .map(param =>
          param.name === 'show_variations' ? (
            <ShowVariations
              _id={_id}
              form={props.form}
              param={param}
              elementEngineParams={elementEngineParams}
            />
          ) : (
            <div className="engine_params_param" key={`${_id}:${param.name}`}>
              <FormFieldComponent
                form={props.form}
                name={`engine.params.${param.name}`}
                label={param.label}
                help={param.help}
                settedValue={elementEngineParams[param.name]}
                defaultValue={param.default}
                required={param.required}
                frontendType={param.frontend_type || 'input'}
                dataType={param.type || 'string'}
                options={param.options}
                allowMulti={param.allow_multi}
              />
            </div>
          ),
        )}

      {useFilter && <RecommendationEngineFiltersComponent {...props} />}

      {usePersonalization && <Title level={4}>Personalização</Title>}
    </div>
  );
};

export default RecommendationEngineParamsComponent;
