import createConsumer from '../../rest/createConsumer';

const TEMPLATE_API_URL = process.env.REACT_APP_TEMPLATE_API_URL;

const TemplateApiRestConsumer = createConsumer(consumer => {
    const getTemplates = clientId =>
        new Promise(resolve => {
            consumer
                .loadSource(`${TEMPLATE_API_URL}/${clientId}/templates`)
                .then(templates => {
                    resolve(templates);
                });
        });

    const getTemplate = (clientId, templateId) =>
        new Promise(resolve => {
            consumer
                .loadSource(
                    `${TEMPLATE_API_URL}/${clientId}/templates/${templateId}`
                )
                .then(template => {
                    resolve(template);
                });
        });

    return Object.freeze({
        getTemplates,
        getTemplate,
    });
});

export default TemplateApiRestConsumer;
