const ElementLayoutTabValidate = ({
    element,
    fieldsValidation,
    fieldsValues,
}) =>
    new Promise((resolve) => {
        const status = {
            success: true,
            tab: 'layout',
            message: null,
        };

        if (!fieldsValues.template || !fieldsValues.template._id) {
            status.success = false;
            status.message = `Um template deve ser selecionado para o elemento`;
        }

        // template fields reports errors
        if (fieldsValidation && fieldsValidation['template']) {
            status.success = false;
            status.message = `Algumas informações são inválidas. Por favor verifique os campos assinalados.`;
        }

        // validation passed, handle fields
        element.props = { ...element.props, ...fieldsValues['props'] };
        element.template = fieldsValues['template'];

        resolve(status);
    });

export default ElementLayoutTabValidate;
