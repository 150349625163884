import React from 'react';
import { Route } from 'react-router-dom';
import AuthStore from '../../../stores/Auth';

export const AuthorizedRoute = ({
    scope,
    component: Component,
    render,
    exact,
    path,
}) => {
    const renderAuthorized = props => {
        AuthStore.setScope({
            scope,
            path,
        });

        return getRender(props);
    };

    const getRender = props => {
        if (render) {
            return render(props);
        } else {
            return <Component {...props} />;
        }
    };

    return (
        <Route
            exact={exact}
            path={path}
            render={props =>
                AuthStore.isAuthorized(scope) === true ? (
                    renderAuthorized(props)
                ) : (
                    <span>Not authorized</span>
                )
            }
        />
    );
};
