export default [
    {
        group: 'Ofertas',
        name: 'showcase',
        label: 'Vitrine',
        description:
            'Recomendações ou destaques de ofertas com filtros de catálogo e personalização para o usuário. Disponível nos templates de carrosel, grid e destaques.',
        icon: 'appstore',
        is_available: true,
    },
    {
        group: 'Estímulos',
        name: 'notification',
        label: 'Notificações',
        description:
            'Chame a atenção dos seus usuários com notificações personalizadas. Utilize call-to-actions, cronômetros, imagens e outros recursos de interação.',
        icon: 'notification',
        is_available: true,
    },
    {
        group: 'Interação',
        name: 'subscribe',
        label: 'Formulários de inscrição',
        description:
            'Colete informações de contato dos seus usuários, como telefone, email, ou dados pessoas, como nome e data de nascimento.',
        icon: 'message',
        is_available: false,
    },
    {
        group: 'Estímulos',
        name: 'product_flag',
        label: 'Flags de produto',
        description:
            'Destaque informações para estímulo à venda do produto com tags especiais indicando alguns benefícios, como percentual de desconto.',
        icon: 'tag',
        is_available: false,
    },
    {
        group: 'Interação',
        name: 'banner',
        label: 'Banners',
        description:
            'Formatos diversos de banners para alavancar a comunicação do seu e-commerce.',
        icon: 'picture',
        is_available: true,
    },
    {
        group: 'Estímulos',
        name: 'snippet',
        label: 'Código HTML',
        description: 'Códigos HTML customizados. Aceita HTML e CSS.',
        icon: 'code',
        is_available: true,
    },
];
