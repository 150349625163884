import React from 'react';
import { Typography } from 'antd';

import './styles.css';

const { Paragraph } = Typography;

const NameColumn = ({ productInfo }) => {
  const { isAvailable, name, priceFrom, priceTo, error } = productInfo;

  return (
    <div className="name-column-container">
      <Paragraph className="product-name-text">{name}</Paragraph>

      {priceTo && (
        <Paragraph className="price-info-text">
          Disponibilidade: {isAvailable ? 'Disponível' : 'Indisponível'} -
          Preço:{' '}
          {priceFrom
            ? `de R$${priceFrom.toFixed(2).toString().replace('.', ',')} por `
            : ''}{' '}
          R${priceTo.toFixed(2).toString().replace('.', ',')}
        </Paragraph>
      )}

      {error && (
        <Paragraph className="error-info-text">
          Falha de sincronização: {error}
        </Paragraph>
      )}
    </div>
  );
};

export default NameColumn;
