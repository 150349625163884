import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Slider, Switch, Table, Typography } from 'antd';

const { Title } = Typography;

const columns = [
  {
    title: 'Classificação',
    dataIndex: 'classification',
    key: 'classification',
  },
  {
    title: 'Ordem',
    dataIndex: 'search',
    key: 'search',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_frontend_results_filters_${record.classification}_search`}
        initialValue={value.default}
        hasFeedback={false}
      >
        <Slider
          min={value?.config?.min ?? 0}
          max={value?.config?.max ?? 10}
          marks={{
            [value?.config?.min ?? 0]: value?.config?.min ?? 10,
            [value?.config?.max ?? 10]: value?.config?.max ?? 10,
          }}
        />
      </Form.Item>
    ),
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_frontend_results_filters_${record.classification}_label`}
        initialValue={value.default}
        hasFeedback={false}
      >
        <Input />
      </Form.Item>
    ),
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: '20%',
    render: (value, record) => (
      <Form.Item
        initialValue={value.default}
        name={`search_frontend_results_filters_${record.classification}_type`}
      >
        <Select
          options={value.config.options.map(option => {
            const [value, label] = option;
            return { label, value };
          })}
        />
      </Form.Item>
    ),
  },
  {
    title: 'Permite pesquisa?',
    dataIndex: 'permit',
    key: 'permit',
    align: 'center',
    render: (value, record) => (
      <Form.Item
        name={`search_frontend_results_filters_${record.classification}_permit`}
        initialValue={value.default}
        hasFeedback={false}
      >
        <Switch defaultChecked={value.default} />
      </Form.Item>
    ),
  },
  {
    title: 'Quantidade de opções',
    dataIndex: 'limit',
    key: 'limit',
    align: 'center',
    width: '25%',
    render: (value, record) => (
      <Form.Item
        name={`search_frontend_results_filters_${record.classification}_limit`}
        initialValue={value.default}
        hasFeedback={false}
      >
        <Slider
          min={value?.config?.min ?? 0}
          max={value?.config?.max ?? 50}
          marks={{
            [value?.config?.min ?? 0]: value?.config?.min ?? 0,
            [value?.config?.max ?? 50]: value?.config?.max ?? 50,
          }}
        />
      </Form.Item>
    ),
  },
];

const FacetSettings = ({ facetsFilters, formItems, tenantConfig }) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const newDataSource = [];

    facetsFilters.forEach(([key, values]) => {
      const [classification] = key.split('_').slice(4);
      const formKey = key.split('_').pop();

      const classificationInSource = newDataSource.find(
        c => c.classification === classification,
      );

      if (classificationInSource) {
        classificationInSource[formKey] = {
          default: tenantConfig[key] ?? values.default,
          config: formItems?.properties[key],
        };
      } else {
        newDataSource.push({
          key: crypto.randomUUID(),
          classification,
          [formKey]: {
            default: tenantConfig[key] ?? values.default,
            config: formItems?.properties[key],
          },
        });
      }
    });

    setDataSource(newDataSource);
  }, []);

  if (!facetsFilters?.length) return <></>;

  return (
    <>
      <Title level={4}>Configuração de filtros (facets)</Title>

      <Table
        className="search-form__table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        scroll={{
          y: 420,
        }}
      />
    </>
  );
};

export default FacetSettings;
