export default {
  PAGE_INITED: `PAGE_INITED`,
  PAGE_CHANGED: `PAGE_CHANGED`,
  PAGE_DUPLICATION_START: `PAGE_DUPLICATION_START`,
  PAGE_EDIT_START: `PAGE_EDIT_START`,
  PAGE_PERSIST_STATUS: `PAGE_PERSIST_STATUS`,
  REGION_EDIT_START: `REGION_EDIT_START`,
  REGION_ADDED: `REGION_ADDED`,
  REGION_SAVED: `REGION_SAVED`,
  REGION_DELETED: `REGION_DELETED`,
  ELEMENT_ADD_START: `ELEMENT_ADD_START`,
  ELEMENT_EDIT_START: `ELEMENT_EDIT_START`,
  ELEMENT_ADDED: `ELEMENT_ADDED`,
  ELEMENT_SAVED: `ELEMENT_SAVED`,
  ELEMENT_DELETED: `ELEMENT_DELETED`,
};
