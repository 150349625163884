import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Popconfirm, Space, Typography } from 'antd';

import EditorComponent from '../../Editor';

const { Title } = Typography;

const CustomCssSettings = ({ form, keyName, value, tenantConfig }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { label, description } = value;

  const handleOk = () => {
    form.setFieldsValue({ [keyName]: inputValue });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (tenantConfig[keyName]) {
      setInputValue(tenantConfig[keyName]);
    }
    setIsModalVisible(false);
  };

  useEffect(() => {
    const inputValue = tenantConfig[keyName] ?? value.default;
    setInputValue(inputValue || '');
    form.setFieldsValue({ [keyName]: inputValue });
  }, [tenantConfig, keyName, value.default, form]);

  return (
    <Form.Item
      name={keyName}
      label={label}
      hasFeedback={false}
      help={description}
      initialValue={tenantConfig[keyName] || ''}
      className="search-form__grid"
    >
      <Button onClick={() => setIsModalVisible(true)}>Abrir editor</Button>
      <Drawer
        title={`Busca - ${label} -> Editando`}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="65%"
        height="100vh"
        extra={
          <Space>
            <Popconfirm
              title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
              onConfirm={handleCancel}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => setIsModalVisible(false)}>Cancelar</Button>
            </Popconfirm>
            <Button type="primary" onClick={handleOk}>
              Salvar
            </Button>
          </Space>
        }
      >
        <EditorComponent
          code={inputValue}
          onCodeChange={code => setInputValue(code)}
        />
        <Title level={4} style={{ marginTop: '2em' }}>
          Ajuda sobre o editor
        </Title>
        <ul>
          <li>Use este editor para aplicar estilizações avançadas</li>
          <li>Todas as tags serão removidas, utilize apenas CSS.</li>
          <li>
            {`Exemplo: .minhaclasse {
              color: 'white';
              font-size: 25px;
            }`}
          </li>
          <li>Você pode identar e saltar linhas como preferir.</li>
        </ul>
      </Drawer>
    </Form.Item>
  );
};

export default CustomCssSettings;
