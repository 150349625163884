import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Alert, Input, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../context/useUser';
import { useTenant } from '../../../context/useTenant';
import LoginFormComponent from './Form';
import TenantSelectComponent from './TenantSelect';
import BannerAlert from '../../Common/Alert';
import LoadingComponent from '../../Common/Loading/Loading';
import './styles/login.css';

const { Content } = Layout;

const MAINTENANCE = process.env.REACT_APP_MAINTENANCE;
const MAINTENANCE_TITLE = process.env.REACT_APP_MAINTENANCE_TITLE;
const MAINTENANCE_DESCRIPTION = process.env.REACT_APP_MAINTENANCE_DESCRIPTION;

const INCIDENT = process.env.REACT_APP_INCIDENT;
const INCIDENT_TITLE = process.env.REACT_APP_INCIDENT_TITLE;
const INCIDENT_DESCRIPTION = process.env.REACT_APP_INCIDENT_DESCRIPTION;

const SUCCESS = process.env.REACT_APP_SUCCESS_MESSAGE;
const SUCCESS_TITLE = process.env.REACT_APP_SUCCESS_TITLE;
const SUCCESS_DESCRIPTION = process.env.REACT_APP_SUCCESS_DESCRIPTION;

const LoginComponent = () => {
  const [filteredTenants, setFilteredTenants] = useState('');
  const { login, authSuccess, loading, step, setStep, operationsList, user } =
    useUser();
  const { handleTenantData } = useTenant();
  const history = useHistory();

  const handleLogin = async credentials => {
    const loginAction = await login(credentials.username, credentials.password);
    if (loginAction) {
      // this action for one operation
      handleTenantData(loginAction);
      history.push(`/${loginAction}`);
    }
  };

  const selectTenant = tenant => {
    handleTenantData(tenant);
    history.push(`/${tenant}`);
  };

  const onInputChange = ({ target: { value } }) => {
    setFilteredTenants(value);
  };

  useEffect(() => {
    if (user?.success && operationsList && operationsList.length > 1)
      setStep('select');
  }, []);

  return (
    <Content className="Login">
      {/true/i.test(INCIDENT) && (
        <BannerAlert
          message={INCIDENT_TITLE}
          description={INCIDENT_DESCRIPTION}
          type={'error'}
        />
      )}

      {/true/i.test(MAINTENANCE) && (
        <BannerAlert
          message={MAINTENANCE_TITLE}
          description={MAINTENANCE_DESCRIPTION}
          type={'warning'}
        />
      )}

      {/true/i.test(SUCCESS) && (
        <BannerAlert
          message={SUCCESS_TITLE}
          description={SUCCESS_DESCRIPTION}
          type={'success'}
        />
      )}

      <Row type="flex" justify="center" align="middle">
        <Col span={8}>
          {authSuccess === false && (
            <Alert
              message="Falha ao efetuar login."
              description="Verifique suas credenciais e tente novamente."
              type="error"
              showIcon
              closable
            />
          )}

          {authSuccess === true && (
            <Alert
              message="Bem vindo de volta."
              description="Login efetuado com sucesso, carregando a aplicação."
              type="success"
              showIcon
              closable
            />
          )}
        </Col>
      </Row>

      <Row type="flex" justify="center" align="middle">
        {loading ? (
          <Col span={4}>
            <LoadingComponent />
          </Col>
        ) : step === 'login' ? (
          <LoginFormComponent confirm={handleLogin} />
        ) : step === 'select' ? (
          <Col span={12}>
            {operationsList && (
              <>
                <section className="main_title">
                  <h2>
                    Olá <em>{user.user}</em>!
                  </h2>
                  <h3>Estas são as operações a que você tem acesso:</h3>
                </section>

                {operationsList.length > 10 && (
                  <div className="tenant-select-search-container">
                    <span>Procurar operação: </span>
                    <Input
                      placeholder="Operação..."
                      onChange={onInputChange}
                      className="tenant-select-search-input"
                    />
                  </div>
                )}

                <Divider />

                {(operationsList || []).map(operation => (
                  <TenantSelectComponent
                    key={operation}
                    tenantId={operation}
                    onClick={(_, tenantData) =>
                      selectTenant(operation, tenantData)
                    }
                    filteredTenants={filteredTenants}
                  />
                ))}
              </>
            )}
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Content>
  );
};

export default LoginComponent;
