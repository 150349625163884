import React from 'react';
import { Input, Switch } from 'antd';

import './styles.css';

const FilterSwitch = ({ type, text, setFilterBy }) => {
  return (
    <div className="sync-switch-wrapper">
      <Switch
        className="sync-switch"
        defaultChecked
        onChange={() =>
          setFilterBy(prevState => ({
            ...prevState,
            [type]: !prevState[type],
          }))
        }
      />
      <span>{text}</span>
    </div>
  );
};

const TableFilter = ({ setFilterBy, setFilterInputValue }) => {
  return (
    <div className="sync-filter-container">
      <div className="sync-filter-wrapper">
        <span>Visualizar:</span>

        <FilterSwitch
          type="waiting"
          text="Aguardando"
          setFilterBy={setFilterBy}
        />

        <FilterSwitch
          type="success"
          text="Sincronizados"
          setFilterBy={setFilterBy}
        />

        <FilterSwitch type="error" text="Com erro" setFilterBy={setFilterBy} />
      </div>

      <Input
        className="sync-filter-input"
        placeholder="Filtrar tabela"
        onChange={({ target: { value } }) => setFilterInputValue(value)}
      />
    </div>
  );
};

export default TableFilter;
