import React from 'react';
import { Typography } from 'antd';

import FormFieldComponent from '../../Form/Field';

const { Title } = Typography;

const FrontendGeneralSettings = ({ generalConfigs, form, tenantConfig }) => {
  if (!generalConfigs?.length) return <></>;

  return (
    <>
      <Title level={4}>Configurações gerais</Title>

      {generalConfigs.map(([key, value]) => (
        <FormFieldComponent
          form={form}
          name={key}
          key={key}
          {...value}
          defaultValue={value.default}
          defaultFormatter={'none'}
          hasFeedback={false}
          help={value.description}
          dataType={value.type}
          settedValue={tenantConfig[key]}
          className="search-form__grid"
        />
      ))}
    </>
  );
};

export default FrontendGeneralSettings;
