import React from 'react';
import { Form, Input } from 'antd';

const InputItem = ({ props }) => {
  const { className, defaultValue, hasFeedback, help, label, name, rules } =
    props;

  return (
    <Form.Item
      hasFeedback={hasFeedback ?? true}
      help={help}
      initialValue={defaultValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
    >
      <Input />
    </Form.Item>
  );
};

export default InputItem;
