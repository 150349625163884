import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Popconfirm } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import EmptyResultsSettings from '../../../../../Common/Search/SearchConfig/EmptyResultsSettings';
import PageItemsSettings from '../../../../../Common/Search/SearchConfig/PageItemsSettings';
import SortOrderSettings from '../../../../../Common/Search/SearchConfig/SortOrderSettings';
import TitleSettings from '../../../../../Common/Search/SearchConfig/TitleSettings';
import FiltersSettings from '../../../../../Common/Search/SearchConfig/FiltersSettings';
import SearchResultsSettings from '../../../../../Common/Search/SearchConfig/SearchResultsSettings';

const EMPTY = 'empty';
const FIELDS = 'fields';
const FILTERDIRECTION = 'filterDirection';
const FRONTEND = 'frontend';
const PAGE = 'page';
const PAGE_ITEMS = 'search_frontend_results_pageItems';
const RESULTS = 'results';
const SORT = 'sort';
const TITLE = 'title';

const ResultsTab = ({ formItems, handleSubmit, tenantConfig, onCancel }) => {
  const [form] = Form.useForm();
  const [emptyResults, setEmptyResults] = useState([]);
  const [filtersSettings, setFiltersSettings] = useState([]);
  const [pageItemsNumber, setPageItemsNumber] = useState([]);
  const [searchResultsSettings, setSearchResultsSettings] = useState([]);
  const [sortOrderItems, setSortOrderItems] = useState([]);
  const [titleTpls, setTitleTpls] = useState([]);

  const { properties } = formItems;

  useEffect(() => {
    const emptyItems = filterByKeywords(properties, [FRONTEND, EMPTY]);
    const filtersItems = filterByKeywords(properties, [
      FRONTEND,
      FILTERDIRECTION,
    ]);
    const orderItems = filterByKeywords(properties, [FRONTEND, SORT]);
    const pageItems = filterByKeywords(properties, [PAGE_ITEMS]);
    const searchResultsItems = filterByKeywords(properties, [
      FRONTEND,
      RESULTS,
      PAGE,
    ]);
    const titleItems = filterByKeywords(properties, [FRONTEND, FIELDS, TITLE]);

    setEmptyResults(emptyItems);
    setFiltersSettings(filtersItems);
    setPageItemsNumber(pageItems);
    setSearchResultsSettings(searchResultsItems);
    setSortOrderItems(orderItems);
    setTitleTpls(titleItems);
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <PageItemsSettings
          form={form}
          pageItemsNumber={pageItemsNumber}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <FiltersSettings
          form={form}
          filtersSettings={filtersSettings}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <SortOrderSettings
          form={form}
          sortOrderItems={sortOrderItems}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <SearchResultsSettings
          form={form}
          searchResultsSettings={searchResultsSettings}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <EmptyResultsSettings
          form={form}
          emptyResults={emptyResults}
          tenantConfig={tenantConfig}
        />

        <Divider />

        <TitleSettings
          form={form}
          titleTpls={titleTpls}
          tenantConfig={tenantConfig}
        />
      </div>
    </Form>
  );
};

export default ResultsTab;
