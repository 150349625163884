import React from 'react';

import BannerTemplateParams from '../../../../../../../Common/TemplateParams/BannerTemplateParams';
import BasicNotificationTemplateParams from '../../../../../../../Common/TemplateParams/BasicNotificationTemplateParams';
import GeneralTemplateParams from '../../../../../../../Common/TemplateParams/GeneralTemplateParams';
import NotificationTemplateParams from '../../../../../../../Common/TemplateParams/NotificationTemplateParams';

const BANNER_TEMPLATE = 'showcase--vtex--banner';
const BASIC_NOTIFICATION_TEMPLATE = 'notification_basica_c';
const FREE_SHIPPING_NOTIFICATION_TEMPLATE = 'notification_frete_c';

const LayoutTemplateParamsComponent = props => {
  const { element, template } = props;

  if (!template.paramsGroups || !template.params) return null;

  switch (template?.name) {
    case BANNER_TEMPLATE:
      return (
        <BannerTemplateParams
          {...props}
          element={element}
          template={template}
        />
      );

    case FREE_SHIPPING_NOTIFICATION_TEMPLATE:
      return (
        <NotificationTemplateParams
          {...props}
          element={element}
          template={template}
        />
      );

    case BASIC_NOTIFICATION_TEMPLATE:
      return (
        <BasicNotificationTemplateParams
          {...props}
          element={element}
          template={template}
        />
      );

    default:
      return (
        <GeneralTemplateParams
          {...props}
          element={element}
          template={template}
        />
      );
  }
};

export default LayoutTemplateParamsComponent;
