import cookie from 'react-cookies';
import AuthApiConsumer from '../../core/auth/api';

export const TOKEN_KEY_COOKIE = 'beon_auth_token';

export const isAuthenticated = () => {
  try {
    const token = cookie.load('beon_auth_token');
    return token ? true : false;
  } catch (error) {
    return false;
  }
};

export const isAuthorized = scope => {
  try {
    const { acl } = getAuthenticated();

    if (!acl) {
      return false;
    }

    const hasGlobalAcl = Object.keys(acl).indexOf(`bn:global`);
    const hasOrgAcl = Object.keys(acl).indexOf(`org:${scope}`);

    return hasGlobalAcl > -1 || hasOrgAcl > -1;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setAuthenticated = ({ token, user, acl, meta }) =>
  new Promise(async resolve => {
    try {
      cookie.save('beon_auth_token', token);

      // user is local stored
      localStorage.setItem('current_user', user);
      localStorage.setItem('current_user_meta', JSON.stringify(meta));
      localStorage.setItem('current_user_acl', JSON.stringify(acl));

      resolve(true);
    } catch (error) {
      resolve(error);
    }
  });

export const destroyAuthenticated = () =>
  new Promise(async resolve => {
    cookie.remove('beon_auth_token');

    // user is local stored
    localStorage.clear();
    resolve();
  });

export const getAuthenticated = () => {
  try {
    const user = localStorage.getItem('current_user');
    const acl = JSON.parse(localStorage.getItem('current_user_acl'));
    const meta = JSON.parse(localStorage.getItem('current_user_meta'));

    const storageScope = localStorage.getItem('current_scope');
    const scope = storageScope ? JSON.parse(storageScope) : undefined;

    return { user, acl, scope, meta };
  } catch (error) {
    console.log(error);
    return { user: undefined, acl: undefined, scope: undefined };
  }
};

export const authenticate = ({ username, password }) =>
  new Promise(async resolve => {
    AuthApiConsumer.authenticate({ username, password }).then(result => {
      const status = {
        success: undefined,
        user: username,
        meta: undefined,
        acl: undefined,
        token: undefined,
      };

      if (result instanceof Error) {
        status.success = false;
      } else {
        status.success = true;
        status.acl = result.acl;
        status.token = result.token;
        status.meta = result;
      }

      resolve(status);
    });
  });

export const refreshAuth = () =>
  new Promise(async resolve => {
    resolve(isAuthenticated());
  });
