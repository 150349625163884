import React, { useRef } from 'react';
import axios from 'axios';
import { Button, Form, Select } from 'antd';

import './styles.css';

const { Option } = Select;

const VTEX_PROXY_URL = process.env.REACT_APP_VTEX_PROXY_URL;
const BATCH_SIZE = 50;
const MAX_ITEMS = 2000;

const integrationOptions = [
  { name: 'Cluster', key: 'collection', value: 'fq=productClusterIds:' },
  { name: 'Product ID', key: 'product_id', value: 'fq=productId:' },
  { name: 'SKU ID', key: 'sku_id', value: 'fq=skuId:' },
  { name: 'Reference ID', key: 'refid', value: 'fq=alternateIds_RefId:' },
];

const getSearchKey = formValue => {
  return integrationOptions.find(({ value }) => value === formValue);
};

const CatalogForm = ({
  isLoading,
  setDataSource,
  setFilteredItems,
  setIsLoading,
  setStartUnitaryIntegrations,
  setTextMode,
  setSyncTextMode,
}) => {
  const TENANT_DOMAIN = localStorage
    .getItem('tenantDomain')
    ?.replace('https://', '');

  const numberOfProductsRef = useRef(0);
  const fromRef = useRef(0);

  const fetchItems = async ({ searchBy, ids }) => {
    const searchByObj = getSearchKey(searchBy);
    const searchKey = searchByObj.key;
    const searchKeyPayload = ids.map(id => `${searchByObj.value}${id}`);

    const payload = {
      domain: TENANT_DOMAIN,
      [searchKey]: searchKeyPayload,
      from: fromRef.current.toString(),
      to: (fromRef.current + BATCH_SIZE - 1).toString(),
    };

    try {
      const response = await axios.post(VTEX_PROXY_URL, payload);

      const items = response.data.products.map(
        ({ sku, product_id, refid, name }) => ({
          sku,
          product_id,
          refid,
          productInfo: { name },
        }),
      );

      numberOfProductsRef.current += items.length;

      if (numberOfProductsRef.current > 2000) {
        const remainingItems =
          2000 - (numberOfProductsRef.current - items.length);
        const slicedItems = items.slice(0, remainingItems);
        setFilteredItems(prevItems => [...prevItems, ...slicedItems]);
        setDataSource(prevItems => [...prevItems, ...slicedItems]);
      } else {
        setFilteredItems(prevItems => [...prevItems, ...items]);
        setDataSource(prevItems => [...prevItems, ...items]);
      }

      return {
        numberOfProducts: response.data.number_of_products,
      };
    } catch (e) {
      return {
        numberOfProducts: 0,
      };
    }
  };

  const handleSubmit = async ({ searchBy, ids }) => {
    if (numberOfProductsRef.current === 0) {
      setIsLoading(true);
    }

    setSyncTextMode('batching');

    const { numberOfProducts } = await fetchItems({
      searchBy,
      ids,
    });

    setIsLoading(false);

    if (numberOfProducts === 0) {
      setTextMode('notFound');
    }

    if (
      numberOfProducts === BATCH_SIZE &&
      numberOfProductsRef.current < MAX_ITEMS
    ) {
      fromRef.current += BATCH_SIZE;
      handleSubmit({ searchBy, ids });
    } else {
      if (numberOfProductsRef.current > 0) {
        setSyncTextMode('processing');
        setStartUnitaryIntegrations(true);
      }
      setIsLoading(false);
    }
  };

  return (
    <Form onFinish={handleSubmit} className="sync-form-container">
      <Form.Item
        name="searchBy"
        rules={[{ required: true, message: 'Selecione uma opção de busca' }]}
        className="sync-options"
      >
        <Select placeholder="Buscar por">
          {integrationOptions.map(({ name, key, value }) => (
            <Option key={key} value={value}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="ids"
        rules={[{ required: true, message: 'Insira pelo menos um valor' }]}
        className="sync-ids"
      >
        <Select placeholder="Insira os IDs" mode="tags" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isLoading}>
          Sincronizar Catálogo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CatalogForm;
