import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Layout, notification } from 'antd';

import { UserProvider } from '../../context/useUser';
import { TenantProvider } from '../../context/useTenant';
import { AuthenticatedRoute, AuthorizedRoute } from '../Common/Auth';

import AdvancedTab from './AdvancedTab';
import HeaderComponent from './Header';
import LoginComponent from './Login';
import LogoutComponent from './Logout';
import ContentComponent from './Content';
import SearchComponent from './Search';

import './App.css';

const { Footer } = Layout;

const App = () => {
  useEffect(() => {
    if (/true/i.test(process.env.REACT_APP_OFFLINE))
      notification.open({
        message: 'Ambiente offline',
        description:
          'Você está em uma versão offline da plataforma Beon. Todos os dados serão descartados ao fechar o navegador. Bom passeio!',
        duration: 5,
        style: {
          backgroundColor: '#b13333',
          color: '#fff',
          fontWeight: 'bold',
        },
      });
  }, []);

  return (
    <UserProvider>
      <TenantProvider>
        <Layout style={{ minHeight: '100vh' }} className="App">
          <Router>
            <Switch>
              <Route path="/" exact render={() => <Redirect to={`/login`} />} />

              <Route path="/login" component={LoginComponent} />
              <Route path="/logout" component={LogoutComponent} />

              <AuthenticatedRoute
                path="/:client_id"
                render={({ match }) => (
                  <>
                    <HeaderComponent route={match} />

                    <Route
                      path="/:client_id"
                      exact
                      render={() => (
                        <Redirect to={`/${match.params.client_id}/content`} />
                      )}
                    />

                    <AuthorizedRoute
                      path="/:client_id/content"
                      scope={match.params.client_id}
                      component={ContentComponent}
                    />

                    <AuthorizedRoute
                      path="/:client_id/search"
                      scope={match.params.client_id}
                      component={SearchComponent}
                    />

                    <AuthenticatedRoute
                      path="/:client_id/catalog"
                      scope={match.params.client_id}
                      component={AdvancedTab}
                    />

                    <Footer style={{ textAlign: 'center' }}>
                      Desenvolvido por Beonly &copy; 2018-2024
                    </Footer>
                  </>
                )}
              />
            </Switch>
          </Router>
        </Layout>
      </TenantProvider>
    </UserProvider>
  );
};

export default App;
