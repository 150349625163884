import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import LoadingComponent from '../../../../../Common/Loading/Loading';
import SearchApiConsumer from '../../../../../../core/search/rest/api';
import SynonymComponent from '../../../../../Common/Search/Synonym/Synonym';
import SynonymsForm from '../../../../../Common/Search/Synonym/Form/SynonymsForm';
import SynonymsHeader from '../../../../../Common/Search/Synonym/Header/SynonymsHeader';

const SynonymsTab = ({ logChanges }) => {
  const { client_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [synonyms, setSynonyms] = useState([]);
  const [synonymFilter, setSynonymFilter] = useState('');
  const [showSynonymForm, setShowSynonymForm] = useState(false);
  const [synonymForm, setSynonymForm] = useState({});
  const [synonymFormData, setSynonymFormData] = useState({});

  const getSynonyms = async () => {
    try {
      setIsLoading(true);
      setSynonyms([]);
      setSynonymFormData({});

      const response = await SearchApiConsumer.getSynonyms(client_id);

      if (response) {
        setSynonyms(response);
      } else {
        message.error(
          'Ocorreu um erro ao carregar os sinônimos. Por favor, tente novamente',
        );
      }
    } catch (e) {
      console.warn('getSynonyms failed with', e);
    } finally {
      setIsLoading(false);
    }
  };

  const saveSynonyms = async values => {
    try {
      setIsLoading(true);
      setShowSynonymForm(false);

      const saved = await SearchApiConsumer.createSynonyms(client_id, values);

      if (saved) {
        message.success('Informações do grupo salvas com sucesso!');
        logChanges({
          action: 'Sinônimo adicionado/editado',
          body: { ...values },
        });
      } else {
        message.error(
          'Ocorreu um erro ao salvar as informações do grupo. Por favor, tente novamente',
        );
      }

      await getSynonyms();
    } catch (e) {
      console.warn('saveSynonyms failed with', e);
    } finally {
      setIsLoading(false);
    }
  };

  const editSynonymGroup = synonym => {
    setIsAdding(false);
    setSynonymFormData(synonym);
    setShowSynonymForm(true);
  };

  const deleteSynonymGroup = async id => {
    try {
      setIsLoading(true);
      const deleted = await SearchApiConsumer.deleteSynonyms(client_id, id);
      if (deleted) {
        logChanges({ action: 'Sinônimo excluído', body: { id } });
        message.success('Grupo de sinônimos removido com sucesso!');
      } else {
        message.error(
          'Ocorreu um erro ao remover o grupo. Por favor, tente novamente',
        );
      }
    } catch (e) {
      console.warn('deleteSynonym failed with', e);
    } finally {
      await getSynonyms();
    }
  };

  const getSynonymForm = async () => {
    try {
      const result = await SearchApiConsumer.getSynonymForm();
      if (result) {
        return setSynonymForm(result);
      }
    } catch (e) {
      console.warn('getSynonymForm failed with', e);
    }
  };

  const onFormCancel = () => {
    setShowSynonymForm(false);
    setSynonymFormData({});
  };

  const filterSynonyms = ({ target: { value } }) => {
    setSynonymFilter(value);
  };

  useEffect(() => {
    getSynonyms();
    getSynonymForm();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        style={{ margin: '20px 0' }}
        onClick={() => {
          setShowSynonymForm(true);
          setIsAdding(true);
        }}
      >
        Adicionar grupo de sinônimos
      </Button>

      {synonyms.length > 0 && (
        <>
          <Input
            onChange={filterSynonyms}
            placeholder={`Digite a palavra procurada`}
            value={synonymFilter}
            style={{ marginLeft: '20px', width: '30%', borderRadius: '20px' }}
          />

          <SynonymsHeader />

          {synonyms
            .filter(item => {
              const filtered = item.synonyms
                .concat(item.input || [])
                .some(s =>
                  s.toLowerCase().includes(synonymFilter.toLowerCase()),
                );
              return filtered;
            })
            .map(item => (
              <SynonymComponent
                key={item._id}
                getSynonyms={getSynonyms}
                synonym={item}
                deleteSynonym={deleteSynonymGroup}
                editSynonym={editSynonymGroup}
              />
            ))}
        </>
      )}

      {showSynonymForm && (
        <SynonymsForm
          isAdding={isAdding}
          onCancel={onFormCancel}
          onSave={saveSynonyms}
          synonymForm={synonymForm}
          settedValues={synonymFormData}
        />
      )}
    </>
  );
};

export default SynonymsTab;
