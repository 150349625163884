import React, { useEffect, useState } from 'react';
import { Button, Form, Popconfirm } from 'antd';

import { filterByKeywords } from '../../../../../../utils/search';

import FacetSettings from '../../../../../Common/Search/SearchConfig/FacetSettings';
import LoadingComponent from '../../../../../Common/Loading/Loading';

const FILTERS = 'filters';
const FRONTEND = 'frontend';

const FiltersTab = ({ formItems, handleSubmit, tenantConfig, onCancel }) => {
  const [form] = Form.useForm();
  const [facetsFilters, setFacetsFilters] = useState();

  const { properties } = formItems;

  const getFacetsFilters = () => {
    const facets = [];

    filterByKeywords(tenantConfig, [
      'backend',
      'classifications',
      'useAsFacet',
    ]).forEach(([key, value]) => {
      const filtered = Object.entries(properties).filter(([formKey, _]) => {
        const [classification] = formKey.split('_').slice(4);
        const [classificationKey] = key.split('_').slice(3);

        return (
          classification === classificationKey &&
          formKey.includes(FRONTEND) &&
          formKey.includes(FILTERS) &&
          value === true
        );
      });

      facets.push(...filtered);
    });
    return facets;
  };

  useEffect(() => {
    const facets = getFacetsFilters();
    setFacetsFilters(facets);
  }, []);

  if (!facetsFilters) {
    return <LoadingComponent />;
  }

  if (!facetsFilters.length) {
    return <p>Esta operação não possui filtros ativos.</p>;
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: '5px',
        padding: '5px 20px 10px 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '1rem',
          marginTop: '0.75rem',
        }}
      >
        <Form.Item>
          <Popconfirm
            title="Todas as mudanças não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="default" htmlType="button">
              Cancelar
            </Button>
          </Popconfirm>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </div>

      <div style={{ marginLeft: '1.5rem' }}>
        <FacetSettings
          form={form}
          facetsFilters={facetsFilters}
          formItems={formItems}
          tenantConfig={tenantConfig}
        />
      </div>
    </Form>
  );
};

export default FiltersTab;
