import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Slider,
  Switch,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import AuthStore from '../../../../../stores/Auth';
import CatalogApiConsumer from '../../../../../core/catalog/api';

const MerchandisingRulesForm = ({ isAdding, onCancel, onSave, rule }) => {
  const [isFetchingTrees, setIsFetchingTrees] = useState(false);
  const [treesOptions, setTreesOptions] = useState([]);

  const getCatalogTreesOptions = async term => {
    try {
      setIsFetchingTrees(true);

      const { scope } = AuthStore.getScope();
      const trees = await CatalogApiConsumer.searchTrees({
        clientId: scope,
        term,
      });

      const mappedTrees = trees.map(({ kind, name, tree_key }) => {
        return {
          label: `${tree_key} (${name})`,
          value: tree_key,
          title: name,
        };
      });

      const sortedTrees = mappedTrees.sort((a, b) =>
        a[`label`].localeCompare(b[`label`]),
      );

      setTreesOptions(sortedTrees);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsFetchingTrees(false);
    }
  };

  return (
    <Drawer
      title={`Regras de Merchandising -> ${
        isAdding ? 'Adicionando regra' : 'Editando'
      }`}
      placement="right"
      closable={false}
      maskClosable={false}
      visible={true}
      width={900}
      className="page-edit-wrapper"
    >
      <Form
        onFinish={onSave}
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={true}
        size="large"
        style={{
          width: '90%',
          margin: '10px 50px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {rule?._id && (
          <Form.Item
            initialValue={rule._id}
            name="_id"
            style={{ display: 'none' }}
          />
        )}

        <Form.Item style={{ alignSelf: 'flex-end' }}>
          <Popconfirm
            title="Todas as informações não salvas serão perdidas. Deseja continuar?"
            onConfirm={onCancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button htmlType="button">Cancelar</Button>
          </Popconfirm>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '7px' }}
          >
            Salvar
          </Button>
        </Form.Item>

        <Form.Item
          initialValue={rule?.is_active || false}
          label="Regra ativa"
          name="is_active"
        >
          <Switch
            defaultChecked={rule?.is_active || false}
            checkedChildren={'On'}
            unCheckedChildren={'Off'}
          />
        </Form.Item>

        <Form.Item
          help="Procure por departamentos, categorias e clusters."
          initialValue={rule?.trees_keys || []}
          label="Grupo(s)"
          name="trees_keys"
        >
          <Select
            mode="multiple"
            allowClear
            defaultValue={rule?.trees_keys || []}
            showSearch
            notFoundContent={
              isFetchingTrees ? (
                <Empty
                  imageStyle={{
                    height: 40,
                  }}
                  image={<LoadingOutlined style={{ fontSize: '40px' }} />}
                  description="Carregando opções..."
                />
              ) : null
            }
            filterOption={false}
            onSearch={v => getCatalogTreesOptions(v)}
            options={treesOptions}
            loading={isFetchingTrees}
            labelInValue
          />
        </Form.Item>

        <Form.Item
          initialValue={rule?.score ?? 0}
          help={
            'Grupos com prioridade 5 serão mostrados primeiro. Grupos com prioridade -5 serão mostrados por último.'
          }
          label="Prioridade"
          name="score"
        >
          <Slider
            marks={{
              [-5]: -5,
              [5]: 5,
            }}
            min={-5}
            max={5}
            step={1}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MerchandisingRulesForm;
