import React from 'react';
import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

const StatsText = ({ data }) => {
  const totalItems = data.reduce((acc, cur) => acc + cur.value, 0) || 1;

  const sync = data[0].value;
  const syncPercentage = ((data[0].value / totalItems) * 100).toFixed(2);
  const errored = data[1].value;
  const erroredPercentage = ((data[1].value / totalItems) * 100).toFixed(2);
  const queued = data[2].value;
  const queuedPercentage = ((data[2].value / totalItems) * 100).toFixed(2);

  return (
    <div>
      <Paragraph>
        Sincronizados:{' '}
        <Text
          strong
          style={{ color: '#52c41a' }}
        >{`${sync} (${syncPercentage}%)`}</Text>
      </Paragraph>

      <Paragraph>
        Com erro:{' '}
        <Text
          strong
          style={{ color: '#f5222d' }}
        >{`${errored} (${erroredPercentage}%)`}</Text>
      </Paragraph>

      <Paragraph>
        Aguardando:{' '}
        <Text
          strong
          style={{ color: '#767676' }}
        >{`${queued} (${queuedPercentage}%)`}</Text>
      </Paragraph>
    </div>
  );
};

export default StatsText;
