import SearchApiRestConsumer from './rest/api';
import SearchApiStaticConsumer from './static/api';

// const ContentApiConsumer = /true/i.test(process.env.REACT_APP_OFFLINE)
//     ? ContentApiStaticConsumer
//     : ContentApiRestConsumer;

const SearchApiConsumer = SearchApiRestConsumer;

export default SearchApiConsumer;
