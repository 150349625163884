import React, { useEffect, useState } from 'react';

const ERROR_TEXT =
  'Houve um erro ao efetuar a busca dos produtos selecionados. Verifique os dados e tente novamente.';
const NOT_FOUND_TEXT =
  'Não encontramos resultados para sua busca. Revise os dados e tente novamente.';
const WAITING_TEXT =
  'Aguardando os filtros para descobrir os produtos a serem sincronizados.';

const FormText = ({ textMode }) => {
  const [text, setText] = useState(WAITING_TEXT);

  useEffect(() => {
    switch (textMode) {
      case 'error':
        return setText(ERROR_TEXT);

      case 'notFound':
        return setText(NOT_FOUND_TEXT);

      default:
        return setText(WAITING_TEXT);
    }
  }, [textMode]);

  return <div>{text}</div>;
};

export default FormText;
