export default [
    {
        _id: 'b0c25a65-754d-43a7-8c0d-fe0a3b093b8c',
        kind: 'category',
        name: 'Pessoal',
        tree_id: 'Pessoal',
        priority: 20,
    },
    {
        _id: 'fb9eb388-e388-49f1-bbc5-25b2917e1aed',
        kind: 'category',
        name: 'Mochilas',
        tree_id: 'Mochilas',
        priority: 10,
    },
    {
        _id: '00fdd672-b7aa-403c-b246-f6704d5779ec',
        kind: 'category',
        name: 'Casa e Decoração',
        tree_id: 'Casa e Decoração',
        priority: 30,
    },
    {
        _id: '0e004eaa-65da-4672-a6e6-2f4c2f5e8e13',
        kind: 'category',
        name: 'Cozinha',
        tree_id: 'Cozinha',
        priority: 20,
    },
    {
        _id: 'f52be9ca-c9e7-4f7e-9c36-ca9e8af74172',
        kind: 'category',
        name: 'Moringas E Garrafas',
        tree_id: 'Moringas E Garrafas',
        priority: 10,
    },
    {
        _id: 'da961ac2-1055-4f16-9333-1b6bbda222d2',
        kind: 'category',
        name: 'Diversos Casa',
        tree_id: 'Diversos Casa',
        priority: 10,
    },
    {
        _id: '5f61843a-d01b-43ff-ab4a-b1af155117d5',
        kind: 'category',
        name: 'Diversos Cozinha',
        tree_id: 'Diversos Cozinha',
        priority: 10,
    },
    {
        _id: 'd28edefe-88b1-4490-84fa-2f4cd2de1288',
        kind: 'category',
        name: 'Bolsas',
        tree_id: 'Bolsas',
        priority: 10,
    },
    {
        _id: '1ef447e6-a082-41c4-9c6c-408e469d4fd6',
        kind: 'collection',
        tree_id: 'unicornio',
        name: 'Unicórnio',
        priority: 10,
    },
    {
        _id: 'f6e1582e-36f5-4ace-b696-f66dbf91b18d',
        kind: 'category',
        name: 'Diversos Churrasco',
        tree_id: 'Diversos Churrasco',
        priority: 10,
    },
    {
        _id: '9465580a-3e7f-4619-acf8-3ada8e266bc3',
        kind: 'category',
        name: 'Eletrônicos',
        tree_id: 'Eletrônicos',
        priority: 10,
    },
    {
        _id: '8a308145-6962-46ab-aab6-1270cf40979d',
        kind: 'category',
        name: 'Sala e Escritorio',
        tree_id: 'Sala e Escritorio',
        priority: 20,
    },
    {
        _id: '046f0751-a953-490d-96f2-72ab1be67849',
        kind: 'category',
        name: 'Almofadas',
        tree_id: 'Almofadas',
        priority: 10,
    },
    {
        _id: 'e95ed76f-b58b-46b7-84fd-00091389fa79',
        kind: 'category',
        name: 'Caixas',
        tree_id: 'Caixas',
        priority: 10,
    },
    {
        _id: '2ea7749f-3c46-48b3-b563-c4b07aeb1a4d',
        kind: 'category',
        name: 'Albuns',
        tree_id: 'Albuns',
        priority: 10,
    },
    {
        _id: 'cbb65b91-17eb-4ed3-b613-a9a4f2b80f2f',
        kind: 'category',
        name: 'Quarto e Banheiro',
        tree_id: 'Quarto e Banheiro',
        priority: 10,
    },
    {
        _id: 'f1f6d8d2-f14c-4f5e-918c-5dd2e3c92f83',
        kind: 'category',
        name: 'Diversos Pessoal',
        tree_id: 'Diversos Pessoal',
        priority: 10,
    },
    {
        _id: '472fe17c-404b-4b67-af23-568298ea9684',
        kind: 'category',
        name: 'Cofres',
        tree_id: 'Cofres',
        priority: 10,
    },
    {
        _id: '02b7f0ea-20f3-45b5-bb5a-55eed933181b',
        kind: 'category',
        name: 'Porta Retratos',
        tree_id: 'Porta Retratos',
        priority: 10,
    },
    {
        _id: 'a1af3fb3-a28d-4476-a740-e64c4ec69ecb',
        kind: 'category',
        name: 'Bolsas Térmicas',
        tree_id: 'Bolsas Térmicas',
        priority: 10,
    },
    {
        _id: 'fa05e27a-6812-4d39-90f9-438ba8fbeee4',
        kind: 'collection',
        tree_id: 'me-lhama',
        name: 'Me Lhama',
        priority: 10,
    },
    {
        _id: 'cd5d7d07-dfce-4d7f-8624-b95e6fb5b1c7',
        kind: 'category',
        name: 'Diversos Bebidas',
        tree_id: 'Diversos Bebidas',
        priority: 10,
    },
    {
        _id: '6988737d-0c76-4051-8b15-8f509bf1c05c',
        kind: 'category',
        name: 'Papelaria',
        tree_id: 'Papelaria',
        priority: 10,
    },
    {
        _id: 'fc6d6e9a-0fed-43fd-bc31-5d1cc80efb97',
        kind: 'collection',
        tree_id: 'harry-potter',
        name: 'Harry Potter',
        priority: 10,
    },
    {
        _id: 'a05c30a9-93d6-42e6-830d-52b5468586e4',
        kind: 'category',
        name: 'Jogos',
        tree_id: 'Jogos',
        priority: 10,
    },
    {
        _id: '7475ee26-4953-403d-ad10-2b0daf39fa08',
        kind: 'category',
        name: 'Luminarias',
        tree_id: 'Luminarias',
        priority: 10,
    },
    {
        _id: '3f44e303-a2fe-482c-a6b3-f0d9a8ea6086',
        kind: 'collection',
        tree_id: 'star-wars',
        name: 'Star Wars',
        priority: 10,
    },
    {
        _id: '9be7326a-b927-4cca-b046-e605d33f3d05',
        kind: 'category',
        name: 'Copos Termicos e Para Viagem',
        tree_id: 'Copos Termicos e Para Viagem',
        priority: 10,
    },
    {
        _id: '7ae9a4bb-8df9-4e51-96cc-35d950ecb50c',
        kind: 'category',
        name: 'Canecas',
        tree_id: 'Canecas',
        priority: 10,
    },
    {
        _id: '1999f660-da2d-46d1-aab8-b4e689264520',
        kind: 'category',
        name: 'Copos com canudo',
        tree_id: 'Copos com canudo',
        priority: 10,
    },
    {
        _id: '38d20ad7-62c0-4495-b522-eb4ef91eb3d1',
        kind: 'category',
        name: 'Amplificadores',
        tree_id: 'Amplificadores',
        priority: 10,
    },
    {
        _id: 'aae23d23-c558-4856-9f22-ce50f18f19ae',
        kind: 'category',
        name: 'Cartoes',
        tree_id: 'Cartoes',
        priority: 10,
    },
    {
        _id: '9ae9c120-05a3-405b-9589-ecc2503a66ec',
        kind: 'category',
        name: 'Bandejas Laptop',
        tree_id: 'Bandejas Laptop',
        priority: 10,
    },
    {
        _id: 'c12ab2a0-e8db-4802-a652-9ea9d40c8fff',
        kind: 'category',
        name: 'Copos Com Canudo',
        tree_id: 'Copos Com Canudo',
        priority: 10,
    },
    {
        _id: 'a7be0762-d2f6-4d08-990d-784c3f597e03',
        kind: 'category',
        name: 'Canecos E Tulipas',
        tree_id: 'Canecos E Tulipas',
        priority: 10,
    },
    {
        _id: '1fc4f960-e7ac-4529-86a0-0a621573d8b4',
        kind: 'category',
        name: 'Eletronicos',
        tree_id: 'Eletronicos',
        priority: 10,
    },
    {
        _id: '3389d2a2-8244-4608-8799-dc52b6a6e323',
        kind: 'category',
        name: 'Produtos de Beleza',
        tree_id: 'Produtos de Beleza',
        priority: 10,
    },
    {
        _id: 'ca421818-d503-4016-90b5-ffb6c7496747',
        kind: 'category',
        name: 'Quarto',
        tree_id: 'Quarto',
        priority: 10,
    },
    {
        _id: '88ad16ec-60c8-45dd-85f4-9c9ed1835ddd',
        kind: 'category',
        name: 'Copos',
        tree_id: 'Copos',
        priority: 10,
    },
    {
        _id: '148682cb-20c3-4d1a-86fc-ee0ba222cda7',
        kind: 'category',
        name: 'Integração',
        tree_id: 'Integração',
        priority: 10,
    },
    {
        _id: '625f99f1-b7fc-439e-9f38-ce44e9368764',
        kind: 'category',
        name: 'Jogos E Brincadeiras',
        tree_id: 'Jogos E Brincadeiras',
        priority: 10,
    },
    {
        _id: 'ddcb72b2-f19b-4698-a2a8-5ae0c036c28f',
        kind: 'category',
        name: 'Decoração Criativa',
        tree_id: 'Decoração Criativa',
        priority: 10,
    },
    {
        _id: 'd046d44e-2650-412e-89b6-daed0d25f7cc',
        kind: 'category',
        name: 'Eletrodomesticos',
        tree_id: 'Eletrodomesticos',
        priority: 10,
    },
    {
        _id: 'dae956be-f756-4e6b-976a-c2f5b76e4fd4',
        kind: 'category',
        name: 'Estojos',
        tree_id: 'Estojos',
        priority: 10,
    },
    {
        _id: '325d6947-6243-4d7b-b330-dd7f5579625c',
        kind: 'category',
        name: 'Banheiro',
        tree_id: 'Banheiro',
        priority: 10,
    },
    {
        _id: '2b116e98-2dd6-4829-bde5-60c80860910e',
        kind: 'category',
        name: 'Canecos E Tulipas',
        tree_id: 'Canecos E Tulipas',
        priority: 10,
    },
    {
        _id: '065f9d10-5824-45f0-8ff8-e8873034150d',
        kind: 'category',
        name: 'Generico',
        tree_id: 'Generico',
        priority: 10,
    },
    {
        _id: '7344954b-7bf4-4890-a37a-2251009ac59e',
        kind: 'category',
        name: 'Tacas',
        tree_id: 'Tacas',
        priority: 10,
    },
    {
        _id: 'f2320c0a-bb8e-4456-97a2-68695d6fe0f2',
        kind: 'category',
        name: 'Bandejas de Notebook',
        tree_id: 'Bandejas de Notebook',
        priority: 10,
    },
    {
        _id: 'f7e2be44-7bf9-4906-834b-487d36fd4443',
        kind: 'category',
        name: 'Papelaria e cartao',
        tree_id: 'Papelaria e cartao',
        priority: 10,
    },
    {
        _id: '2c17d190-ef7e-41a1-a62a-578f13e1ddba',
        kind: 'category',
        name: 'Moringas E Garrafas',
        tree_id: 'Moringas E Garrafas',
        priority: 10,
    },
    {
        _id: '049953b1-5cd6-4cd0-984c-b5db55b89553',
        kind: 'category',
        name: 'Móveis',
        tree_id: 'Móveis',
        priority: 10,
    },
    {
        _id: '3eab6db5-d4bc-497a-9ed4-03cdea7b686d',
        kind: 'category',
        name: 'Porta Bijoux',
        tree_id: 'Porta Bijoux',
        priority: 10,
    },
    {
        _id: '3b9b03da-452d-4bdc-9032-e712b533df9d',
        kind: 'category',
        name: 'Relogios',
        tree_id: 'Relogios',
        priority: 10,
    },
    {
        _id: '021a5fd8-4063-4476-80fc-702acf60bc46',
        kind: 'category',
        name: 'Diversos cozinha e mesa',
        tree_id: 'Diversos cozinha e mesa',
        priority: 10,
    },
    {
        _id: 'ced51bfe-3e01-4180-a122-34c0994171f8',
        kind: 'category',
        name: 'Cozinha e Mesa',
        tree_id: 'Cozinha e Mesa',
        priority: 20,
    },
    {
        _id: '6e299ebf-88d7-4b93-a522-7333f99c0843',
        kind: 'category',
        name: 'Bijoux',
        tree_id: 'Bijoux',
        priority: 10,
    },
    {
        _id: '432f83b8-38d3-4e67-8423-6c882cd0c4e0',
        kind: 'category',
        name: 'Anel',
        tree_id: 'Anel',
        priority: 10,
    },
    {
        _id: '12dd28e6-0a9a-4a98-b960-4c5dcc7f004b',
        kind: 'category',
        name: 'Brincos',
        tree_id: 'Brincos',
        priority: 10,
    },
    {
        _id: 'd8dfa40f-b9bf-41e9-9f90-0eb2696b4028',
        kind: 'category',
        name: 'Colar',
        tree_id: 'Colar',
        priority: 10,
    },
    {
        _id: 'fc6507ad-fa77-43e7-abea-30a1d1467b72',
        kind: 'category',
        name: 'Pulseiras',
        tree_id: 'Pulseiras',
        priority: 10,
    },
    {
        _id: '21a4d97d-a994-4b52-8d84-db05a2a3550f',
        kind: 'category',
        name: 'Decoração',
        tree_id: 'Decoração',
        priority: 20,
    },
    {
        _id: '3d2594cf-e885-4ad6-b366-b604ded1a00d',
        kind: 'category',
        name: 'Espelhos',
        tree_id: 'Espelhos',
        priority: 10,
    },
    {
        _id: '0518b17c-ffe5-4ff9-a9c4-cdc1f5fa11de',
        kind: 'category',
        name: 'Imas de Geladeira',
        tree_id: 'Imas de Geladeira',
        priority: 10,
    },
    {
        _id: '7ddd5ff9-bd35-4b76-a1de-e9350dd8d111',
        kind: 'category',
        name: 'Abridores',
        tree_id: 'Abridores',
        priority: 10,
    },
    {
        _id: '1cd90613-e352-414c-b8b1-85e2edcd5a71',
        kind: 'category',
        name: 'Headphones',
        tree_id: 'Headphones',
        priority: 10,
    },
    {
        _id: '084afa79-bc6c-40f7-9797-7431a44ed2ad',
        kind: 'category',
        name: 'Vestuario',
        tree_id: 'Vestuario',
        priority: 10,
    },
    {
        _id: '557e014f-0fe0-4669-bfb3-e5ecae9a6a8c',
        kind: 'category',
        name: 'Organizadores',
        tree_id: 'Organizadores',
        priority: 10,
    },
    {
        _id: '7cd592ba-5b7d-4c01-8831-796f9807f7c3',
        kind: 'category',
        name: 'Cosmeticos',
        tree_id: 'Cosmeticos',
        priority: 10,
    },
    {
        _id: 'ceb481e2-5c6c-44ed-95f4-95a289adbce3',
        kind: 'category',
        name: 'Porta Pijamas',
        tree_id: 'Porta Pijamas',
        priority: 10,
    },
    {
        _id: 'f6521a40-11cd-489a-94c0-e92a255f3b55',
        kind: 'category',
        name: 'Fotografia',
        tree_id: 'Fotografia',
        priority: 10,
    },
    {
        _id: 'aa45160c-e66c-4e76-bd18-9ed60fd86dd7',
        kind: 'category',
        name: 'Fronhas',
        tree_id: 'Fronhas',
        priority: 10,
    },
    {
        _id: '22f287bc-9b5b-45b8-9b56-42d9a66da192',
        kind: 'category',
        name: 'Acessorios',
        tree_id: 'Acessorios',
        priority: 10,
    },
    {
        _id: '3fde0700-3cd6-4373-a6b5-358cf6e2c889',
        kind: 'collection',
        tree_id: 'signo',
        name: 'Signos',
        priority: 10,
    },
    {
        _id: 'f0b2d959-92db-4dbe-8438-0ee39a780feb',
        kind: 'category',
        name: 'PortaRetratos',
        tree_id: 'PortaRetratos',
        priority: 10,
    },
    {
        _id: 'aff9d514-8200-442e-a325-560e7d5333a8',
        kind: 'category',
        name: 'Bandejas',
        tree_id: 'Bandejas',
        priority: 10,
    },
];
