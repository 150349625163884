import React from 'react';
import { Button, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import './styles/actions.css';

const ElementActionPreview = ({ handle, element }) => {
  const { isPreview } = element.props || {};

  return (
    <Tooltip title="Ativar/Desativar pré-visualização">
      <Button
        className={`actions_preview-icon ${
          isPreview ? 'preview--on' : 'preview--off'
        }`}
        type="default"
        shape="circle"
        icon={<WarningOutlined />}
        onClick={handle}
      />
    </Tooltip>
  );
};

export default ElementActionPreview;
