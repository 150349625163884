import React from 'react';
import EditorComponent from '../../../../../../../Common/Editor';
import { Typography } from 'antd';
const { Title } = Typography;

export default class ElementSnippetTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            element: props.element,
            code: null,
        };
    }

    componentWillMount() {
        const { snippet = {} } = this.state.element;
        this.setState({
            code:
                snippet.code || `<!-- Adicione seu código HTML e CSS aqui -->`,
        });
    }

    updateCode(update) {
        let { element, code } = this.state;

        code = update;

        element.snippet = {
            ...element.snippet,
            code,
        };

        this.setState({
            element,
            code,
        });
    }

    render() {
        return (
            <>
                <EditorComponent
                    code={this.state.code}
                    onCodeChange={code => this.updateCode(code)}
                />

                <Title level={4} style={{ marginTop: '2em' }}>
                    Ajuda sobre o editor
                </Title>
                <ul>
                    <li>O editor suporta marcações HTML e CSS.</li>
                    <li>
                        Tags do tipo <code>&lt;script&gt;</code> são removidas
                    </li>
                    <li>
                        Não é necessário incluir tags do tipo{' '}
                        <code>&lt;html&gt;</code>, <code>&lt;head&gt;</code> ou{' '}
                        <code>&lt;body&gt;</code>.
                    </li>
                </ul>
            </>
        );
    }
}
