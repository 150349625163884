import React from 'react';
// import moment from 'moment';
import { Input, Form, Button, Row, Col } from 'antd';
import unflatten from '../../../../../utils/unflatten'

// const { Title } = Typography;
export default class PageEditFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: props.page || {},
        };
    }

    validateTab = (formResult) => {
      const { errorFields = [], values } = formResult;

      const fieldsValues = unflatten(values);
      const fieldsValidation = unflatten(
          errorFields.reduce((obj, err) => {
              obj[err.name[0]] = err.errors[0];
              return obj;
          }, {})
      );
    }

    handleSubmit = (formValues) => {

            const values = unflatten(formValues)
            console.log('original', formValues)
            console.log('values', values)

            let { props } = this.state.page;

            // put all together
            props = { ...props, ...values };

            // set props to page, confirm
            // warning! this is setting elements and regions also
            const page = { ...this.state.page, props };
            this.props.confirm(page);
    };

    render() {
        // const { getFieldDecorator } = this.props.form;
        const { context, props } = this.state.page;

        return (
            <Form
                onFinish={this.handleSubmit}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                layout="horizontal"
                size="large"
            >
                <Row className="field-component-row">
                    <Col span={24}>
                        <Form.Item 
                          label="Nome"
                          name='label'
                          rules={[{ required: true, message: 'Campo obrigatório'}]}
                          initialValue={props.label}
                        >
                            <Input />
                            {/* {getFieldDecorator('label', {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                                initialValue: props.label,
                            })(<Input />)} */}
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="field-component-row">
                    <Col span={24}>
                        <Form.Item label="Tipo de página">
                            <Input disabled defaultValue={context.label} />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Title level={4}>Disparadores</Title>

                <Form.Item label="URL da página">
                    {getFieldDecorator('triggers.url.match', {
                        initialValue: props.triggers.url.match,
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Usa expressão regular">
                    {getFieldDecorator('triggers.url.useRegex', {
                        initialValue: props.triggers.url.useRegex,
                    })(<Input />)}
                </Form.Item>

                <Form.Item label="Intervalo de data">
                    {getFieldDecorator('triggers.date', {
                        rules: [{ required: true }],
                        initialValue: [
                            props.triggers.date.start
                                ? moment(
                                      props.triggers.date.start,
                                      'YYYY-MM-DD'
                                  )
                                : null,
                            props.triggers.date.end
                                ? moment(props.triggers.date.end, 'YYYY-MM-DD')
                                : null,
                        ],
                    })(<RangePicker format="DD/MM/YYYY" />)}
                </Form.Item> */}

                <Col offset="9">
                    <Button htmlType="submit" type="primary">
                        Salvar
                    </Button>
                </Col>
            </Form>
        );
    }
}
