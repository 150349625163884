import React from 'react';
import { Alert, Space } from 'antd';

const BannerAlert = ({ message, description, type }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <Alert
      banner
      closable
      description={description}
      message={message}
      type={type}
    />
  </Space>
);

export default BannerAlert;
