import React from 'react';
import { HomeOutlined } from '@ant-design/icons';

const TenantIdComponent = ({ tenantData }) => {
  if (!tenantData) return '';

  return (
    <span>
      <HomeOutlined />
      &nbsp;{tenantData?.profile?.name}
    </span>
  );
};

export default TenantIdComponent;
