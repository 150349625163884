import createConsumer from '../../rest/createConsumer';
import staticTrees from './trees';

const delay = 100;

const CatalogApiStaticConsumer = createConsumer(consumer => {
    const getTrees = () =>
        new Promise(resolve => {
            const uniqueTrees = [];
            const filteredTrees = staticTrees.filter(tree => {
                const id = tree.tree_id.toLowerCase();

                if (uniqueTrees.indexOf(id) < 0) {
                    uniqueTrees.push(id);
                    return true;
                }

                return false;
            });

            setTimeout(() => resolve(filteredTrees), delay);
        });

    const getTreesByType = type =>
        new Promise(resolve => {
            getTrees().then(trees =>
                setTimeout(
                    () => resolve(trees.filter(tree => tree.kind === type)),
                    delay
                )
            );
        });

    return Object.freeze({
        getTrees,
        getTreesByType,
        offline: true,
    });
});

export default CatalogApiStaticConsumer;
