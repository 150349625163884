export const setResponseCache = (url, response) => {
  try {
    localStorage.setItem(url, JSON.stringify(response));
  } catch (e) {
    console.warn(`failed to cache response (${e.message})`);
  }

  return response;
};

export const getResponseCache = url => {
  try {
    const cache = localStorage.getItem(url);
    return JSON.parse(cache);
  } catch (e) {
    console.warn(`failed to cache response (${e.message})`);
    return undefined;
  }
};

export const clearResponseCache = url => {
  return localStorage.removeItem(url);
};
